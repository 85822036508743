// src/context/StudyContext.tsx

import React, { createContext, useState, useContext } from 'react';

interface StudyContextType {
  studyLanguage: string;
  setStudyLanguage: (language: string) => void;
  studyLevel: number;
  setStudyLevel: (level: number) => void;
  selectedScenario: string;
  setSelectedScenario: (scenario: string) => void;
}

// (NOUVEAU LOG) - Au moment de la création du contexte
console.log('[StudyContext] (NOUVEAU LOG) Initialisation du contexte');

const StudyContext = createContext<StudyContextType | undefined>(undefined);

export const StudyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // (NOUVEAU LOG) - Juste avant la création des states
  console.log('[StudyContext] (NOUVEAU LOG) Avant useState pour studyLanguage, studyLevel, selectedScenario');

  // État local pour la langue d’étude
  const [studyLanguage, setStudyLanguage] = useState<string>('');
  // État local pour le niveau d’étude
  const [studyLevel, setStudyLevel] = useState<number>(0);
  // État local pour le scénario sélectionné
  const [selectedScenario, setSelectedScenario] = useState<string>('');

  // (NOUVEAU LOG) - Vérifier l’état initial
  console.log('[StudyContext] (NOUVEAU LOG) État initial =>', {
    studyLanguage,
    studyLevel,
    selectedScenario,
  });

  // Fonctions de mise à jour, avec logs
  const handleSetStudyLanguage = (language: string) => {
    console.log('[StudyContext] (NOUVEAU LOG) setStudyLanguage appelé avec =>', language);
    setStudyLanguage(language);
    // Log supplémentaire pour confirmer la mise à jour immédiatement après
    console.log('[StudyContext] (DEBUG) Nouvelle valeur de studyLanguage =>', language);
  };

  const handleSetStudyLevel = (level: number) => {
    console.log('[StudyContext] (NOUVEAU LOG) setStudyLevel appelé avec =>', level);
    setStudyLevel(level);
    console.log('[StudyContext] (DEBUG) Nouvelle valeur de studyLevel =>', level);
  };

  const handleSetSelectedScenario = (scenario: string) => {
    console.log('[StudyContext] (NOUVEAU LOG) setSelectedScenario appelé avec =>', scenario);
    setSelectedScenario(scenario);
    console.log('[StudyContext] (DEBUG) Nouvelle valeur de selectedScenario =>', scenario);
  };

  // (NOUVEAU LOG) - Log avant de retourner le provider
  console.log('[StudyContext] (NOUVEAU LOG) Juste avant le return =>', {
    finalStudyLanguage: studyLanguage,
    finalStudyLevel: studyLevel,
    finalSelectedScenario: selectedScenario,
  });

  return (
    <StudyContext.Provider
      value={{
        studyLanguage,
        setStudyLanguage: handleSetStudyLanguage,
        studyLevel,
        setStudyLevel: handleSetStudyLevel,
        selectedScenario,
        setSelectedScenario: handleSetSelectedScenario,
      }}
    >
      {children}
    </StudyContext.Provider>
  );
};

export const useStudy = () => {
  const context = useContext(StudyContext);

  if (!context) {
    throw new Error('useStudy must be used within a StudyProvider');
  }

  console.log('[useStudy] Récupération du contexte Study avec:', {
    studyLanguage: context.studyLanguage,
    studyLevel: context.studyLevel,
    selectedScenario: context.selectedScenario,
  });

  return context;
};