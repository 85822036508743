// src/components/routing/ScrollToTop.tsx
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Force le défilement tout en haut lors du changement de route
    window.scrollTo(0, 0);
  }, [pathname]);

  // Ce composant n'affiche rien à l'écran
  return null;
};