// src/pages/WhyLearnPage.tsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { useStudy } from '../context/StudyContext';
import { VoxButton } from '../components/VoxButton';
import { OnboardingLayout } from '../components/OnboardingLayout';
import { VoxCard } from '../components/VoxCard';

interface ReasonOption {
  label: string;
  value: string;
  imagePath: string;
}

interface WhyLearnPageProps {
  onNext?: () => void;
}

const WhyLearnPage: React.FC<WhyLearnPageProps> = ({ onNext }) => {
  const { t } = useTranslation('why-learn');

  // Récupération de la langue d’étude pour personnaliser le titre
  const { studyLanguage } = useStudy();
  const uiLang = i18n.language || 'en';

  // Préparation de la traduction fixée pour "languages"
  const fixedT = i18n.getFixedT(uiLang, ['languages']);
  const studyLangName = fixedT(`withArticle.${studyLanguage}`, { defaultValue: studyLanguage });
  const dynamicTitle = t('titleWithLang', { studyLangName });

  // Liste d’options (7 raisons)
  const reasonsData: ReasonOption[] = [
    { label: t('options.work'),       value: 'work',       imagePath: '/why-learn/work-icon.png' },
    { label: t('options.travel'),     value: 'travel',     imagePath: '/why-learn/travel-icon.png' },
    { label: t('options.friends'),    value: 'friends',    imagePath: '/why-learn/friends-icon.png' },
    { label: t('options.exam'),       value: 'exam',       imagePath: '/why-learn/exam-icon.png' },
    { label: t('options.fun'),        value: 'fun',        imagePath: '/why-learn/having-fun-icon.png' },
    { label: t('options.productive'), value: 'productive', imagePath: '/why-learn/clever-time-icon.png' },
    { label: t('options.other'),      value: 'other',      imagePath: '/why-learn/other-icon.png' },
  ];

  // État local
  const [selectedReason, setSelectedReason] = useState('');
  const [otherText, setOtherText] = useState('');

  // Gestion de la sélection d’une raison
  const handleOptionClick = (value: string) => {
    setSelectedReason(value);
    if (value !== 'other') {
      setOtherText('');
    }
  };

  // Gestion de la saisie pour l'option "other"
  const handleOtherChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtherText(e.target.value);
  };

  // Validation et passage à l'étape suivante
  const handleNext = () => {
    const finalChoice = selectedReason === 'other' ? otherText : selectedReason;
    console.log('Motif d’apprentissage sélectionné :', finalChoice);
    onNext?.();
  };

  // Désactivation du bouton Next si aucune option n'est sélectionnée
  const isNextDisabled = !selectedReason || (selectedReason === 'other' && !otherText.trim());

  // Footer avec le bouton "Next"
  const footer = (
    <div className="flex justify-center">
      <VoxButton
        variant="primary"
        width="auto"
        disabled={isNextDisabled}
        onClick={handleNext}
      >
        {t('next', 'Next')}
      </VoxButton>
    </div>
  );

  return (
    <OnboardingLayout
      currentStep="whyLearn"
      title={dynamicTitle}
      footer={footer}
    >
      {/* Grille responsive : 1 colonne sur mobile, 2 sur desktop */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-xl md:text-2xl">
        {reasonsData.map((item) => {
          const isSelected = selectedReason === item.value;
          return (
            <VoxCard
              key={item.value}
              hoverable
              selected={isSelected}
              onClick={() => handleOptionClick(item.value)}
            >
              <img
                src={item.imagePath}
                alt={item.value}
                className="w-16 h-16 object-contain mr-4"
              />
              <span className="font-bold text-[#243238] leading-snug">
                {item.label}
              </span>
            </VoxCard>
          );
        })}

        {selectedReason === 'other' && (
          <div className="mt-2 md:col-span-2">
            <input
              type="text"
              placeholder={t('otherPlaceholder', 'Please specify')}
              value={otherText}
              onChange={handleOtherChange}
              className="
                w-full
                text-xl md:text-2xl
                px-3 py-2
                border border-gray-300
                rounded-md
                focus:outline-none
                focus:ring-2
                focus:ring-blue-300
              "
            />
          </div>
        )}
      </div>
    </OnboardingLayout>
  );
};

export default WhyLearnPage;