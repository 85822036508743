// src/pages/LandingFAQSection.tsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Exemple de section FAQ avec un système d'accordéon.
 */
export const LandingFAQSection: React.FC = () => {
  const { t } = useTranslation('landingPage');
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  // Récupération du tableau "faqs" => t('faqs', { returnObjects: true })
  const faqs = t('faqs', { returnObjects: true }) as Array<{
    question: string;
    answer: string;
  }>;

  // Bascule l'ouverture de l'index (accordéon)
  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // Si faqs n'est pas un tableau, on ne rend rien
  if (!Array.isArray(faqs)) {
    return null;
  }

  return (
    <section
      className={`
        relative
        w-screen left-1/2 ml-[-50vw]
        right-1/2 mr-[-50vw]
        border-t border-gray-300
        py-24
        bg-gray-50
      `}
    >
      {/*
        Container => text-3xl (~1.65rem) + leading-snug
      */}
      <div className="max-w-screen-lg mx-auto px-4 text-3xl leading-snug">
        {/*
          Titre aligné sur Hero / Features => text-4xl sm:text-5xl md:text-7xl
          + marge de 4rem en bas => mb-[4rem]
        */}
        <h2
          className="
            text-4xl
            sm:text-5xl
            md:text-7xl
            font-bold
            text-center
            text-[#243238]
            mb-[4rem]
            leading-tight
          "
        >
          {t('faqSectionTitle')}
        </h2>

        {/*
          On conserve un “space-y-4” pour séparer chaque FAQ
        */}
        <div className="space-y-4">
          {faqs.map((faq, idx) => (
            <div
              key={idx}
              className="
                bg-white
                border border-gray-300
                rounded-lg
                overflow-hidden
                hover:shadow
                transition-shadow
              "
            >
              <button
                type="button"
                className="
                  w-full px-4 py-3 
                  text-left
                  flex justify-between items-center
                  focus:outline-none
                  text-gray-800
                "
                onClick={() => toggleFAQ(idx)}
              >
                <span className="font-semibold">
                  {faq.question}
                </span>
                <span className="ml-2 text-gray-500">
                  {openIndex === idx ? '-' : '+'}
                </span>
              </button>

              {openIndex === idx && (
                <div className="px-4 pb-4 text-gray-700">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};