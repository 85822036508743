  // src/services/userService.ts
  import { getApiUrl } from '../config/environment';

  export const updateUserSettings = async (
    uid: string,
    updates: Record<string, any>,
  ) => {
    try {
      const response = await fetch(`${getApiUrl()}/api/user/registerOrUpdateUser`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid, ...updates }),
      });

      if (!response.ok) {
        throw new Error('Failed to update user settings');
      }

      return response.json();
    } catch (error) {
      console.error('Error updating user settings:', error);
      throw error;
    }
  };