// src/components/Seo.tsx

import React from 'react';
import { Helmet } from 'react-helmet';

interface SeoProps {
  title: string;
  description: string;
  lang?: string;
  canonicalUrl?: string;
  ogImage?: string;
}

export const Seo: React.FC<SeoProps> = ({
  title,
  description,
  lang = 'en',
  canonicalUrl,
  ogImage,
}) => {
  return (
    <Helmet>
      {/* Spécifie la langue */}
      <html lang={lang} />

      {/* Title principal */}
      <title>{title}</title>

      {/* Meta description */}
      <meta name="description" content={description} />

      {/* Canonical si besoin */}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

      {/* Open Graph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      {ogImage && <meta property="og:image" content={ogImage} />}

      {/* Twitter Cards (optionnel) */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {ogImage && <meta name="twitter:image" content={ogImage} />}
    </Helmet>
  );
};