// src/pages/LandingFeatureSection.tsx

import React from 'react';
import { useTranslation } from 'react-i18next';

export const LandingFeatureSection: React.FC = () => {
  const { t } = useTranslation('landingPage');

  return (
    <section
      className={`
        relative
        w-screen left-1/2 ml-[-50vw]
        right-1/2 mr-[-50vw]
        border-t border-gray-300
        py-24
        bg-gray-50
      `}
    >
      <div className="max-w-screen-lg mx-auto px-4 text-center">
        {/*
          Titre identique au Hero
          text-4xl => ~2rem
          sm:text-5xl => ~2.4rem
          md:text-7xl => ~3.3rem
          + font-extrabold + color #243238
        */}
        <h2
          className="
            text-4xl
            sm:text-5xl
            md:text-7xl
            font-extrabold
            text-[#243238]
            mb-[4rem]
            leading-tight
          "
        >
          {t('featureSectionTitle')}
        </h2>

        {/*
          On conserve text-3xl leading-snug pour le contenu
          + émojis en text-8xl pour les doubler
        */}
        <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-3 text-3xl leading-snug">
          {/* Feature 1 */}
          <div className="p-6 bg-white border-2 border-gray-400 rounded-lg shadow hover:shadow-xl transition-shadow">
            <div className="text-8xl mb-4">{t('features.ai.emoji')}</div>
            <h3 className="font-bold mb-2">{t('features.ai.title')}</h3>
            <p className="text-gray-800">
              {t('features.ai.description')}
            </p>
          </div>

          {/* Feature 2 */}
          <div className="p-6 bg-white border-2 border-gray-400 rounded-lg shadow hover:shadow-xl transition-shadow">
            <div className="text-8xl mb-4">{t('features.speaking.emoji')}</div>
            <h3 className="font-bold mb-2">{t('features.speaking.title')}</h3>
            <p className="text-gray-800">
              {t('features.speaking.description')}
            </p>
          </div>

          {/* Feature 3 */}
          <div className="p-6 bg-white border-2 border-gray-400 rounded-lg shadow hover:shadow-xl transition-shadow">
            <div className="text-8xl mb-4">{t('features.personalized.emoji')}</div>
            <h3 className="font-bold mb-2">{t('features.personalized.title')}</h3>
            <p className="text-gray-800">
              {t('features.personalized.description')}
            </p>
          </div>

          {/* Feature 4 */}
          <div className="p-6 bg-white border-2 border-gray-400 rounded-lg shadow hover:shadow-xl transition-shadow">
            <div className="text-8xl mb-4">{t('features.chatbot.emoji')}</div>
            <h3 className="font-bold mb-2">{t('features.chatbot.title')}</h3>
            <p className="text-gray-800">
              {t('features.chatbot.description')}
            </p>
          </div>

          {/* Feature 5 */}
          <div className="p-6 bg-white border-2 border-gray-400 rounded-lg shadow hover:shadow-xl transition-shadow">
            <div className="text-8xl mb-4">{t('features.feedback.emoji')}</div>
            <h3 className="font-bold mb-2">{t('features.feedback.title')}</h3>
            <p className="text-gray-800">
              {t('features.feedback.description')}
            </p>
          </div>

          {/* Feature 6 */}
          <div className="p-6 bg-white border-2 border-gray-400 rounded-lg shadow hover:shadow-xl transition-shadow">
            <div className="text-8xl mb-4">{t('features.progress.emoji')}</div>
            <h3 className="font-bold mb-2">{t('features.progress.title')}</h3>
            <p className="text-gray-800">
              {t('features.progress.description')}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};