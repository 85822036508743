// src/components/VoxCard.tsx

import React from 'react';
import clsx from 'clsx';

interface VoxCardProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Active un effet de survol (hover) : bordure + ombre rehaussées */
  hoverable?: boolean;
  /** Indique si la carte est sélectionnée : bordure bleue + fond bleu + ombre rehaussée */
  selected?: boolean;
}

/**
 * VoxCard : Composant de carte standardisé.
 * 
 * État normal :
 *  - Fond blanc, bordure gris-300, ombre légère (shadow-sm)
 *  - Au survol => bordure gris-400, ombre-md
 * 
 * État sélectionné (selected=true) :
 *  - Fond bleu clair (bg-blue-50)
 *  - Bordure bleue (border-blue-400)
 *  - Ombre plus marquée (shadow-md)
 */
export const VoxCard: React.FC<VoxCardProps> = ({
  children,
  hoverable = false,
  selected = false,
  className = '',
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={clsx(
        'rounded-lg',               // coins arrondis
        'border-2',                 // bordure épaisse pour plus de visibilité
        'transition duration-200',  // transition sur hover
        'p-4',                      // padding standard
        'flex items-center',        // layout horizontal

        // Survol
        hoverable && 'cursor-pointer',

        // État sélectionné vs normal
        selected
          ? 'bg-blue-50 border-blue-400 shadow-md'
          : 'bg-white border-gray-300 shadow-sm hover:border-gray-400 hover:shadow-md',

        className
      )}
    >
      {children}
    </div>
  );
};