// src/components/ResponsiveLanguageCarousel.tsx

import React, { useState, useEffect } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { VoxCard } from './VoxCard';

/** 
 * Chaque “Language” a un code, un nom et un drapeau.
 */
export interface Language {
  code: string;
  name: string;
  flag: string; 
}

interface ResponsiveLanguageCarouselProps {
  items: Language[];
  onSelectLanguage?: (langCode: string) => void;
}

/**
 * ResponsiveLanguageCarousel
 *   - Montre 6 items si la fenêtre est >= 1300 px
 *   - 5 items si >= 1100 px
 *   - 4 items si >= 900 px
 *   - sinon on masque le carrousel (< 900 => null)
 *
 *   => Largeurs fixes : itemWidthPx + gapPx
 *   => Calcul précis du conteneur pour éviter un 7ᵉ tronqué
 *   => Défilement par “pages” (un bloc de visibleCount items)
 */
export const ResponsiveLanguageCarousel: React.FC<ResponsiveLanguageCarouselProps> = ({
  items,
  onSelectLanguage,
}) => {
  // Nombre d’items à afficher
  const [visibleCount, setVisibleCount] = useState<number>(6);

  // Indique si le carrousel doit être affiché ou non
  const [shouldShow, setShouldShow] = useState<boolean>(true);

  // Index de la page actuelle (0 = première page)
  const [currentPage, setCurrentPage] = useState<number>(0);

  // Paramètres fixes (largeur par item, etc.)
  const itemWidthPx = 180; // largeur fixe par item (en pixels)
  const gapPx = 8;         // marge horizontale entre les items (en pixels)

  /**
   * Met à jour visibleCount et shouldShow en fonction de la largeur de la fenêtre.
   */
  function handleResize(width: number) {
    if (width < 900) {
      setShouldShow(false);
      setCurrentPage(0);
      return;
    }
    setShouldShow(true);

    if (width < 1100) {
      setVisibleCount(4);
    } else if (width < 1300) {
      setVisibleCount(5);
    } else {
      setVisibleCount(6);
    }

    // À chaque changement de taille, on réinitialise la page
    setCurrentPage(0);
  }

  useEffect(() => {
    const onWinResize = () => {
      const width = window.innerWidth;
      handleResize(width);
    };
    window.addEventListener('resize', onWinResize);

    // Appel initial
    onWinResize();

    return () => {
      window.removeEventListener('resize', onWinResize);
    };
  }, []);

  // Si shouldShow est false, on ne l’affiche pas
  if (!shouldShow) {
    return null;
  }

  // Nombre total de pages (chaque page contient "visibleCount" items)
  const maxPage = Math.ceil(items.length / visibleCount) - 1;
  const page = Math.min(currentPage, maxPage);

  // Largeur totale d’une “page” d’items
  const blockWidth = visibleCount * itemWidthPx + (visibleCount - 1) * gapPx;

  // Décalage horizontal (en px) pour l’animation
  const offsetPx = page * blockWidth;

  const handlePrev = () => {
    setCurrentPage((p) => Math.max(p - 1, 0));
  };

  const handleNext = () => {
    setCurrentPage((p) => Math.min(p + 1, maxPage));
  };

  return (
    <div className="flex items-center">
      {/* Bouton Précédent */}
      <button
        onClick={handlePrev}
        disabled={page === 0}
        className="mr-2"
      >
        <AiOutlineLeft size={24} />
      </button>

      {/* Fenêtre d’affichage */}
      <div
        className="overflow-hidden"
        style={{
          width: blockWidth,
        }}
      >
        <div
          className="flex transition-transform duration-300 ease-in-out"
          style={{
            transform: `translateX(-${offsetPx}px)`,
          }}
        >
          {items.map((lang, idx) => {
            // Appliquer un margin-right sauf pour le dernier item global
            const isLast = (idx === items.length - 1);
            const marginRight = isLast ? 0 : gapPx;

            return (
              <div
                key={lang.code}
                style={{
                  width: itemWidthPx,
                  marginRight,
                  flex: '0 0 auto',
                }}
                onClick={() => onSelectLanguage?.(lang.code)}
                className="cursor-pointer"
              >
                {/*
                  On enveloppe le contenu dans un VoxCard hoverable 
                  pour avoir le même contour gris, ombre, etc.
                */}
                <VoxCard hoverable className="w-full h-full flex items-center justify-center">
                  {/* Drapeau => text-6xl */}
                  <span className="text-6xl mr-2">{lang.flag}</span>

                  {/* Nom => text-xl, md:text-2xl */}
                  <span className="font-semibold text-xl md:text-2xl">
                    {lang.name}
                  </span>
                </VoxCard>
              </div>
            );
          })}
        </div>
      </div>

      {/* Bouton Suivant */}
      <button
        onClick={handleNext}
        disabled={page === maxPage}
        className="ml-2"
      >
        <AiOutlineRight size={24} />
      </button>
    </div>
  );
};