// src/hooks/useRealtimeWebRTC/useRealtimeWebRTC.ts

import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import type { UseRealtimeWebRTCOptions } from "./useRealtimeWebRTC.types";

import { startSession } from "./startSession";
import { stopSession } from "./stopSession";
import { useDataChannelListener } from "./useDataChannelListener";
import { usePointerHandlers } from "./pointerHandlers";

/**
 * useRealtimeWebRTC :
 *   - Initialise ou arrête la session WebRTC,
 *   - Fait le lien avec dataChannel (via useDataChannelListener),
 *   - Gère push-to-talk (via usePointerHandlers),
 *   - Peut injecter un historique existant (existingItems)
 *     au moment de démarrer la session.
 *
 * Ajout : on utilise un didStartRef pour éviter de relancer
 * plusieurs fois startSession() lorsqu'autoStartSession est à true.
 */
export function useRealtimeWebRTC({
  autoStartSession = false,
  onConnectingChange,
  onRealtimeEvent,
  showDebug = false,
  initialInstructions = "",
  onAnalyzersReady,
  existingItems,
}: UseRealtimeWebRTCOptions) {
  // On cible le namespace "realtime"
  const { t } = useTranslation("realtime");

  // ---------------------------------------------------------------------
  // ÉTATS LOCAUX
  // ---------------------------------------------------------------------
  const [isSessionActive, setIsSessionActive] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [events, setEvents] = useState<any[]>([]);
  const [localAnalyser, setLocalAnalyser] = useState<AnalyserNode | null>(null);
  const [remoteAnalyser, setRemoteAnalyser] = useState<AnalyserNode | null>(null);
  const [shortRecordingMessage, setShortRecordingMessage] = useState<string | null>(null);
  const [lastResponseId, setLastResponseId] = useState<string | null>(null);
  const [lastAssistantItemId, setLastAssistantItemId] = useState<string | null>(null);

  // ---------------------------------------------------------------------
  // RÉFÉRENCES
  // ---------------------------------------------------------------------
  const [dataChannel, setDataChannel] = useState<RTCDataChannel | null>(null);
  const peerConnection = useRef<RTCPeerConnection | null>(null);
  const audioElement = useRef<HTMLAudioElement | null>(null);

  const localAudioContextRef = useRef<AudioContext | null>(null);
  const localAnalyserRef = useRef<AnalyserNode | null>(null);

  const remoteAudioContextRef = useRef<AudioContext | null>(null);
  const remoteAnalyserRef = useRef<AnalyserNode | null>(null);

  // Pour calculer la durée d'enregistrement (pointerDown -> pointerUp)
  const recordingStartRef = useRef<number | null>(null);

  /**
   * Garde‐fou : évite de lancer plusieurs fois startSession()
   * si autoStartSession est activé en mode React Strict.
   */
  const didStartRef = useRef(false);

  // ---------------------------------------------------------------------
  // 1) Démarrer la session => startSession()
  // ---------------------------------------------------------------------
  async function handleStartSession() {
    await startSession({
      setIsConnecting,
      onConnectingChange,
      peerConnection,
      audioElement,
      localAudioContextRef,
      localAnalyserRef,
      remoteAudioContextRef,
      remoteAnalyserRef,
      setLocalAnalyser,
      setRemoteAnalyser,
      setDataChannel,
      setIsSessionActive,
      initialInstructions,
      onAnalyzersReady,
      showDebug,
      setEvents,
      existingItems, // Si fourni, on le passe
    });
  }

  // ---------------------------------------------------------------------
  // 2) Arrêter la session => stopSession()
  // ---------------------------------------------------------------------
  function handleStopSession() {
    stopSession({
      dataChannel,
      peerConnection,
      setDataChannel,
      setIsSessionActive,
      setIsRecording,
      localAudioContextRef,
      localAnalyserRef,
      remoteAudioContextRef,
      remoteAnalyserRef,
      setLocalAnalyser,
      setRemoteAnalyser,
      audioElement,
    });
  }

  // ---------------------------------------------------------------------
  // 3) dataChannelListener => parse events Realtime
  // ---------------------------------------------------------------------
  useDataChannelListener({
    dataChannel,
    onRealtimeEvent,
    showDebug,
    setEvents,
    setLastResponseId,
    setLastAssistantItemId,
  });

  // ---------------------------------------------------------------------
  // 4) pointerHandlers => micro push-to-talk
  // ---------------------------------------------------------------------
  const {
    handlePointerDown,
    handlePointerUp,
  } = usePointerHandlers({
    dataChannel,
    audioElement,
    remoteAudioContextRef,
    setIsRecording,
    setShortRecordingMessage,
    t,
    lastResponseId,
    setLastResponseId,
    lastAssistantItemId,
    setLastAssistantItemId,
    recordingStartRef,
  });

  // ---------------------------------------------------------------------
  // 5) autoStartSession => lancer la session immédiatement (1 seule fois)
  // ---------------------------------------------------------------------
  useEffect(() => {
    if (autoStartSession && !didStartRef.current) {
      didStartRef.current = true; // drapeau : on a lancé la session
      handleStartSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoStartSession]);

  // ---------------------------------------------------------------------
  // 6) Notifier le parent si Analyzers changent
  // ---------------------------------------------------------------------
  useEffect(() => {
    onAnalyzersReady?.(localAnalyser, remoteAnalyser);
  }, [localAnalyser, remoteAnalyser, onAnalyzersReady]);

  // ---------------------------------------------------------------------
  // 7) Retour du hook
  // ---------------------------------------------------------------------
  return {
    // Indicateurs
    isSessionActive,
    isConnecting,
    isRecording,
    events,
    localAnalyser,
    remoteAnalyser,
    shortRecordingMessage,
    dataChannel,

    // Méthodes
    handlePointerDown,
    handlePointerUp,
    startSession: handleStartSession,
    stopSession: handleStopSession,
  };
}