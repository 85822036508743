// src/utils/studyNavigation.ts

import i18n from '../i18n';

/**
 * Mapping langue interface => verbe
 *  ex. "fr" => "apprends", "en" => "learn", etc.
 */
export const pathVerbMap: Record<string, string> = {
  // Principales
  fr: 'apprends',
  en: 'learn',
  es: 'aprende',
  de: 'lerne',
  it: 'impara',
  pt: 'aprende',

  // Langues non latines (translittérations simples)
  ja: 'manabu',   // 学ぶ
  ko: 'baewuda',  // 배우다
  zh: 'xuexi',    // 学习
  ru: 'uchi',     // учи / учить
  ar: 'ta3allam', // تَعَلَّمَ
  hi: 'seekho',   // सीखो
  ur: 'seekho',   // سیکھو

  // Autres
  nl: 'leer',     // apprendre
  pl: 'ucz-sie',  // (litt. "apprends-toi")
  tr: 'ogren',    // öğren (sans accent)
  id: 'belajar',  // apprendre

  // Fallback
  default: 'apprends',
};

/**
 * buildStudyUrl :
 *   - Construit l'URL finale, par exemple "/fr/en/apprends-anglais" ou "/en/es/learn-spanish".
 *   - Utilise i18n pour récupérer les slugs localisés (ex. "frSlug": "frances", "enSlug": "english", etc.)
 *
 * @param studyLangCode  ex. "en"  (langue étudiée)
 * @param uiLang         ex. "fr"  (langue de l'interface)
 * @returns              ex. "/fr/en/apprends-anglais"
 */
export function buildStudyUrl(studyLangCode: string, uiLang: string): string {
  console.log('[buildStudyUrl] ⚙️ Début de la fonction', { studyLangCode, uiLang });

  // 1) Récupération du verbe localisé en fonction de la langue d'interface
  const pathVerb = pathVerbMap[uiLang] || pathVerbMap.default;
  console.log('[buildStudyUrl] 🔎 Verbe récupéré pour uiLang:', { uiLang, pathVerb });

  // 2) Utilisation d’i18n pour récupérer le slug localisé correspondant à la langue étudiée
  const fixedT = i18n.getFixedT(uiLang, ['languages']);
  const slugKey = `${studyLangCode}Slug`;
  const slug = fixedT(slugKey, { defaultValue: studyLangCode });
  console.log('[buildStudyUrl] 🔎 Clé et slug obtenus:', { slugKey, slug });

  // 3) Construction de l’URL finale
  const finalUrl = `/${uiLang}/${studyLangCode}/${pathVerb}-${slug}`;
  console.log('[buildStudyUrl] ✅ URL finale construite:', finalUrl);

  console.log('[buildStudyUrl] Fin de la fonction.');
  return finalUrl;
}