// src/pages/RegisterEmailForm.tsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { auth } from '../firebaseConfig';
import {
  EmailAuthProvider,
  linkWithCredential,
  linkWithPopup,
  GoogleAuthProvider,
  AuthErrorCodes,
  User,
} from 'firebase/auth';
import { getApiUrl } from '../config/environment';
import { useUser } from '../context/UserContext';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function gtag_report_conversion(url?: string) {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location.href = url;
    }
  };
  // @ts-ignore
  gtag('event', 'conversion', {
    send_to: 'AW-960724066/F2Y3CLDoxuYZEOL4jcoD',
    value: 0.5,
    currency: 'EUR',
    event_callback: callback,
  });
  return false;
}

interface RegisterEmailFormProps {
  onSuccess: () => void;
}

const RegisterEmailForm: React.FC<RegisterEmailFormProps> = ({ onSuccess }) => {
  // Namespace de traduction "registerEmailForm"
  const { t: tRegister } = useTranslation('registerEmailForm');
  const { refreshUserData } = useUser();

  // Champs du formulaire
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState(''); // prénom facultatif

  // Affichage/masquage du MDP
  const [showPassword, setShowPassword] = useState(false);

  // Gestion des erreurs
  const [error, setError] = useState<string | null>(null);

  // ---------------------------
  // [A] Inscription via e-mail
  // ---------------------------
  const handleRegisterEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('🚀 [RegisterEmailForm] Début inscription e-mail');

    try {
      const anonymousUser = auth.currentUser;
      if (!anonymousUser) {
        console.error('❌ Pas d’utilisateur anonyme => impossible de lier un compte');
        return;
      }

      // Crée le credential
      const credential = EmailAuthProvider.credential(email, password);

      // Lier le compte anonyme (User) avec ce credential
      console.log('🔄 Liaison compte anonyme + Email/MDP...');
      const userCredential = await linkWithCredential(anonymousUser, credential);

      console.log('✅ Compte anonyme mis à niveau (Email) :', {
        uid: userCredential.user.uid,
        email: userCredential.user.email,
        isAnonymous: userCredential.user.isAnonymous,
      });

      // Mise à jour DB
      await updateEmailStatusInDb(userCredential.user.uid, userCredential.user.email, firstName);

      // Refresh / onSuccess / conversion
      await refreshUserData();
      onSuccess();
      gtag_report_conversion();

    } catch (err: any) {
      console.error('❌ Erreur inscription e-mail =>', err);

      if (err.code === AuthErrorCodes.EMAIL_EXISTS) {
        setError(tRegister('emailExistsError', 'This email is already in use.'));
      } else {
        setError(err.message);
      }
    }
  };

  // -----------------------------
  // [B] Inscription via Google
  // -----------------------------
  const handleRegisterWithGoogle = async () => {
    console.log('🚀 [RegisterEmailForm] Début inscription via Google');
    setError(null);

    try {
      const anonymousUser = auth.currentUser;
      const provider = new GoogleAuthProvider();

      if (!anonymousUser) {
        // CAS 1 : pas d'utilisateur anonyme => on fait un "signInWithPopup"
        console.warn('⚠️ Pas d’utilisateur anonyme => signInWithPopup');
        const userCredential = await linkWithPopupOrSignIn(null, provider);
        console.log('✅ Compte Google créé via signInWithPopup :', userCredential.user.uid);

        await updateEmailStatusInDb(
          userCredential.user.uid,
          userCredential.user.email,
          firstName
        );
      } else {
        // CAS 2 : on a un user anonyme => on le « link » à Google
        console.log('🔄 Liaison compte anonyme + Google...');
        const userCredential = await linkWithPopupOrSignIn(anonymousUser, provider);
        console.log('✅ Compte anonyme mis à niveau (Google) :', {
          uid: userCredential.user.uid,
          email: userCredential.user.email,
        });

        await updateEmailStatusInDb(
          userCredential.user.uid,
          userCredential.user.email,
          firstName
        );
      }

      // Refresh / onSuccess / conversion
      await refreshUserData();
      onSuccess();
      gtag_report_conversion();

    } catch (err: any) {
      console.error('[RegisterEmailForm] => Erreur Google sign-in =>', err);

      if (err.code === 'auth/popup-closed-by-user') {
        setError(tRegister('popupClosed', 'Google window was closed before finishing.'));
      } else if (err.code === 'auth/account-exists-with-different-credential') {
        setError(tRegister('accountExistsDifferentCred', 'This email already has an account. Use another method.'));
      } else if (err.code === AuthErrorCodes.EMAIL_EXISTS) {
        setError(tRegister('emailExistsError', 'This email is already in use.'));
      } else {
        setError(err.message);
      }
    }
  };

  /**
   * Petite fonction utilitaire :
   *  - Si user === null => signInWithPopup
   *  - Sinon => linkWithPopup
   */
  const linkWithPopupOrSignIn = async (user: User | null, provider: GoogleAuthProvider) => {
    if (!user) {
      // Pas de user => signInWithPopup
      return await import('firebase/auth').then(({ signInWithPopup }) =>
        signInWithPopup(auth, provider)
      );
    } else {
      // On a un user => linkWithPopup
      return await linkWithPopup(user, provider);
    }
  };

  // -----------------------------
  // [C] Mise à jour DB
  // -----------------------------
  const updateEmailStatusInDb = async (
    uid: string,
    emailValue: string | null,
    fname: string
  ) => {
    const apiUrl = getApiUrl();
    console.log('🔄 Mise à jour du statut (email/prénom) DB =>', { uid, emailValue, fname });

    const response = await fetch(`${apiUrl}/api/user/updateEmailStatus`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        uid,
        email: emailValue,
        isAnonymous: false,
        firstName: fname || undefined,
      }),
    });
    const data = await response.json();
    console.log('✅ Réponse API updateEmailStatus =>', data);
  };

  // -----------------------------
  // [D] Rendu
  // -----------------------------
  return (
    <div
      className="
        text-center
        w-full
        max-w-md
        mx-auto
        px-4
        py-6
        bg-white
        rounded-md
        shadow-sm
      "
      style={{ minWidth: '280px' }}
    >
      <h2
        className="
          mb-3
          text-gray-700
          font-bold
          text-[1.4rem]
          sm:text-[1.5rem]
          md:text-[1.7rem]
        "
      >
        {tRegister('saveProgressMessage')}
      </h2>

      <p
        className="
          mb-6
          text-gray-500
          text-[1.1rem]
          sm:text-[1.15rem]
          md:text-[1.2rem]
        "
      >
        {tRegister('saveProgressDescription')}
      </p>

      {error && (
        <p
          className="
            mb-4
            text-red-500
            text-[1rem]
            sm:text-[1.05rem]
            md:text-[1.1rem]
          "
        >
          {error}
        </p>
      )}

      {/* Formulaire email/password */}
      <form onSubmit={handleRegisterEmail} className="space-y-4">
        {/* Champ prénom */}
        <input
          type="text"
          placeholder={tRegister('firstNamePlaceholder', 'First name (optional)')}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          className="
            w-full
            px-4 py-3
            border border-gray-300
            rounded-lg
            focus:outline-none
            focus:ring-2 focus:ring-blue-400
            text-[1.05rem]
            sm:text-[1.1rem]
            md:text-[1.15rem]
          "
        />

        {/* Champ email */}
        <input
          type="email"
          placeholder={tRegister('emailPlaceholder', 'Your email address')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="
            w-full
            px-4 py-3
            border border-gray-300
            rounded-lg
            focus:outline-none
            focus:ring-2 focus:ring-blue-400
            text-[1.05rem]
            sm:text-[1.1rem]
            md:text-[1.15rem]
          "
        />

        {/* Champ mot de passe */}
        <div className="relative">
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder={tRegister('choosePassword', 'Choose a password')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="
              w-full
              px-4 py-3
              border border-gray-300
              rounded-lg
              focus:outline-none
              focus:ring-2 focus:ring-blue-400
              text-[1.05rem]
              sm:text-[1.1rem]
              md:text-[1.15rem]
            "
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="
              absolute
              inset-y-0
              right-3
              flex
              items-center
              text-gray-500
              hover:text-gray-700
            "
            tabIndex={-1}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>

        {/* Bouton valider email/MDP */}
        <button
          type="submit"
          className="
            w-full
            py-3
            mt-2
            bg-blue-500
            text-white
            font-semibold
            rounded-lg
            hover:bg-blue-600
            transition
            duration-200
            text-[1.05rem]
            sm:text-[1.1rem]
            md:text-[1.15rem]
            focus:outline-none
            focus:ring-4
            focus:ring-blue-300
          "
        >
          {tRegister('signUpButton', 'Sign up')}
        </button>
      </form>

      {/* LIGNE DE SÉPARATION */}
      <div className="flex items-center my-6">
        <div className="flex-grow border-t border-gray-400" />
        <span className="mx-2 text-gray-600">
          {tRegister('separatorOr', 'OR')}
        </span>
        <div className="flex-grow border-t border-gray-400" />
      </div>

      {/* Bouton Google */}
      <button
        type="button"
        onClick={handleRegisterWithGoogle}
        className="
          w-full
          py-3
          bg-white
          border-2
          border-blue-500
          text-blue-500
          font-semibold
          rounded-lg
          hover:bg-gray-50
          transition
          duration-200
          focus:outline-none
          focus:ring-4
          focus:ring-blue-300
          uppercase
          tracking-wide
          flex
          items-center
          justify-center
          gap-2
        "
      >
        <svg
          className="w-5 h-5"
          viewBox="0 0 48 48"
        >
          <path
            fill="#EA4335"
            d="M24 9.5c3.94 0 7.14 1.62 9.39 3l6.92-6.92C36.06 2.33 30.48 0 24 0 14.66 0 6.36 4.74 1.74 11.64l7.37 5.73C11.29 11.67 17.18 9.5 24 9.5z"
          />
          <path
            fill="#4285F4"
            d="M46.145 24.5c0-1.64-.15-3.2-.43-4.73H24v9h12.46c-.53 2.4-1.98 4.43-4.2 5.82l6.68 5.19c3.9-3.59 6.14-8.89 6.14-15.28z"
          />
          <path
            fill="#FBBC05"
            d="M8.765 29.109a14.51 14.51 0 0 1-.76-4.61c0-1.6.28-3.14.76-4.61l-7.37-5.73C.45 17.04 0 20.46 0 24c0 3.54.45 6.96 1.395 10.14l7.37-5.031z"
          />
          <path
            fill="#34A853"
            d="M24 48c6.48 0 11.94-2.15 15.92-5.86l-6.68-5.19c-2 1.37-4.53 2.19-9.24 2.19-6.82 0-12.71-4.17-14.83-10.06l-7.37 5.03C6.36 43.26 14.66 48 24 48z"
          />
          <path fill="none" d="M0 0h48v48H0z" />
        </svg>

        <span>{tRegister('googleSignUp', 'Sign up with Google')}</span>
      </button>
    </div>
  );
};

export default RegisterEmailForm;