// ---------------------------------------------------------------------
// src/pages/ConversationScenarioSelection/ScenarioChoiceModal.tsx
// ---------------------------------------------------------------------

import React from 'react';
import { useTranslation } from 'react-i18next';

type ScenarioChoiceModalProps = {
  visible: boolean;
  scenarioTitle?: string; // non-affiché, mais conservé pour le parent
  onContinue: () => void;
  onNewConversation: () => void;
  onClose: () => void;
};

export function ScenarioChoiceModal({
  visible,
  scenarioTitle,
  onContinue,
  onNewConversation,
  onClose,
}: ScenarioChoiceModalProps) {
  const { t } = useTranslation('scenarios');

  if (!visible) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black/50 flex items-center justify-center p-4">
      <div className="relative bg-white p-6 rounded-lg shadow-lg w-full max-w-sm">
        {/* Petit bouton "X" en haut à droite (optionnel) */}
        <button
          onClick={onClose}
          className="
            absolute 
            top-2 right-2 
            text-2xl 
            text-gray-600 
            hover:text-gray-800
          "
          aria-label="Close"
        >
          ×
        </button>

        {/* QUESTION : même taille que les boutons, en gras */}
        <p
          className="
            text-[1.2rem]
            sm:text-[1.3rem]
            md:text-[1.4rem]
            font-bold
            text-[#243238]
            text-center
            mb-6
          "
        >
          {t('scenarioChoiceModal.question')}
        </p>

        <div className="flex flex-col gap-4">
          {/* Bouton 1 : Continuer (MAJUSCULES + gras + même taille) */}
          <button
            onClick={() => {
              console.log('[ScenarioChoiceModal] => onContinue clicked');
              onContinue();
            }}
            className="
              bg-blue-500
              hover:bg-blue-600
              text-white
              py-3 px-4
              rounded
              text-[1.2rem]
              sm:text-[1.3rem]
              md:text-[1.4rem]
              font-bold
              uppercase
            "
          >
            {t('scenarioChoiceModal.continueButton')}
          </button>

          {/* Bouton 2 : Recommencer (MAJUSCULES + gras + même taille) */}
          <button
            onClick={() => {
              console.log('[ScenarioChoiceModal] => onNewConversation clicked');
              onNewConversation();
            }}
            className="
              bg-gray-200
              hover:bg-gray-300
              text-gray-900
              py-3 px-4
              rounded
              text-[1.2rem]
              sm:text-[1.3rem]
              md:text-[1.4rem]
              font-bold
              uppercase
            "
          >
            {t('scenarioChoiceModal.restartButton')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ScenarioChoiceModal;