// src/hooks/usePreloadImages.ts

import { useEffect, useState } from 'react';

/**
 * usePreloadImages
 * @param imageUrls - liste des chemins ou URLs des images à précharger
 * @returns { loadedCount, totalCount, allLoaded }
 */
export function usePreloadImages(imageUrls: string[]) {
  const [loadedCount, setLoadedCount] = useState(0);
  const totalCount = imageUrls.length;
  const allLoaded = loadedCount === totalCount && totalCount > 0;

  useEffect(() => {
    if (imageUrls.length === 0) return;

    let isCancelled = false;

    imageUrls.forEach((url) => {
      const img = new Image();
      img.onload = () => {
        if (!isCancelled) {
          setLoadedCount((prev) => prev + 1);
        }
      };
      img.onerror = () => {
        if (!isCancelled) {
          // Incrémente quand même, pour ne pas bloquer `allLoaded`
          setLoadedCount((prev) => prev + 1);
        }
      };
      img.src = url;
    });

    return () => {
      isCancelled = true;
    };
  }, [imageUrls]);

  return { loadedCount, totalCount, allLoaded };
}