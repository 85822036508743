// src/components/Header.tsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../context/LanguageContext';
import { LanguageSelectorHeader } from './LanguageSelectorHeader';

interface HeaderProps {
  onLogoClick?: () => void;
  isAbsolute?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  onLogoClick,
  isAbsolute = false,
}) => {
  const { t } = useTranslation('landingPage');
  const { interfaceLanguage, setInterfaceLanguage } = useLanguage();

  // On gère la classe du header (absolu ou pas)
  const headerClass = isAbsolute
    ? 'absolute top-0 left-0 w-full z-10 bg-transparent py-2 md:py-5 px-6'
    : 'w-full bg-transparent py-2 md:py-5 px-6 z-10';

  // Clic sur le logo
  const handleLogoClickInternal = () => {
    if (onLogoClick) {
      onLogoClick();
    }
  };

  return (
    <header className={headerClass}>
      <div className="max-w-screen-xl mx-auto flex items-center justify-center md:justify-between">
        {/*
          Titre du logo
          Avant => text-[2.5rem] => text-5xl (~2.4rem)
            sm:text-[3rem] => sm:text-6xl (3rem)
            md:text-[3.5rem] => md:text-??? => (3.3 c’est 7xl, 3.5 = ~3.5rem => on va mettre 7xl ou 8xl)
            lg:text-[4rem] => lg:text-8xl (4rem)

          Pour rester proche :
            text-5xl sm:text-6xl md:text-7xl lg:text-8xl
        */}
        <h1
          onClick={handleLogoClickInternal}
          className="
            cursor-pointer
            text-[#3f7afd]
            font-extrabold
            leading-tight
            animate-fade-in-down
            text-5xl
            sm:text-6xl
            md:text-7xl
            lg:text-8xl
            whitespace-normal
            break-words
          "
        >
          Voxanet
        </h1>

        {/*
          Sélecteur de langue
          Avant => text-[1.3rem] => text-xl, md:text-[1.5rem] => md:text-2xl
        */}
        <div className="hidden md:flex items-center text-gray-400 text-xl md:text-2xl font-semibold uppercase gap-2 whitespace-nowrap">
          <span>{t('siteLanguageLabel')} :</span>
          <LanguageSelectorHeader
            selectedLanguage={interfaceLanguage || 'en'}
            onLanguageSelect={setInterfaceLanguage}
          />
        </div>
      </div>
    </header>
  );
};