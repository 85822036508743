// src/constants/onboardingSteps.ts

import { WorkflowStep } from '../types/WorkflowStep';

/**
 * Les étapes pour lesquelles on veut afficher la barre de progression.
 */
export const ONBOARDING_STEPS: WorkflowStep[] = [
  'heardAbout',
  'whyLearn',
  'level',
  'encouragement',
  'dailyGoal',
  'notifications-opt-in',
  'startChoice',
];