// src/App.tsx

import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import { StartupRouter } from './components/StartupRouter';
import { ScrollToTop } from './components/routing/ScrollToTop';
import LoginPage from './pages/LoginPage';
import { InnerApp } from './pages/InnerApp';
import { LanglessRedirect } from './components/routing/LanglessRedirect';
import './App.scss';

// Import des pages
import LandingPage from './pages/LandingPage';
import StreakPage from './pages/ConsolePage/StreakPage';
import StreakObjectivePage from './pages/ConsolePage/StreakObjectivePage';

export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="relative min-h-screen flex flex-col items-center justify-center">
        <Routes>
          {/* Route racine => StartupRouter, ou LandingPage si vous préférez */}
          <Route path="/" element={<StartupRouter />} />

          {/* /login (hors paramètre :lang) */}
          <Route path="/login" element={<LoginPage />} />

          {/* Redirections langless => /:lang sub-routes */}
          <Route path="/about" element={<LanglessRedirect to="about" />} />
          <Route path="/mission-vision" element={<LanglessRedirect to="mission-vision" />} />
          <Route path="/how-it-works" element={<LanglessRedirect to="how-it-works" />} />
          <Route path="/contact" element={<LanglessRedirect to="contact" />} />
          <Route path="/pricing" element={<LanglessRedirect to="pricing" />} />
          <Route path="/settings" element={<LanglessRedirect to="settings" />} />
          <Route path="/success" element={<LanglessRedirect to="success" />} />
          <Route path="/team" element={<LanglessRedirect to="team" />} />

          {/* Route dynamique => /:lang/* => InnerApp */}
          <Route path="/:lang/*" element={<InnerApp />} />

          {/*
            ============= NOUVELLES ROUTES STREAK =================
            On peut décider que /streak ou /streak-objective
            s'affichent SANS layout ni StartupRouter
          */}
          <Route
            path="/streak"
            element={
              <StreakPage
                currentStreak={3}
                onClose={() => {
                  // Retour à la racine
                  window.location.href = '/';
                }}
              />
            }
          />
          <Route
            path="/streak-objective"
            element={
              <StreakObjectivePage
                onGoalSelected={(goal) => {
                  console.log('Objectif streak choisi =>', goal);
                }}
                onClose={() => {
                  window.location.href = '/';
                }}
              />
            }
          />

          {/* Optionnel : route /landing => LandingPage "pure" sans StartupRouter */}
          <Route path="/landing" element={<LandingPage />} />

          {/* 404 */}
          <Route
            path="*"
            element={
              <div className="p-4 text-center">
                <h1 className="text-2xl font-bold">404 - Not Found</h1>
                <p>Oops, this page does not exist.</p>
              </div>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}