// src/context/AuthContext.tsx

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import { User } from 'firebase/auth';
import {
  getAuth,
  onAuthStateChanged,
  signInAnonymously,
} from 'firebase/auth';
import { getApiUrl } from '../config/environment';

/**
 * AuthContextType :
 *  - currentUser : l'utilisateur Firebase (ou null)
 *  - loading     : indique qu'on est en cours d'initialisation
 *                 (mais on ne bloque plus l'interface sur ce loading).
 */
interface AuthContextType {
  currentUser: User | null;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  loading: true,
});

/**
 * Variable pour éviter d'appeler registerOrUpdateUser
 * trop souvent en un court laps de temps.
 */
let lastRegisterCallTimestamp = 0;

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  console.log('[AuthContext] *** COMPONENT MOUNTED ***');

  const [currentUser, setCurrentUser] = useState<User | null>(null);
  /**
   * loading n'est plus utilisé pour bloquer l'affichage
   * dans la LandingPage. Il est seulement indicatif.
   */
  const [loading, setLoading] = useState(true);

  /**
   * registerOrUpdateUser :
   *   - Fait un fetch POST vers notre backend pour
   *     enregistrer ou mettre à jour (uid, browserLang, etc.).
   */
  async function registerOrUpdateUser(
    uid: string,
    browserLanguage: string,
    timezone: string,
    preferredReminderHour: number
  ) {
    try {
      const apiUrl = getApiUrl();
      const now = Date.now();
      const msSinceLastCall = now - lastRegisterCallTimestamp;
      lastRegisterCallTimestamp = now;

      console.log('---------------------------------------------------');
      console.log('[AuthContext] registerOrUpdateUser() CALLED =>', {
        uid,
        browserLanguage,
        timezone,
        preferredReminderHour,
        msSinceLastCall,
        now: new Date().toISOString(),
      });
      console.log('---------------------------------------------------');

      // Exemple de requête fetch
      const response = await fetch(`${apiUrl}/api/user/registerOrUpdateUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': browserLanguage,
        },
        body: JSON.stringify({
          uid,
          defaultBrowserLanguage: browserLanguage,
          timezone,
          preferredReminderHour,
        }),
      });

      if (!response.ok) {
        console.error('[AuthContext] ❌ Échec de registerOrUpdateUser :', {
          status: response.status,
          statusText: response.statusText,
        });
      } else {
        console.log('[AuthContext] ✅ User register/update success');
        // Log de la réponse JSON (optionnel)
        try {
          const data = await response.json();
          console.log('[AuthContext] => Réponse du serveur:', data);
        } catch (parseErr) {
          console.warn('[AuthContext] Impossible de parser la réponse JSON:', parseErr);
        }
      }
    } catch (error) {
      console.error('[AuthContext] ❌ registerOrUpdateUser => catch error:', error);
    }
  }

  /**
   * useEffect => onAuthStateChanged :
   *   - Surveille l’état Firebase Auth
   *   - Si aucun user => signInAnonymously
   *   - Sinon => on appelle registerOrUpdateUser
   *   - On met ensuite `loading = false` (mais cela n’empêche plus l’affichage).
   */
  useEffect(() => {
    console.log('[AuthContext] => Starting onAuthStateChanged watch');

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('[AuthContext] onAuthStateChanged => user =', user?.uid || null);

      if (!user) {
        // Pas de user => connexion anonyme
        console.log('[AuthContext] => No user => signInAnonymously');
        try {
          const anonResult = await signInAnonymously(auth);
          const anonUser = anonResult.user;

          console.log('[AuthContext] ✅ Anonyme =>', anonUser.uid);

          // Ex. mise à jour backend
          const browserLang = navigator.language || 'en';
          const tz = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
          await registerOrUpdateUser(anonUser.uid, browserLang, tz, 17);

          setCurrentUser(anonUser);
          setLoading(false);
          console.log('[AuthContext] => anonyme user set, loading=false');
        } catch (signInErr) {
          console.error('[AuthContext] => signInAnonymously failed:', signInErr);
          setLoading(false);
        }
      } else {
        // user existant
        console.log('[AuthContext] => existing user =>', user.uid);

        const browserLang = navigator.language || 'en';
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
        await registerOrUpdateUser(user.uid, browserLang, tz, 17);

        setCurrentUser(user);
        setLoading(false);
        console.log('[AuthContext] => user set, loading=false');
      }
    });

    return () => {
      console.log('[AuthContext] => cleaning onAuthStateChanged');
      unsubscribe();
    };
  }, []);

  /**
   * useEffect => juste pour observer l’évolution de currentUser
   */
  useEffect(() => {
    console.log('[AuthContext] => currentUser changed =>', {
      userUid: currentUser?.uid,
      isAnonymous: currentUser?.isAnonymous,
      loadingState: loading,
    });
  }, [currentUser, loading]);

  return (
    <AuthContext.Provider value={{ currentUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);