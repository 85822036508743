// src/components/Layout.tsx

import React from 'react';

interface LayoutProps {
  children: React.ReactNode;
  noMaxWidth?: boolean;
  // Permettre de personnaliser la classe si besoin
  maxWidthClass?: string;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  noMaxWidth = false,
  maxWidthClass = 'max-w-screen-xl', // <--- Valeur par défaut recommandée
}) => {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-1 w-full">
        {noMaxWidth ? (
          // Si on ne veut aucune restriction de largeur :
          <div className="w-full px-4">{children}</div>
        ) : (
          // Sinon, on applique la classe max-w-* désirée
          <div className={`w-full mx-auto px-4 ${maxWidthClass}`}>
            {children}
          </div>
        )}
      </main>
    </div>
  );
};