// ---------------------------------------------------------------------
// src/pages/ConversationScenarioSelection/ConversationScenarioSelection.tsx
// ---------------------------------------------------------------------

import React from 'react';
import { Layout } from '../../components/Layout';
import { SettingsButton } from '../../components/SettingsButton';
import RegisterEmailForm from '../RegisterEmailForm';
import PaywallOffer from '../PaywallOffer';

// Composants locaux
import { ScenarioChoiceModal } from './ScenarioChoiceModal';
import { ScenarioGroups } from './ScenarioGroups';

// Notre hook qui contient toute la logique
import { useConversationScenarioSelection } from './useConversationScenarioSelection';

// Style local
import './ConversationScenarioSelection.scss';

interface ConversationScenarioSelectionProps {
  onScenarioSelect: () => void;
}

export const ConversationScenarioSelection: React.FC<ConversationScenarioSelectionProps> = ({
  onScenarioSelect,
}) => {
  // -- On invoque le hook
  const {
    // i18n
    tScenarios,
    tRegister,

    // États & données
    highlightedScenario,
    showRegisterEmailForm,
    isLimitReached,
    showSuccessMessage,
    scenarioChoiceModalOpen,
    pendingScenarioId,
    pendingScenarioTitle,
    scenarioGroups,
    completedScenarios,
    unlockedGroupIndex,
    totalMessageCount,
    userData,

    // Handlers
    handleScenarioSelect,
    handleSignUpSuccess,
    updateScenarioInBackend,
    setScenarioChoiceModalOpen,
  } = useConversationScenarioSelection({ onScenarioSelect });

  return (
    // (1) => On met noMaxWidth = {true} pour occuper toute la largeur
    <Layout noMaxWidth>
      <div className="relative w-full">
        {/* Bouton paramètres */}
        <SettingsButton />

        {/* (1) Liste des scénarios (si pas de blocage) */}
        {!showRegisterEmailForm && !isLimitReached && (
          <div className="conversation-scenario-selection custom-top-padding">
            {/* Titre */}
            <div className="title-container">
              <h2
                className="
                  page-title
                  inline-block
                  text-[2rem]
                  sm:text-[2.4rem]
                  md:text-[3.3rem]
                  font-extrabold
                  text-[#243238]
                  leading-tight
                  text-center
                  mb-4
                  whitespace-normal
                  sm:whitespace-nowrap
                "
              >
                {tScenarios('conversationScenarioSelection.title')}{' '}
                <span className="title-emoji">🗣️</span>
              </h2>
            </div>

            {/* (a) Étoiles + streak */}
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
              {totalMessageCount > 0 && (
                <div className="star-count" style={{ marginBottom: '0.2rem' }}>
                  <p style={{ fontWeight: 'normal' }}>
                    {totalMessageCount} ⭐️
                  </p>
                </div>
              )}
              {totalMessageCount > 0 && userData && (
                <div className="star-count">
                  <p style={{ fontWeight: 'normal', margin: 0 }}>
                    {tScenarios('conversationScenarioSelection.streakCounter', {
                      count: userData.dailyStreak ?? 0,
                    })}
                  </p>
                </div>
              )}
            </div>

            {/* (b) Affichage groupé des scénarios */}
            <ScenarioGroups
              scenarioGroups={scenarioGroups}
              completedScenarios={completedScenarios}
              unlockedGroupIndex={unlockedGroupIndex}
              highlightedScenario={highlightedScenario}
              onScenarioClick={handleScenarioSelect}
              tScenarios={tScenarios}
            />
          </div>
        )}

        {/* (2) Overlay si showRegisterEmailForm = true */}
        {showRegisterEmailForm && (
          <>
            {showSuccessMessage && (
              <div className="fixed top-16 w-full text-center p-4 bg-green-100 text-green-800 font-semibold z-50 shadow-lg">
                {tRegister('successMessage')}
              </div>
            )}
            <div className="fixed inset-0 z-50 bg-black/50 overflow-auto">
              <div className="flex min-h-screen items-center justify-center p-4">
                <div
                  className="
                    bg-white
                    p-6
                    rounded-lg
                    shadow-lg
                    w-full
                    max-w-md
                    overflow-y-auto
                  "
                  style={{ maxHeight: '90vh' }}
                >
                  <RegisterEmailForm onSuccess={handleSignUpSuccess} />
                </div>
              </div>
            </div>
          </>
        )}

        {/* (3) Overlay si isLimitReached = true => PaywallOffer */}
        {isLimitReached && (
          <div className="fixed inset-0 z-50 bg-black/50 overflow-auto">
            <div className="flex min-h-screen items-center justify-center p-4">
              <div
                className="
                  bg-white
                  p-6
                  rounded-lg
                  shadow-lg
                  w-full
                  max-w-lg
                  overflow-y-auto
                "
                style={{ maxHeight: '90vh' }}
              >
                <PaywallOffer />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* (4) Modal Continuer / Recommencer */}
      <ScenarioChoiceModal
        visible={scenarioChoiceModalOpen}
        scenarioTitle={pendingScenarioTitle}
        onContinue={() => {
          if (pendingScenarioId) {
            updateScenarioInBackend(pendingScenarioId, false);
          }
          setScenarioChoiceModalOpen(false);
        }}
        onNewConversation={() => {
          if (pendingScenarioId) {
            updateScenarioInBackend(pendingScenarioId, true);
          }
          setScenarioChoiceModalOpen(false);
        }}
        onClose={() => {
          setScenarioChoiceModalOpen(false);
        }}
      />
    </Layout>
  );
};

export default ConversationScenarioSelection;