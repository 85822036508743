// src/hooks/useWarnOnUnsavedChanges.ts
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Liste des routes concernées par l'avertissement.
 * Ici, on inclut toutes les pages d'onboarding
 * depuis "heard-about" jusqu'à "start-choice".
 */
const ONBOARDING_PATHS = [
  '/heard-about',
  '/why-learn',
  '/level',
  '/encouragement',
  '/daily-goal',
  '/start-choice',
];

/**
 * Ce hook écoute la route courante et, si on se trouve
 * sur une des routes d'onboarding, on attache un
 * écouteur "beforeunload" pour avertir l'utilisateur
 * en cas de fermeture ou de rechargement.
 */
export function useWarnOnUnsavedChanges() {
  const location = useLocation();

  useEffect(() => {
    // Vérifie si la pathname courante matche une page d'onboarding
    const isOnboardingPage = ONBOARDING_PATHS.some((path) =>
      location.pathname.endsWith(path),
    );

    if (!isOnboardingPage) {
      // Onboarding non concerné => on n’attache rien
      return;
    }

    // Callback appelé au moment de la fermeture / reload
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Annule la fermeture : affiche la pop-up native
      event.preventDefault();
      // Pour Chrome, il faut assigner une chaîne à returnValue
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Nettoyage : on retire l'écouteur si l'utilisateur
    // quitte la page ou change de route.
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location]);
}