// src/hooks/useAppInitialization.ts

import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { useUser } from '../context/UserContext';
import { useStudy } from '../context/StudyContext';
import { getApiUrl } from '../config/environment';
import { WorkflowStep } from '../types/WorkflowStep';

type UseAppInitializationParams = {
  currentStep: WorkflowStep;
  setCurrentStep: React.Dispatch<React.SetStateAction<WorkflowStep>>;
  locationPathname: string;
};

/**
 * Hook d'initialisation « non-bloquante » :
 *   - Surveille onAuthStateChanged => si user => refreshUserData
 *   - Optionnellement, on modifie currentStep si on veut skip le onboarding
 *     en se basant sur la DB (si l'user a déjà choisi une langue, etc.)
 *   - NE bloque PAS le rendu : la LandingPage s’affiche immédiatement.
 */
export function useAppInitialization({
  currentStep,
  setCurrentStep,
  locationPathname,
}: UseAppInitializationParams) {
  const [authChecking, setAuthChecking] = useState(true);
  const [userDataLoading, setUserDataLoading] = useState(true);

  const { refreshUserData } = useUser();
  const { setStudyLanguage, setStudyLevel } = useStudy();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUserDataLoading(true);
        try {
          await refreshUserData();

          // On peut charger la langue/niveau depuis la DB
          const languageInfo = await fetchUserLanguageInfo(user.uid);

          // Ex. si on est encore sur 'landing', on skip direct => 'scenario'
          if (currentStep === 'landing') {
            if (languageInfo.interfaceLanguage) {
              if (languageInfo.lastSelectedStudyLanguage) {
                setStudyLanguage(languageInfo.lastSelectedStudyLanguage);
                if (languageInfo.lastSelectedStudyLevel != null) {
                  setStudyLevel(languageInfo.lastSelectedStudyLevel);
                  setCurrentStep('scenario');
                } else {
                  setCurrentStep('level');
                }
              } else {
                setCurrentStep('language');
              }
            } else {
              setCurrentStep('landing'); 
            }
          }
        } catch (err) {
          console.error('[useAppInitialization] refreshUserData =>', err);
        }
        setUserDataLoading(false);
      } else {
        // Pas de user => on reste sur "landing"
        setCurrentStep('landing');
        setUserDataLoading(false);
      }
      setAuthChecking(false);
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Ex. surveillance du path marketing
  useEffect(() => {
    const path = locationPathname.toLowerCase();
    const isMarketing = ['/about','/contact','/pricing','/how-it-works'].some((k) => path.includes(k));
    if (isMarketing) {
      console.log('[useAppInitialization] => marketing path:', path);
    }
  }, [locationPathname]);

  // Retourne un objet si tu veux l'exploiter ailleurs (ou rien)
  return {
    authChecking,
    userDataLoading,
  };
}

async function fetchUserLanguageInfo(uid: string) {
  const apiUrl = getApiUrl();
  try {
    const resp = await fetch(`${apiUrl}/api/user/getUserProfileInfo?uid=${uid}`);
    return await resp.json();
  } catch (err) {
    console.error('[fetchUserLanguageInfo] =>', err);
    return {};
  }
}