/* -----------------------------------------------------------------------
 * src/pages/ConsolePage/logic/useConsolePageLogic.ts
 * ----------------------------------------------------------------------- */

import { useEffect, useState, useCallback, useMemo, useRef } from 'react';

import { useAuth } from '../../../context/AuthContext';
import { useLanguage } from '../../../context/LanguageContext';
import { useStudy } from '../../../context/StudyContext';
import { useUser } from '../../../context/UserContext';
import { useTotalMessageCount } from '../../../context/TotalMessageCountContext';

import { MESSAGE_LIMITS, SPECIAL_USERS } from '../../../config/limits';
import { generateInstructions } from '../../../utils/conversation_config';

/** Hooks spécialisés */
import { useStreak } from './useStreak';
import { useScenarioCompletion } from './useScenarioCompletion';
import { useTranslateMessage } from './useTranslateMessage';
import { useConsoleMessages } from './useConsoleMessages';

/**
 * useConsolePageLogic :
 *  - Gère la logique de conversation (messages, correction, traduction)
 *  - Gère la streak quotidienne
 *  - Gère la complétion de scénario
 *  - Gère l’affichage du formulaire si user anonyme dépasse `ANONYMOUS_USER_PROMPT_LIMIT`
 *  - Gère l’affichage de l’offre d’abonnement (isLimitReached), mais ne gère plus ici la limite "50".
 */
export function useConsolePageLogic() {
  // -------------------------------------------------------------------------
  // 1) Récupération des contextes
  // -------------------------------------------------------------------------
  const { currentUser } = useAuth();
  const { interfaceLanguage } = useLanguage();
  const { studyLanguage, studyLevel, selectedScenario } = useStudy();
  const { totalMessageCount, refreshTotalMessageCount } = useTotalMessageCount();
  const { userData, refreshUserData } = useUser();

  // -------------------------------------------------------------------------
  // Modification clé ici : Gestion du log userFirstName
  // -------------------------------------------------------------------------
  const userFirstName = userData?.firstName || null;
  const prevFirstNameRef = useRef(userFirstName);

  useEffect(() => {
    if (prevFirstNameRef.current !== userFirstName) {
      console.log('[Debug] userFirstName =', userFirstName);
      prevFirstNameRef.current = userFirstName;
    }
  }, [userFirstName]);

  // -------------------------------------------------------------------------
  // 2) Hooks "Streak" et "Scenario"
  // -------------------------------------------------------------------------
  const {
    showStreakAnimation,
    showStreakSuccessMessage,
    checkAndTriggerStreakIncrease,
  } = useStreak();

  const {
    showScenarioSuccessMessage,
    scenarioAlreadyCompleted,
    error: scenarioError,
    markScenarioAsCompleted,
    setError: setScenarioError,
  } = useScenarioCompletion(checkAndTriggerStreakIncrease);

  // -------------------------------------------------------------------------
  // 3) Calculer si l'utilisateur est anonyme
  // -------------------------------------------------------------------------
  const isAnonymousUser = userData?.isAnonymous ?? currentUser?.isAnonymous ?? true;

  // -------------------------------------------------------------------------
  // 4) Générer le "prompt system" (instructions GPT)
  // -------------------------------------------------------------------------
  const dynamicInstructions = useMemo(() => {
    return generateInstructions(
      interfaceLanguage,
      studyLanguage,
      studyLevel,
      selectedScenario,
      userFirstName
    );
  }, [
    interfaceLanguage,
    studyLanguage,
    studyLevel,
    selectedScenario,
    userFirstName,
  ]);

  // -------------------------------------------------------------------------
  // 5) Surveiller totalMessageCount et userData => isLimitReached ?
  // -------------------------------------------------------------------------
  const [isLimitReached, setIsLimitReached] = useState(false);

  useEffect(() => {
    if (!currentUser) return;

    // (A) Cas user "UNLIMITED" => aucune limite
    if (SPECIAL_USERS.UNLIMITED_ACCESS.includes(currentUser.uid)) {
      setIsLimitReached(false);
      return;
    }

    // (B) Cas user abonné => pas de limite
    const subscriptionEndDate = userData?.subscriptionEndDate
      ? new Date(userData.subscriptionEndDate)
      : null;
    if (subscriptionEndDate && subscriptionEndDate > new Date()) {
      setIsLimitReached(false);
      return;
    }

    // (C) Sinon => c'est le backend qui renverra 403 quand la limite est dépassée
    // => On réagit alors via onLimitReached (dans useConsoleMessages).
  }, [currentUser, totalMessageCount, userData]);

  // -------------------------------------------------------------------------
  // 5-bis) checkIfLimitReached => micro (push-to-talk), anonyme
  // -------------------------------------------------------------------------
  const [showRegisterEmailForm, setShowRegisterEmailForm] = useState(false);

  const checkIfLimitReached = useCallback((): boolean => {
    if (isAnonymousUser) {
      if (totalMessageCount >= MESSAGE_LIMITS.ANONYMOUS_USER_PROMPT_LIMIT) {
        setShowRegisterEmailForm(true);
        return true;
      }
    }
    return false;
  }, [isAnonymousUser, totalMessageCount]);

  // -------------------------------------------------------------------------
  // 6) handleSignUpSuccess => user finit son inscription => on affiche un message
  // -------------------------------------------------------------------------
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSignUpSuccess = useCallback(() => {
    setShowRegisterEmailForm(false);
    setShowSuccessMessage(true);
    setTimeout(() => setShowSuccessMessage(false), 5000);
  }, []);

  // -------------------------------------------------------------------------
  // 7) useConsoleMessages => gère la liste items + count session + ...
  // -------------------------------------------------------------------------
  const {
    items,
    setItems, // On récupère setItems pour pouvoir manipuler la liste
    sessionMessageCount,
    handleRealtimeEvent,
  } = useConsoleMessages({
    currentUser,
    userData,
    studyLanguage,
    selectedScenario,
    refreshTotalMessageCount,
    refreshUserData,
    checkAndTriggerStreakIncrease,
    onLimitReached: () => {
      setIsLimitReached(true);
    },
  });

  // -------------------------------------------------------------------------
  // 8) Hook de traduction => “handleTranslate”
  // -------------------------------------------------------------------------
  const [error, setError] = useState<string | null>(null);

  const { handleTranslate: handleTranslateFinal } = useTranslateMessage(
    items,
    setItems,
    (errMsg) => setError(errMsg)
  );

  // -------------------------------------------------------------------------
  // 9) Surveiller scenarioError => fusionner avec error
  // -------------------------------------------------------------------------
  useEffect(() => {
    if (scenarioError) {
      setError(scenarioError);
      setScenarioError(null);
    }
  }, [scenarioError, setScenarioError]);

  // -------------------------------------------------------------------------
  // 10) handleConnectingChange => indicateur de “connexion en cours”
  // -------------------------------------------------------------------------
  const [isConnecting, setIsConnecting] = useState(false);

  const handleConnectingChange = useCallback((connecting: boolean) => {
    setIsConnecting(connecting);
  }, []);

  // -------------------------------------------------------------------------
  // 11) Compléter scénario si sessionMessageCount atteint X
  // -------------------------------------------------------------------------
  useEffect(() => {
    if (
      sessionMessageCount >= MESSAGE_LIMITS.MESSAGES_REQUIRED_TO_COMPLETE_SCENARIO
    ) {
      markScenarioAsCompleted();
    }
  }, [sessionMessageCount, markScenarioAsCompleted]);

  // -------------------------------------------------------------------------
  // 12) On retourne tout => usage dans ConsolePage
  // -------------------------------------------------------------------------
  return {
    // A) Données conversation
    items,
    setItems,
    sessionMessageCount,

    // B) UI states
    showRegisterEmailForm,
    showSuccessMessage,
    showScenarioSuccessMessage,
    scenarioAlreadyCompleted,
    isLimitReached,
    setIsLimitReached,
    error,
    isConnecting,

    // C) Méthodes
    handleSignUpSuccess,
    handleTranslate: handleTranslateFinal,
    handleRealtimeEvent,
    handleConnectingChange,
    setShowRegisterEmailForm,

    // D) Prompt GPT (instructions)
    dynamicInstructions,

    // E) Streak
    showStreakAnimation,
    showStreakSuccessMessage,

    // F) Contrôle micro (limites) => anonyme
    checkIfLimitReached,
  };
}