// src/components/Footer.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AVAILABLE_LANGUAGES = [
  { code: 'ar', label: 'العربية' },
  { code: 'de', label: 'Deutsch' },
  { code: 'en', label: 'English' },
  { code: 'es', label: 'Español' },
  { code: 'fr', label: 'Français' },
  { code: 'hi', label: 'हिंदी' },
  { code: 'it', label: 'Italiano' },
  { code: 'ja', label: '日本語' },
  { code: 'ko', label: '한국어' },
  { code: 'nl', label: 'Nederlands' },
  { code: 'pl', label: 'Polski' },
  { code: 'pt', label: 'Português' },
  { code: 'ru', label: 'Русский' },
  { code: 'tr', label: 'Türkçe' },
  { code: 'zh', label: '中文' },
  { code: 'id', label: 'Bahasa Indonesia' },
  { code: 'ur', label: 'اردو' },
];

export const Footer: React.FC = () => {
  // Utilisation du namespace "footer" pour les traductions
  const { t } = useTranslation('footer');

  return (
    <footer className="w-full bg-gray-50 text-gray-700 py-10 px-4 mt-8">
      <div className="max-w-screen-xl mx-auto">
        {/* SECTION 1: 4 colonnes – Company, Products, Resources, Legal */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          {/* COMPANY */}
          <div>
            <h3 className="font-bold mb-3 uppercase text-[1.2rem]">
              {t('companyTitle')}
            </h3>
            <ul className="space-y-2 text-[1.2rem]">
              <li>
                <Link to="/about#about" className="hover:underline">
                  {t('aboutLink')}
                </Link>
              </li>
              <li>
                <Link to="/mission-vision" className="hover:underline">
                  {t('missionVisionLink')}
                </Link>
              </li>
              <li>
                <Link to="/team" className="hover:underline">
                  {t('teamLink')}
                </Link>
              </li>
              <li>
                <Link to="/careers" className="hover:underline">
                  {t('careersLink')}
                </Link>
              </li>
              <li>
                <Link to="/press" className="hover:underline">
                  {t('pressLink')}
                </Link>
              </li>
              <li>
                <Link to="/contact" className="hover:underline">
                  {t('contactLink')}
                </Link>
              </li>
            </ul>
          </div>

          {/* PRODUCTS */}
          <div>
            <h3 className="font-bold mb-3 uppercase text-[1.2rem]">
              {t('productsTitle')}
            </h3>
            <ul className="space-y-2 text-[1.2rem]">
              <li>{t('featuresLink')}</li>
              <li>
                <Link to="/pricing" className="hover:underline">
                  {t('pricingPlansLink')}
                </Link>
              </li>
              <li>{t('forEducationLink')}</li>
              <li>{t('forBusinessesLink')}</li>
              <li>{t('conversationalAiLink')}</li>
            </ul>
          </div>

          {/* RESOURCES */}
          <div>
            <h3 className="font-bold mb-3 uppercase text-[1.2rem]">
              {t('resourcesTitle')}
            </h3>
            <ul className="space-y-2 text-[1.2rem]">
              <li>
                <Link to="/blog" className="hover:underline">
                  {t('blogLink')}
                </Link>
              </li>
              <li>
                <Link to="/faq" className="hover:underline">
                  {t('faqLink')}
                </Link>
              </li>
              <li>{t('helpCenterLink')}</li>
              <li>{t('tutorialsLink')}</li>
            </ul>
          </div>

          {/* LEGAL */}
          <div>
            <h3 className="font-bold mb-3 uppercase text-[1.2rem]">
              {t('legalTitle')}
            </h3>
            <ul className="space-y-2 text-[1.2rem]">
              <li>{t('legalNoticeLink')}</li>
              <li>{t('termsOfUseLink')}</li>
              <li>{t('privacyPolicyLink')}</li>
              <li>{t('cookiesLink')}</li>
            </ul>
          </div>
        </div>

        {/* Divider */}
        <div className="border-t border-gray-300 my-8" />

        {/* SECTION 2: 4 colonnes – Contact, Downloads, Social */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          {/* CONTACT */}
          <div>
            <h3 className="font-bold mb-3 uppercase text-[1.2rem]">
              {t('contactTitle')}
            </h3>
            <ul className="space-y-2 text-[1.2rem]">
              <li>{t('addressLabel')}</li>
              <li>{t('emailLabel')}</li>
            </ul>
          </div>

          {/* DOWNLOADS */}
          <div>
            <h3 className="font-bold mb-3 uppercase text-[1.2rem]">
              {t('downloadsTitle')}
            </h3>
            <ul className="space-y-2 text-[1.2rem]">
              <li>
                <a href="#app-store" className="hover:underline">
                  {t('iosAppLink')}
                </a>
              </li>
              <li>
                <a href="#google-play" className="hover:underline">
                  {t('androidAppLink')}
                </a>
              </li>
            </ul>
          </div>

          {/* SOCIAL */}
          <div>
            <h3 className="font-bold mb-3 uppercase text-[1.2rem]">
              {t('socialTitle')}
            </h3>
            <ul className="space-y-2 text-[1.2rem]">
              <li>
                <Link to="/blog" className="hover:underline">
                  {t('socialBlogLink')}
                </Link>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/voxanet"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  {t('instagramLink')}
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/voxanet"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  {t('facebookLink')}
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/voxanet"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  {t('twitterLink')}
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/voxanet"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  {t('youtubeLink')}
                </a>
              </li>
            </ul>
          </div>

          {/* Empty column for alignment */}
          <div />
        </div>

        {/* Divider */}
        <div className="border-t border-gray-300 my-8" />

        {/* SECTION 3: Languages */}
        <div>
          <h3 className="font-bold mb-3 uppercase text-[1.2rem]">
            {t('siteLanguageLabel')}
          </h3>
          <ul className="flex flex-wrap gap-2 text-[1.2rem]">
            {AVAILABLE_LANGUAGES.map(({ code, label }) => (
              <li key={code}>
                <a
                  href={`/${code}/`}
                  className="hover:underline text-gray-700"
                  rel="alternate"
                  hrefLang={code}
                >
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* Divider and Copyright */}
        <div className="border-t border-gray-300 mt-8 pt-4 flex justify-center">
          <div className="text-[1.2rem] text-gray-500">
            {t('copyrightNotice')}
          </div>
        </div>
      </div>
    </footer>
  );
};