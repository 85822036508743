// src/pages/Marketing/MissionVisionPage.tsx
import React from 'react';
import { Layout } from '../../components/Layout';
import { Header } from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import { useTranslation } from 'react-i18next';

/**
 * Page "Mission & Vision", accessible via :
 * - "/mission-vision" (langless) => redirige ensuite vers "/:lang/mission-vision"
 * - un lien direct dans le Footer (par ex. <Link to="/mission-vision" />).
 */
const MissionVisionPage: React.FC = () => {
  const navigate = useNavigate();
  const { interfaceLanguage } = useLanguage();

  // On utilisera le namespace "missionVisionPage" pour les traductions
  const { t } = useTranslation('missionVisionPage');

  const handleLogoClick = () => {
    // Redirige vers la page d'accueil dans la langue courante, ex. "/fr" ou "/en"
    navigate(`/${interfaceLanguage || 'en'}`);
  };

  const handleGetStarted = () => {
    // Redirige vers la page d'onboarding "/:lang/language"
    navigate(`/${interfaceLanguage || 'en'}/language`);
  };

  return (
    <Layout noMaxWidth={false}>
      {/* HEADER commun */}
      <Header isAbsolute={false} onLogoClick={handleLogoClick} />

      {/* CONTENU PRINCIPAL */}
      <div
        className="py-10 text-gray-700 leading-relaxed text-[1.2rem]
                   sm:text-[1.25rem] md:text-[1.375rem] lg:text-[1.65rem]"
      >
        {/* TITRE PRINCIPAL */}
        <h1 className="text-center text-3xl md:text-4xl font-bold mb-16">
          {t('pageTitle')} 
          {/* ex. "Mission & Vision" */}
        </h1>

        {/* SECTION 1 : Intro */}
        <div className="flex flex-wrap items-start justify-between my-[4.8rem]">
          {/* Colonne image (gauche) */}
          <div className="w-full md:w-1/2 px-4 order-1">
            <img
              src="/missionvision/missionvision-image-1.jpeg"
              alt={t('section1ImageAlt')} 
              className="w-full h-[416px] object-cover border-2 border-dashed border-gray-400 mb-4"
            />
          </div>
          {/* Colonne texte (droite) */}
          <div className="w-full md:w-1/2 px-4 order-2">
            <h2 className="text-2xl md:text-3xl font-bold mb-2">
              {t('section1Title')} 
              {/* ex. "Who We Are" */}
            </h2>
            <p className="mb-4">
              {t('section1Paragraph1')}
            </p>
            <p className="mb-4">
              {t('section1Paragraph2')}
            </p>
          </div>
        </div>

        <div className="border-t border-gray-300 my-[3.6rem]" />

        {/* SECTION 2 : Mission */}
        <div className="flex flex-wrap items-start justify-between my-[4.8rem]">
          {/* Colonne texte (ordre inversé en desktop) */}
          <div className="w-full md:w-1/2 px-4 order-2 md:order-1">
            <h2 className="text-2xl md:text-3xl font-bold mb-2">
              {t('section2Title')} 
              {/* ex. "Our Mission" */}
            </h2>
            <p className="mb-4">
              {t('section2Paragraph1')}
            </p>
            <p className="mb-4">
              {t('section2Paragraph2')}
            </p>
          </div>
          {/* Colonne image */}
          <div className="w-full md:w-1/2 px-4 order-1 md:order-2">
            <img
              src="/missionvision/missionvision-image-2.webp"
              alt={t('section2ImageAlt')} 
              className="w-full h-[416px] object-cover border-2 border-dashed border-gray-400 mb-4"
            />
          </div>
        </div>

        <div className="border-t border-gray-300 my-[3.6rem]" />

        {/* SECTION 3 : Vision */}
        <div className="flex flex-wrap items-start justify-between my-[4.8rem]">
          {/* Colonne image (gauche) */}
          <div className="w-full md:w-1/2 px-4 order-1">
            <img
              src="/missionvision/missionvision-image-3.png"
              alt={t('section3ImageAlt')} 
              className="w-full h-[416px] object-cover border-2 border-dashed border-gray-400 mb-4"
            />
          </div>
          {/* Colonne texte (droite) */}
          <div className="w-full md:w-1/2 px-4 order-2">
            <h2 className="text-2xl md:text-3xl font-bold mb-2">
              {t('section3Title')} 
              {/* ex. "Our Vision" */}
            </h2>
            <p className="mb-4">
              {t('section3Paragraph1')}
            </p>
            <p className="mb-4">
              {t('section3Paragraph2')}
            </p>
          </div>
        </div>

        <div className="border-t border-gray-300 my-[3.6rem]" />

        {/* SECTION 4 : Conclusion */}
        <div className="flex flex-wrap items-start justify-between my-[4.8rem]">
          {/* Colonne texte (gauche) */}
          <div className="w-full md:w-1/2 px-4 order-2 md:order-1">
            <h2 className="text-2xl md:text-3xl font-bold mb-2">
              {t('section4Title')} 
              {/* ex. "Join Our Journey" */}
            </h2>
            <p className="mb-4">
              {t('section4Paragraph1')}
            </p>
            <p className="mb-4">
              {t('section4Paragraph2')}
            </p>
          </div>
          {/* Colonne image (droite) */}
          <div className="w-full md:w-1/2 px-4 order-1 md:order-2">
            <img
              src="/missionvision/missionvision-image-4.avif"
              alt={t('section4ImageAlt')} 
              className="w-full h-[416px] object-cover border-2 border-dashed border-gray-400 mb-4"
            />
          </div>
        </div>

        {/* Bouton CTA final */}
        <div className="flex justify-center mt-16">
          <button
            onClick={handleGetStarted}
            className="w-[28rem] whitespace-nowrap px-8 py-4 uppercase text-white font-semibold
                       text-[1.3rem] md:text-[1.5rem] rounded-lg shadow-lg transition-transform
                       transform hover:scale-105 focus:outline-none focus:ring-4 
                       focus:ring-blue-300 bg-[#3f7afd] hover:bg-[#2f69df]"
          >
            {t('ctaButton')}
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default MissionVisionPage;