// src/components/OnboardingLayout.tsx

import React from 'react';
import { Layout } from './Layout';
import { OnboardingProgressBar } from './OnboardingProgressBar';
import { WorkflowStep } from '../types/WorkflowStep';

interface OnboardingLayoutProps {
  currentStep: WorkflowStep;
  title?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

/**
 * OnboardingLayout "hybride" :
 *  - Mobile : h-screen, sticky header & footer, contenu scrollable au milieu.
 *  - Desktop : h-auto, barre & footer dans le flux normal, contenu centré verticalement.
 */
export function OnboardingLayout({
  currentStep,
  title,
  children,
  footer,
}: OnboardingLayoutProps) {
  return (
    <Layout>
      <div
        // On regroupe "h-screen md:h-auto" et "overflow-hidden md:overflow-y-auto"
        className="
          relative
          w-full
          flex
          flex-col
          h-screen md:h-auto
          overflow-hidden md:overflow-y-auto
        "
      >
        {/* (A) Barre de progression + titre */}
        <div
          // Garde un espace de 4mm sur mobile, revert à top-0 sur desktop
          className="
            sticky
            top-[4mm]
            z-20
            bg-white
            shadow-sm

            md:static
            md:mt-[75px]
            md:shadow-none
            md:px-4
            md:top-0
          "
        >
          <OnboardingProgressBar currentStep={currentStep} />

          {/* Titre optionnel */}
          {title && (
            <h1
              className="
                text-4xl
                font-extrabold
                text-[#243238]
                leading-tight
                text-center
                mt-2
                mb-4       /* Ajout d'une marge-basse pour aérer la zone */
                px-4

                md:text-7xl
                md:mt-12
                md:mb-12   /* On peut accentuer la marge sur desktop si besoin */
              "
            >
              {title}
            </h1>
          )}
        </div>

        {/* (B) Contenu => scrollable sur mobile, centré sur desktop */}
        <div
          className="
            flex-1
            overflow-y-auto
            px-4
            pt-4       /* Plus grand padding-top sur mobile, pour compenser la barre sticky */
            pb-2

            md:overflow-visible
            md:px-4
            md:pt-0    /* Remis à 0 sur desktop */
            md:pb-0
            md:w-full
            md:max-w-[45rem]
            md:mx-auto
            md:mt-[8.5rem]
            md:flex
            md:flex-col
            md:items-center
            md:justify-center
            md:-translate-y-[75px]
          "
        >
          {children}
        </div>

        {/* (C) Footer => sticky bottom-0 sur mobile, dans le flux sur desktop */}
        {footer && (
          <div
            className="
              sticky bottom-0
              z-20
              bg-white
              border-t border-gray-300
              p-4

              md:static
              md:border-0
              md:p-0
              md:mt-2
              md:flex
              md:items-center
              md:justify-center
            "
          >
            {footer}
          </div>
        )}
      </div>
    </Layout>
  );
}