// ---------------------------------------------------------------------
// src/pages/ConversationScenarioSelection/useConversationScenarioSelection.ts
// ---------------------------------------------------------------------

import { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getApiUrl } from '../../config/environment';
import { SPECIAL_USERS } from '../../config/limits';
import { useAuth } from '../../context/AuthContext';
import { useStudy } from '../../context/StudyContext';
import { useTotalMessageCount } from '../../context/TotalMessageCountContext';
import { useUser, ScenarioData } from '../../context/UserContext';

import { scenarios } from './scenariosList';
import { groupScenarios } from './scenarioUtils';

interface UseConversationScenarioSelectionProps {
  onScenarioSelect: () => void; // callback fourni par le parent
}

export function useConversationScenarioSelection({
  onScenarioSelect,
}: UseConversationScenarioSelectionProps) {
  // -- 1) Hooks & context
  const { currentUser } = useAuth();
  const { studyLanguage, setSelectedScenario } = useStudy();
  const { totalMessageCount, refreshTotalMessageCount } = useTotalMessageCount();
  const { userData, refreshUserData } = useUser();

  // -- i18n
  const { t: tScenarios } = useTranslation('scenarios');
  const { t: tRegister } = useTranslation('registerEmailForm');

  // -- Récupère la langue courante dans l'URL
  const { lang } = useParams<{ lang?: string }>();
  const navigate = useNavigate();

  // -- 2) États locaux
  const [highlightedScenario, setHighlightedScenario] = useState<string | null>(null);
  const [showRegisterEmailForm, setShowRegisterEmailForm] = useState(false);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [scenarioChoiceModalOpen, setScenarioChoiceModalOpen] = useState(false);
  const [pendingScenarioId, setPendingScenarioId] = useState<string | null>(null);
  const [pendingScenarioTitle, setPendingScenarioTitle] = useState<string>('');

  // -- 3) Groupement de scénarios
  const scenarioGroups = useMemo(() => groupScenarios(scenarios, 5), []);

  // -- 4) useEffect => refreshUserData
  useEffect(() => {
    refreshUserData().catch((err) => {
      console.error('[ConversationScenarioSelection] => refreshUserData ERROR =>', err);
    });
  }, [refreshUserData]);

  // Juste pour debug
  useEffect(() => {
    if (userData) {
      console.log('[ConversationScenarioSelection] => userData changed =>', {
        dailyStreak: userData.dailyStreak,
        streakMessagesToday: userData.streakMessagesToday,
      });
    }
  }, [userData]);

  // -- 5) completedScenarios
  const completedScenarios = useMemo(() => {
    const studyLangObj = userData?.studyLanguages?.find(
      (sl) => sl.language === studyLanguage
    );
    if (!studyLangObj) return [];
    return (
      studyLangObj.scenarios
        ?.filter((sc: ScenarioData) => sc.completed)
        .map((s: ScenarioData) => s.scenarioId) || []
    );
  }, [userData, studyLanguage]);

  // -- 6) Index du groupe “débloqué”
  const unlockedGroupIndex = useMemo(() => {
    let unlocked = 0;
    for (let i = 0; i < scenarioGroups.length; i++) {
      const group = scenarioGroups[i];
      const allCompleted = group.every((sc: any) =>
        completedScenarios.includes(sc.id)
      );
      if (allCompleted) {
        unlocked = i + 1;
      } else {
        break;
      }
    }
    return Math.min(unlocked, scenarioGroups.length - 1);
  }, [completedScenarios, scenarioGroups]);

  // -- 7) Reset highlight + scroll top
  useEffect(() => {
    setHighlightedScenario(null);
    window.scrollTo(0, 0);
  }, []);

  // -- 8) checkHasExistingConversation
  function checkHasExistingConversation(scenarioId: string): boolean {
    if (!userData) return false;

    const studyLangObj = userData.studyLanguages?.find(
      (sl) => sl.language === studyLanguage
    );
    if (!studyLangObj) return false;

    const scenarioObj = studyLangObj.scenarios?.find(
      (s) => s.scenarioId === scenarioId
    );
    if (!scenarioObj) return false;

    // Trie par lastMessageAt DESC
    const sorted = scenarioObj.conversations?.slice().sort((a, b) => {
      const dateA = new Date(a.lastMessageAt ?? 0).getTime();
      const dateB = new Date(b.lastMessageAt ?? 0).getTime();
      return dateB - dateA;
    });
    const newestConv = sorted?.[0];
    if (!newestConv) return false;

    // On regarde s'il y a au moins un vrai message user
    const hasRealUserMsg = newestConv.messages?.some(
      (m) => m.role === 'user' && m.content.trim().toLowerCase() !== 'start'
    );

    return !!hasRealUserMsg;
  }

  // -- 9) updateScenarioInBackend
  async function updateScenarioInBackend(scenarioId: string, forceNewConv: boolean) {
    if (!currentUser) return;

    // Fixe localement le scénario sélectionné
    setSelectedScenario(scenarioId);

    try {
      const res = await fetch(`${getApiUrl()}/api/scenario/updateScenarios`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uid: currentUser.uid,
          studyLanguage,
          scenarioId,
          lastAccessed: new Date(),
          forceNewConversation: forceNewConv,
        }),
      });

      console.log('[updateScenarioInBackend] => response status=', res.status);
      if (!res.ok) {
        throw new Error(`Failed to save scenario: HTTP ${res.status}`);
      }

      // Si forceNewConv => on va sur "/:lang/console?fresh=1"
      if (forceNewConv) {
        const routeLang = lang || 'fr';
        navigate(`/${routeLang}/console?fresh=1`);
      } else {
        // Sinon, on continue l’ancienne conversation
        onScenarioSelect();
      }
    } catch (err) {
      console.error('[updateScenarioInBackend] => Error =>', err);
    }
  }

  // -- 10) handleScenarioSelect
  const handleScenarioSelect = async (scenarioId: string) => {
    console.log('[handleScenarioSelect] => user clicked scenarioId=', scenarioId);
    setHighlightedScenario(scenarioId);

    if (!currentUser) {
      console.warn('[handleScenarioSelect] => No user => abort');
      return;
    }

    // (A) Vérif limites
    if (!SPECIAL_USERS.UNLIMITED_ACCESS.includes(currentUser.uid)) {
      // userData => check sub
      const subscriptionEndDate = userData?.subscriptionEndDate
        ? new Date(userData.subscriptionEndDate)
        : null;
      const isSubActive = subscriptionEndDate && subscriptionEndDate > new Date();

      // Si pas abonné + anonyme => check
      if (!isSubActive && userData?.isAnonymous) {
        const ANON_LIMIT = 8;
        if ((totalMessageCount || 0) >= ANON_LIMIT) {
          setShowRegisterEmailForm(true);
          return;
        }
      }

      // Appel backend canSelectScenario => 403 => limit
      try {
        const checkUrl = `${getApiUrl()}/api/scenario/canSelectScenario?uid=${currentUser.uid}`;
        const checkRes = await fetch(checkUrl);
        if (checkRes.status === 403) {
          setIsLimitReached(true);
          return;
        }
        if (!checkRes.ok) {
          throw new Error(`Backend returned ${checkRes.status}`);
        }
      } catch (error) {
        console.error('[handleScenarioSelect] => Error checking scenario limit =>', error);
        return;
      }
    }

    // (B) Refresh userData
    try {
      await refreshUserData();
      console.log('[handleScenarioSelect] => refreshUserData done =>', userData);
    } catch (err) {
      console.warn('[handleScenarioSelect] => refreshUserData => error =>', err);
    }

    // (C) Check conversation existante
    const alreadyHasConversation = checkHasExistingConversation(scenarioId);

    // (D) Récupère titre i18n
    const scenarioTitleKey = scenarios.find((s) => s.id === scenarioId)?.titleKey;
    const scenarioTitle = scenarioTitleKey
      ? tScenarios(
          `conversationScenarioSelection.scenarios.${scenarioTitleKey.replace(
            'conversationScenarioSelection.scenarios.',
            ''
          )}.title`
        )
      : scenarioId;

    // (E) Popup si conv existe
    if (alreadyHasConversation) {
      setPendingScenarioId(scenarioId);
      setPendingScenarioTitle(scenarioTitle || scenarioId);
      setScenarioChoiceModalOpen(true);
    } else {
      // Sinon => création forcée
      await updateScenarioInBackend(scenarioId, true);
    }
  };

  // -- 11) handleSignUpSuccess
  const handleSignUpSuccess = async () => {
    setShowRegisterEmailForm(false);
    setShowSuccessMessage(true);
    await refreshTotalMessageCount();
    // Cache le message de succès après 5s
    setTimeout(() => setShowSuccessMessage(false), 5000);
  };

  // -- 12) Retourne tout ce qu'il faut au composant principal
  return {
    // i18n
    tScenarios,
    tRegister,

    // États & données calculées
    highlightedScenario,
    showRegisterEmailForm,
    isLimitReached,
    showSuccessMessage,
    scenarioChoiceModalOpen,
    pendingScenarioId,
    pendingScenarioTitle,

    scenarioGroups,
    completedScenarios,
    unlockedGroupIndex,
    totalMessageCount,
    userData,

    // Handlers
    handleScenarioSelect,
    handleSignUpSuccess,
    updateScenarioInBackend, // utilisé par le modal
    setScenarioChoiceModalOpen,
    setPendingScenarioId,
    setPendingScenarioTitle,
    setShowRegisterEmailForm, // si besoin
  };
}