// src/pages/ConsolePage/StreakObjectivePage.tsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VoxButton } from '../../components/VoxButton';

interface StreakObjectivePageProps {
  onGoalSelected: (goal: number) => void;
  onClose: () => void;
}

export const StreakObjectivePage: React.FC<StreakObjectivePageProps> = ({
  onGoalSelected,
  onClose,
}) => {
  const { t } = useTranslation('streakObjectivePage');

  // Options d'objectif (en jours d'affilée)
  const possibleGoals = [7, 14, 21, 30];
  const [selectedGoal, setSelectedGoal] = useState<number | null>(null);

  const handleSelectGoal = (goal: number) => {
    setSelectedGoal(goal);
  };

  const handleConfirm = () => {
    if (selectedGoal !== null) {
      onGoalSelected(selectedGoal);
      onClose();
    } else {
      alert(t('errorNoSelection', 'Veuillez sélectionner un objectif.'));
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-4 py-6 bg-white">
      <div className="w-full max-w-md bg-white shadow-md rounded-lg p-6 text-center">
        {/* Titre principal */}
        <h1 className="text-2xl font-bold text-gray-800 mb-4">
          {t('title', 'Fixe-toi un objectif pour rester motivé !')}
        </h1>

        {/* Message d'encouragement */}
        <p className="text-gray-700 mb-6">
          {t(
            'intro',
            "Choisis le nombre de jours d'affilée que tu veux tenir et construis ta série !"
          )}
        </p>

        {/* Image de motivation */}
        <img
          src="/assets/images/streak-objective.jpg"
          alt={t('imageAlt', "Photo inspirante pour fixer un objectif")}
          className="mx-auto mb-6 w-48 h-auto object-contain"
        />

        {/* Boutons de sélection */}
        <div className="flex flex-wrap justify-center gap-4 mb-6">
          {possibleGoals.map((goal) => (
            <VoxButton
              key={goal}
              variant={selectedGoal === goal ? 'primary' : 'secondary'}
              onClick={() => handleSelectGoal(goal)}
            >
              {t('daysOption', '{{count}} jours d\'affilée', { count: goal })}
            </VoxButton>
          ))}
        </div>

        {/* Bouton de confirmation */}
        <VoxButton variant="primary" onClick={handleConfirm}>
          {t('confirm', 'Valider mon objectif')}
        </VoxButton>
      </div>
    </div>
  );
};

export default StreakObjectivePage;