// src/pages/Marketing/AboutPage.tsx
import React from 'react';
import { Layout } from '../../components/Layout';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import { useTranslation } from 'react-i18next';
import { Header } from '../../components/Header';

const AboutPage: React.FC = () => {
  const navigate = useNavigate();
  const { interfaceLanguage } = useLanguage();
  const { t } = useTranslation('aboutPage');

  const handleLogoClick = () => {
    // Retourne à l'accueil dans la langue courante, ex. "/fr" ou "/en"
    navigate(`/${interfaceLanguage || 'en'}`);
  };

  // Mise à jour : redirection vers "/language" pour correspondre au comportement du bouton sur la landing page
  const handleLetsGo = () => {
    console.log('[AboutPage] "Allons-y" cliqué');
    navigate(`/${interfaceLanguage || 'en'}/language`);
  };

  return (
    <Layout noMaxWidth={false}>
      {/* Utilisation du Header commun */}
      <Header isAbsolute={false} onLogoClick={handleLogoClick} />

      {/* CONTENU DE LA PAGE ABOUT */}
      <div className="py-10 text-gray-700 leading-relaxed text-[1.2rem] sm:text-[1.25rem] md:text-[1.375rem] lg:text-[1.65rem]">
        {/* Titre principal */}
        <h1 className="text-center text-3xl md:text-4xl font-bold mb-16">
          {t('pageTitle')}
        </h1>

        {/* SECTION 1 : Notre histoire (IMAGE GAUCHE - TEXTE DROITE) */}
        <div className="flex flex-wrap items-start justify-between my-[4.8rem]">
          {/* Colonne image */}
          <div className="w-full md:w-1/2 px-4 order-1">
            <img
              src="/about/about-image-1.webp"
              alt={t('section1ImageAlt')}
              className="w-full h-80 object-cover border-2 border-dashed border-gray-400 mb-4"
            />
          </div>
          {/* Colonne texte */}
          <div className="w-full md:w-1/2 px-4 order-2">
            <h2 className="text-2xl md:text-3xl font-bold mb-2">
              {t('section1Title')}
            </h2>
            <p className="mb-4">{t('section1Desc')}</p>
          </div>
        </div>

        <div className="border-t border-gray-300 my-[3.6rem]" />

        {/* SECTION 2 : Un enseignement personnalisé (TEXTE GAUCHE - IMAGE DROITE) */}
        <div className="flex flex-wrap items-start justify-between my-[4.8rem]">
          {/* Colonne texte (ordre inversé en desktop) */}
          <div className="w-full md:w-1/2 px-4 order-2 md:order-1">
            <h2 className="text-2xl md:text-3xl font-bold mb-2">
              {t('section2Title')}
            </h2>
            <p className="mb-4">{t('section2Desc')}</p>
          </div>
          {/* Colonne image */}
          <div className="w-full md:w-1/2 px-4 order-1 md:order-2">
            <img
              src="/about/about-image-2.png"
              alt={t('section2ImageAlt')}
              className="w-full h-80 object-cover border-2 border-dashed border-gray-400 mb-4"
            />
          </div>
        </div>

        <div className="border-t border-gray-300 my-[3.6rem]" />

        {/* SECTION 3 : Quand apprendre devient un jeu (IMAGE GAUCHE - TEXTE DROITE) */}
        <div className="flex flex-wrap items-start justify-between my-[4.8rem]">
          {/* Colonne image */}
          <div className="w-full md:w-1/2 px-4 order-1">
            <img
              src="/about/about-image-3.png"
              alt={t('section3ImageAlt')}
              className="w-full h-80 object-cover border-2 border-dashed border-gray-400 mb-4"
            />
          </div>
          {/* Colonne texte */}
          <div className="w-full md:w-1/2 px-4 order-2">
            <h2 className="text-2xl md:text-3xl font-bold mb-2">
              {t('section3Title')}
            </h2>
            <p className="mb-4">{t('section3Desc')}</p>
          </div>
        </div>

        <div className="border-t border-gray-300 my-[3.6rem]" />

        {/* SECTION 4 : Accessible à tous (TEXTE GAUCHE - IMAGE DROITE) */}
        <div className="flex flex-wrap items-start justify-between my-[4.8rem]">
          {/* Colonne texte (ordre inversé en desktop) */}
          <div className="w-full md:w-1/2 px-4 order-2 md:order-1">
            <h2 className="text-2xl md:text-3xl font-bold mb-2">
              {t('section4Title')}
            </h2>
            <p className="mb-4">{t('section4Desc')}</p>
          </div>
          {/* Colonne image */}
          <div className="w-full md:w-1/2 px-4 order-1 md:order-2">
            <img
              src="/about/about-image-4.webp"
              alt={t('section4ImageAlt')}
              className="w-full h-80 object-cover border-2 border-dashed border-gray-400 mb-4"
            />
          </div>
        </div>

        {/* Bouton CTA final */}
        <div className="flex justify-center mt-16">
          <button
            onClick={handleLetsGo}
            className="w-[28rem] whitespace-nowrap px-8 py-4 uppercase text-white font-semibold text-[1.3rem] md:text-[1.5rem] rounded-lg shadow-lg transition-transform transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-blue-300 bg-[#3f7afd] hover:bg-[#2f69df]"
          >
            {t('ctaButton')}
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;