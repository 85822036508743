// --------------------------------------
// src/components/routing/LanglessRedirect.tsx
// --------------------------------------
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserLang } from '../../hooks/useUserLang';

/**
 * LanglessRedirect :
 *   - Permet de rediriger "/contact" => "/fr/contact"
 *   - Propriété "to" = "contact", "about", "pricing", etc.
 */
type LanglessRedirectProps = {
  to: string;
};

export function LanglessRedirect({ to }: LanglessRedirectProps) {
  const navigate = useNavigate();
  const userLang = useUserLang();

  useEffect(() => {
    // Ex. "/contact" => "/fr/contact"
    navigate(`/${userLang}/${to}`, { replace: true });
  }, [userLang, to, navigate]);

  return null;
}
