// src/pages/DailyGoalPage.tsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingLayout } from '../components/OnboardingLayout';
import { VoxButton } from '../components/VoxButton';
import { VoxCard } from '../components/VoxCard';

interface DailyGoalPageProps {
  onNext?: () => void;
}

type GoalOption = {
  value: number;
  label: string;
};

const DailyGoalPage: React.FC<DailyGoalPageProps> = ({ onNext }) => {
  const { t } = useTranslation('daily-goal');

  // Options : minutes par jour
  const goalOptions: GoalOption[] = [
    { value: 5,  label: t('options.five', '5 minutes / jour') },
    { value: 10, label: t('options.ten', '10 minutes / jour') },
    { value: 15, label: t('options.fifteen', '15 minutes / jour') },
    { value: 20, label: t('options.twenty', '20 minutes / jour') },
  ];

  // État local : option sélectionnée
  const [selectedGoal, setSelectedGoal] = useState<number | null>(null);

  // Sélection d'une option
  const handleOptionClick = (value: number) => {
    setSelectedGoal(value);
  };

  // Validation
  const handleNext = () => {
    console.log('Objectif quotidien sélectionné :', selectedGoal, 'min/jour');
    onNext?.();
  };

  // Désactivation du bouton si aucune option n'est sélectionnée
  const isNextDisabled = selectedGoal === null;

  // Correction du titre (remplacement de " ?" par "\u00A0?")
  const rawTitle = t('title', 'Quel est ton objectif quotidien ?');
  const pageTitle = rawTitle.replace(' ?', '\u00A0?');

  // Footer => Bouton "Next"
  const footer = (
    <div className="flex justify-center">
      <VoxButton
        variant="primary"
        width="auto"
        disabled={isNextDisabled}
        onClick={handleNext}
      >
        {t('next', 'Suivant')}
      </VoxButton>
    </div>
  );

  return (
    <OnboardingLayout currentStep="dailyGoal" title={pageTitle} footer={footer}>
      <div className="flex flex-col items-center px-4 text-xl md:text-2xl mt-4 md:mt-8 w-full max-w-screen-md mx-auto">
        {/* Icône */}
        <img
          src="/daily-goal/clock-icon.png"
          alt="Clock icon"
          className="mx-auto h-16 w-16 md:h-20 md:w-20 object-contain mb-4"
        />

        {/* Liste d'options */}
        <div className="flex flex-col gap-6 mt-4 mb-8 w-full max-w-md">
          {goalOptions.map((opt) => {
            const isSelected = opt.value === selectedGoal;
            return (
              <VoxCard
                key={opt.value}
                hoverable
                selected={isSelected}
                onClick={() => handleOptionClick(opt.value)}
                // Ajout de "justify-center" pour centrer horizontalement le texte
                className="hover:scale-105 justify-center"
              >
                <span className="font-bold text-[#243238] leading-snug">
                  {opt.label}
                </span>
              </VoxCard>
            );
          })}
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default DailyGoalPage;