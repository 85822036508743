// src/context/LanguageContext.tsx

import React, { createContext, useState, useContext, useEffect } from 'react';
import i18n from '../i18n';

/**
 * Interface exposée par ce contexte :
 *  - detectedBrowserLanguage : la langue détectée initialement (navigateur)
 *  - interfaceLanguage       : la langue d’affichage du site (pilotée par i18n)
 *  - setInterfaceLanguage    : setter pour changer la langue d’interface
 */
interface LanguageContextType {
  detectedBrowserLanguage: string;
  interfaceLanguage: string;
  setInterfaceLanguage: (lang: string) => void;
}

// Log initial de détection de la langue
console.log('[LanguageContext] (NOUVEAU LOG) Détection initiale =>', {
  fromI18n: i18n.language,
  fromNavigator: navigator.language,
});

// Choix final : i18n.language ou navigator.language, fallback = 'en'
const detectedBrowserLanguage = i18n.language || navigator.language || 'en';
console.log('[LanguageContext] (NOUVEAU LOG) Valeur finale =>', detectedBrowserLanguage);

// Création du contexte avec des valeurs par défaut
const LanguageContext = createContext<LanguageContextType>({
  detectedBrowserLanguage,
  interfaceLanguage: detectedBrowserLanguage,
  setInterfaceLanguage: () => {},
});

console.log('[LanguageContext] (NOUVEAU LOG) Création du LanguageProvider');

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Récupère la langue d’interface depuis localStorage ou fallback
  const savedInterfaceLang = localStorage.getItem('interfaceLanguage') || detectedBrowserLanguage;
  console.log('[LanguageContext] (NOUVEAU LOG) interfaceLanguage stockée depuis localStorage =>', savedInterfaceLang);

  // State local pour la langue d’interface
  const [interfaceLanguage, setInterfaceLanguage] = useState<string>(savedInterfaceLang);

  // Création d'un wrapper pour le setter afin de loguer chaque demande de changement
  const handleSetInterfaceLanguage = (lang: string) => {
    console.log('[LanguageContext] Changement demandé de interfaceLanguage à:', lang);
    setInterfaceLanguage(lang);
  };

  // Chaque fois que interfaceLanguage change, on met à jour i18n, le <html lang>, et localStorage
  useEffect(() => {
    console.log('[LanguageContext] (useEffect) interfaceLanguage change détecté =>', interfaceLanguage);
    i18n.changeLanguage(interfaceLanguage);
    document.documentElement.lang = interfaceLanguage;
    localStorage.setItem('interfaceLanguage', interfaceLanguage);
    console.log('[LanguageContext] Mise à jour appliquée: interfaceLanguage =', interfaceLanguage);
  }, [interfaceLanguage]);

  console.log('[LanguageContext] (Avant return) Valeurs finales =>', {
    finalInterfaceLanguage: interfaceLanguage,
    finalDetectedBrowserLanguage: detectedBrowserLanguage,
  });

  return (
    <LanguageContext.Provider
      value={{
        detectedBrowserLanguage,
        interfaceLanguage,
        setInterfaceLanguage: handleSetInterfaceLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);