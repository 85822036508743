// ---------------------------------------------------------------------
// src/pages/ConsolePage/ConsolePage.tsx
// ---------------------------------------------------------------------
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useConsolePageLogic } from './logic/useConsolePageLogic';
import { useRealtimeWebRTC } from '../../hooks/useRealtimeWebRTC/useRealtimeWebRTC';
import { useAuth } from '../../context/AuthContext';
import { useStudy } from '../../context/StudyContext';
import { useUser } from '../../context/UserContext';

import { ConsolePageHeader } from './ConsolePageHeader';
import { ConsolePageOverlays } from './ConsolePageOverlays';
import { ConversationSection } from './components/ConversationSection';
import { BottomBar } from './components/BottomBar';
import { RealtimeLogViewer } from './RealtimeLogViewer';

import { checkPaywallLimit } from './checkPaywallLimit';
import { MESSAGE_LIMITS } from '../../config/limits';  // <-- Import des limites (10 messages, etc.)

/**
 * -------------------------------------------------------------------------
 * LOGIQUE DU FREE_CHAT (ICEBREAKER & SUGGESTION BUBBLE)
 * -------------------------------------------------------------------------
 * - Au premier "silence" de l’assistant :
 *     -> Si l’utilisateur n’a pas encore parlé, on affiche les 4 boutons
 *        “icebreaker” (showIcebreaker = true).
 *     -> Si l’utilisateur a déjà parlé et qu’on est encore en
 *        “skipSuggestionInFreeChat”, on désactive skipSuggestion.
 *        Sinon, on affiche la suggestion bubble.
 *
 * - Si l’utilisateur clique sur l’un des boutons icebreaker,
 *   le message “user” est envoyé => on masque icebreaker (showIcebreaker = false)
 *   et la suggestion (showSuggestion = false).
 *   À partir du prochain silence, la suggestion bubble pourra apparaître
 *   si skipSuggestionInFreeChat est false.
 *
 * - Si l’utilisateur envoie un message lui-même (par voix ou texte) sans cliquer
 *   sur l’icebreaker, on force immédiatement skipSuggestionInFreeChat = false.
 *   Ainsi, le prochain silence de l’assistant fera apparaître la suggestion bubble.
 *
 * - Pour les autres scénarios, on affiche simplement la suggestion
 *   après le premier silence (handleAssistantSilence => showSuggestion = true).
 *
 * -------------------------------------------------------------------------
 * COMPOSANT : ConsolePage
 * -------------------------------------------------------------------------
 * Gère :
 * - La logique de conversation (via useConsolePageLogic),
 * - L’écoute d’événements “realtime” (via useRealtimeWebRTC),
 * - L’affichage conditionnel : icebreakers, suggestion, etc.,
 * - Le push-to-talk (micro),
 * - La paywall (limite journalière).
 * -------------------------------------------------------------------------
 */

type ConsolePageProps = {
  onExit: () => void;
};

export function ConsolePage({ onExit }: ConsolePageProps) {
  // -------------------------------------------------------------------------
  // 0) Récupération du paramètre ?fresh=1 pour recommencer à zéro
  // -------------------------------------------------------------------------
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isFresh = searchParams.get('fresh') === '1';

  // Contexts
  const { currentUser } = useAuth();
  const { studyLanguage, selectedScenario } = useStudy();
  const { userData, refreshUserData } = useUser();

  // -------------------------------------------------------------------------
  // 1) Logique principale (useConsolePageLogic)
  // -------------------------------------------------------------------------
  const {
    items,
    setItems,
    sessionMessageCount,
    showRegisterEmailForm,
    showSuccessMessage,
    showScenarioSuccessMessage,
    isLimitReached,
    setIsLimitReached,
    error,
    isConnecting,
    handleSignUpSuccess,
    handleTranslate,
    handleRealtimeEvent: originalRealtimeEvent,
    handleConnectingChange,
    dynamicInstructions,
    showStreakAnimation,
    showStreakSuccessMessage,
    checkIfLimitReached,
  } = useConsolePageLogic();

  // -------------------------------------------------------------------------
  // 2) État local : user a‐t‐il déjà parlé ? (pour icebreaker / suggestion)
  // -------------------------------------------------------------------------
  const [hasUserSpoken, setHasUserSpoken] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [revealedPhrase, setRevealedPhrase] = useState<string | null>(null);
  const [showIcebreaker, setShowIcebreaker] = useState(false);

  // Pour free_chat, on saute la suggestion tant que l’utilisateur n’a pas parlé
  const [skipSuggestionInFreeChat, setSkipSuggestionInFreeChat] = useState(
    selectedScenario === 'free_chat'
  );

  // -------------------------------------------------------------------------
  // 3) Charger l’historique ou non, selon ?fresh=1
  //    + gère un petit booléen hasLoadedConversation
  // -------------------------------------------------------------------------
  const [hasLoadedConversation, setHasLoadedConversation] = useState(false);

  useEffect(() => {
    let didLoad = false;

    async function loadLastConversation() {
      if (didLoad) return;
      didLoad = true;

      // Si ?fresh=1 => on veut un démarrage à zéro => on ne charge pas l’historique
      if (isFresh) {
        console.log('[ConsolePage] => fresh=1 => skip loadLastConversation');
        setItems([]);
        setHasLoadedConversation(true); // On signale qu'on a “fini”
        return;
      }

      try {
        // 1) Refresh userData
        await refreshUserData();

        // 2) Chercher la conversation la + récente
        const langObj = userData?.studyLanguages?.find(
          (sl) => sl.language === studyLanguage
        );
        if (!langObj) {
          setHasLoadedConversation(true);
          return;
        }

        const scenarioObj = langObj.scenarios?.find(
          (s) => s.scenarioId === selectedScenario
        );
        if (!scenarioObj || !scenarioObj.conversations?.length) {
          setHasLoadedConversation(true);
          return;
        }

        // 3) Tri par lastMessageAt DESC => conversation la + récente
        const sorted = scenarioObj.conversations.slice().sort(
          (a, b) =>
            new Date(b.lastMessageAt ?? 0).getTime() -
            new Date(a.lastMessageAt ?? 0).getTime()
        );
        const lastConv = sorted[0];
        if (!lastConv) {
          setHasLoadedConversation(true);
          return;
        }

        // 4) Tri asc des messages (par timestamp)
        const sortedMsgs = (lastConv.messages || []).slice().sort(
          (mA, mB) =>
            new Date(mA.timestamp ?? 0).getTime() -
            new Date(mB.timestamp ?? 0).getTime()
        );
        console.log('[ConsolePage] => loadLastConversation => messages:', sortedMsgs);

        // 5) Conversion => items
        const newItems = sortedMsgs.map((m: any) => ({
          id: m._id?.toString() || `msg_${Math.random()}`,
          role: m.role,
          text: m.content,
          status: 'done',
          correction: m.correction || undefined,
          isWaitingCorrection: false,
        }));

        // 6) setItems
        setItems(newItems);
      } catch (err) {
        console.error('[ConsolePage] => loadLastConversation => error =>', err);
      } finally {
        setHasLoadedConversation(true); // On signale qu'on a fini
      }
    }

    loadLastConversation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyLanguage, selectedScenario, isFresh, setItems]);

  // -------------------------------------------------------------------------
  // 4) WebRTC : session audio + dataChannel
  //    => On transmet l’historique UNIQUEMENT quand hasLoadedConversation=true
  //    => On NE met plus autoStartSession: true
  // -------------------------------------------------------------------------
  const {
    isSessionActive,
    isRecording,
    dataChannel,
    shortRecordingMessage,
    events,
    stopSession,
    startSession, // on va déclencher manuellement
    handlePointerDown,
    handlePointerUp,
  } = useRealtimeWebRTC({
    autoStartSession: false, // Désactivé => on appelle startSession() quand on veut
    onConnectingChange: handleConnectingChange,
    onRealtimeEvent: customRealtimeHandler,
    showDebug: false,
    initialInstructions: dynamicInstructions,
    existingItems: hasLoadedConversation && !isFresh ? items : [], // si non chargé => []
    onAnalyzersReady: (local, remote) => {
      setLocalAnalyserState(local);
      setRemoteAnalyserState(remote);
    },
  });

  // (Local state) pour afficher le volume local/remote
  const [localAnalyserState, setLocalAnalyserState] = useState<AnalyserNode | null>(null);
  const [remoteAnalyserState, setRemoteAnalyserState] = useState<AnalyserNode | null>(null);

  // Pour logs dev
  const [showRealtimeLogs, setShowRealtimeLogs] = useState(false);
  // Afficher zone texte
  const [showTextInput, setShowTextInput] = useState(false);
  // Hack pointerUp
  const userHasReleased = useRef(false);

  // -------------------------------------------------------------------------
  // 5) Démarrer la session UNE fois : verrous
  // -------------------------------------------------------------------------
  const didStartRef = useRef(false);

  useEffect(() => {
    if (!isSessionActive && hasLoadedConversation && !didStartRef.current) {
      didStartRef.current = true; // On verrouille => plus de second appel
      startSession();
    }
  }, [isSessionActive, hasLoadedConversation, startSession]);

  // -------------------------------------------------------------------------
  // 6) Surveiller si user a déjà parlé (pour icebreaker)
  // -------------------------------------------------------------------------
  useEffect(() => {
    const hasAnyUserMsg = items.some((m) => m.role === 'user');
    if (hasAnyUserMsg && !hasUserSpoken) {
      setHasUserSpoken(true);
    }
  }, [items, hasUserSpoken]);

  // -------------------------------------------------------------------------
  // 7) Bloquer la fermeture si le scénario est encore en cours (moins de 10 msgs)
  // -------------------------------------------------------------------------
  const isScenarioInProgress =
    sessionMessageCount < MESSAGE_LIMITS.MESSAGES_REQUIRED_TO_COMPLETE_SCENARIO;

  useEffect(() => {
    if (!isScenarioInProgress) {
      // Rien à faire si le scénario est terminé
      return;
    }

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = ''; // requis pour Chrome
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isScenarioInProgress]);

  // -------------------------------------------------------------------------
  // 8) customRealtimeHandler => intercept => free_chat
  // -------------------------------------------------------------------------
  function customRealtimeHandler(evt: any) {
    // Passer au handler interne
    originalRealtimeEvent(evt);

    // Si c'est un message user => on masque icebreaker + suggestion
    if (evt.type === 'conversation.item.created' && evt.item?.role === 'user') {
      setShowIcebreaker(false);
      setShowSuggestion(false);
      setRevealedPhrase(null);

      // free_chat => on arrête skip
      if (selectedScenario === 'free_chat') {
        setSkipSuggestionInFreeChat(false);
      }
    }
  }

  // -------------------------------------------------------------------------
  // 9) handleAssistantSilence => suggestions
  // -------------------------------------------------------------------------
  function handleAssistantSilence() {
    console.log(
      '[handleAssistantSilence] scenario=',
      selectedScenario,
      'hasUserSpoken=',
      hasUserSpoken
    );

    if (selectedScenario !== 'free_chat') {
      setShowSuggestion(true);
      setRevealedPhrase(null);
      return;
    }
    // free_chat
    if (!hasUserSpoken) {
      setShowIcebreaker(true);
    } else {
      if (skipSuggestionInFreeChat) {
        setSkipSuggestionInFreeChat(false);
      } else {
        setShowSuggestion(true);
        setRevealedPhrase(null);
      }
    }
  }

  // -------------------------------------------------------------------------
  // 10) handleSendTextMessage => envoi message user
  // -------------------------------------------------------------------------
  async function handleSendTextMessage(text: string) {
    setShowIcebreaker(false);

    // free_chat => plus de skip
    if (selectedScenario === 'free_chat') {
      setSkipSuggestionInFreeChat(false);
    }

    // Anonyme => check
    if (checkIfLimitReached()) {
      console.warn('[ConsolePage] => limit => skip user text');
      return;
    }

    // paywall
    if (currentUser?.uid) {
      const paywallBlocked = await checkPaywallLimit(currentUser.uid);
      if (paywallBlocked) {
        setIsLimitReached(true);
        return;
      }
    }

    // dataChannel ?
    if (!dataChannel || dataChannel.readyState !== 'open') {
      console.warn('[ConsolePage] => dataChannel not open => skip user text');
      return;
    }

    // Envoie conversation.item.create + response.create
    const createEvt = {
      type: 'conversation.item.create',
      item: {
        type: 'message',
        role: 'user',
        content: [{ type: 'input_text', text }],
      },
    };
    const responseEvt = { type: 'response.create' };

    dataChannel.send(JSON.stringify(createEvt));
    dataChannel.send(JSON.stringify(responseEvt));
  }

  // -------------------------------------------------------------------------
  // 11) push‐to‐talk => pointerDown / pointerUp
  // -------------------------------------------------------------------------
  async function handleMicPointerDown() {
    userHasReleased.current = false;
    handlePointerDown();

    // anonyme ?
    if (checkIfLimitReached()) {
      if (!userHasReleased.current) handlePointerUp();
      return;
    }

    // paywall ?
    if (currentUser?.uid) {
      try {
        const paywallBlocked = await checkPaywallLimit(currentUser.uid);
        if (paywallBlocked) {
          setIsLimitReached(true);
          if (!userHasReleased.current) handlePointerUp();
        }
      } catch (err) {
        console.error('[ConsolePage] => handleMicPointerDown => error =>', err);
      }
    }
  }

  function handleMicPointerUp() {
    userHasReleased.current = true;
    handlePointerUp();
  }

  // -------------------------------------------------------------------------
  // 12) Quitter la console
  // -------------------------------------------------------------------------
  function handleExitClick() {
    stopSession();
    onExit();
  }

  // -------------------------------------------------------------------------
  // 13) Toggle logs dev
  // -------------------------------------------------------------------------
  function handleToggleLogs() {
    setShowRealtimeLogs((prev) => !prev);
  }

  // -------------------------------------------------------------------------
  // Rendu final
  // -------------------------------------------------------------------------
  const isDev =
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_NODE_ENV === 'development' ||
    process.env.REACT_APP_ENVIRONMENT === 'development';

  return (
    <div
      data-component="ConsolePage"
      className="relative w-screen h-screen flex flex-col font-sans overflow-hidden"
    >
      {/* (A) Header */}
      <ConsolePageHeader
        onExitClick={handleExitClick}
        sessionMessageCount={sessionMessageCount}
        showDev={isDev}
        onToggleLogs={handleToggleLogs}
      />

      {/* (B) Contenu principal */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <ConversationSection
          items={items}
          showIcebreaker={showIcebreaker}
          setShowIcebreaker={setShowIcebreaker}
          showSuggestion={showSuggestion}
          setShowSuggestion={setShowSuggestion}
          revealedPhrase={revealedPhrase}
          setRevealedPhrase={setRevealedPhrase}
          skipSuggestionInFreeChat={skipSuggestionInFreeChat}
          setSkipSuggestionInFreeChat={setSkipSuggestionInFreeChat}
          handleSendTextMessage={handleSendTextMessage}
          handleTranslate={handleTranslate}
        />

        <BottomBar
          isSessionActive={isSessionActive}
          isRecording={isRecording}
          dataChannel={dataChannel}
          shortRecordingMessage={shortRecordingMessage}
          localAnalyserState={localAnalyserState}
          remoteAnalyserState={remoteAnalyserState}
          onMicPointerDown={handleMicPointerDown}
          onMicPointerUp={handleMicPointerUp}
          onAssistantSilence={handleAssistantSilence}
          onToggleTextInput={() => setShowTextInput((prev) => !prev)}
          checkIfLimitReached={checkIfLimitReached}
          isLimitReached={isLimitReached}
          setIsLimitReached={setIsLimitReached}
          showTextInput={showTextInput}
          handleSendTextMessage={handleSendTextMessage}
        />
      </div>

      {/* (C) Overlay : connexion en cours */}
      {isConnecting && (
        <div className="absolute inset-0 z-50 bg-white bg-opacity-75 flex items-center justify-center">
          <div className="animate-spin rounded-full h-16 w-16 border-b-4 border-blue-500" />
        </div>
      )}

      {/* (D) Overlays : paywall, success, streak, etc. */}
      <ConsolePageOverlays
        showRegisterEmailForm={showRegisterEmailForm}
        onSignUpSuccess={handleSignUpSuccess}
        isLimitReached={isLimitReached}
        showStreakAnimation={showStreakAnimation}
        showStreakSuccessMessage={showStreakSuccessMessage}
        showSuccessMessage={showSuccessMessage}
        showScenarioSuccessMessage={showScenarioSuccessMessage}
        error={error}
      />

      {/* (E) Logs dev */}
      {isDev && showRealtimeLogs && <RealtimeLogViewer events={events} />}
    </div>
  );
}

export default ConsolePage;