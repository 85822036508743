// src/components/LanguageSelectorHeader.tsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

interface StudyLanguage {
  code: string;
  countryCode: string;
}

interface LanguageSelectorHeaderProps {
  selectedLanguage: string;
  onLanguageSelect: (languageCode: string) => void;
}

const studyLanguages: StudyLanguage[] = [
  { code: 'en', countryCode: 'us' },
  { code: 'es', countryCode: 'es' },
  { code: 'fr', countryCode: 'fr' },
  { code: 'de', countryCode: 'de' },
  { code: 'it', countryCode: 'it' },
  { code: 'pt', countryCode: 'pt' },
  { code: 'nl', countryCode: 'nl' },
  { code: 'zh', countryCode: 'cn' },
  { code: 'ar', countryCode: 'sa' },
  { code: 'hi', countryCode: 'in' },
  { code: 'ru', countryCode: 'ru' },
  { code: 'ko', countryCode: 'kr' },
  { code: 'id', countryCode: 'id' },
  { code: 'tr', countryCode: 'tr' },
  { code: 'pl', countryCode: 'pl' },
  { code: 'ja', countryCode: 'jp' },
  { code: 'ur', countryCode: 'pk' },
];

// Émojis drapeaux
const flagEmojis: { [key: string]: string } = {
  us: '🇬🇧', // pour l'anglais
  es: '🇪🇸',
  fr: '🇫🇷',
  de: '🇩🇪',
  it: '🇮🇹',
  pt: '🇵🇹',
  nl: '🇳🇱',
  cn: '🇨🇳',
  sa: '🇸🇦',
  in: '🇮🇳',
  ru: '🇷🇺',
  kr: '🇰🇷',
  id: '🇮🇩',
  tr: '🇹🇷',
  pl: '🇵🇱',
  jp: '🇯🇵',
  pk: '🇵🇰',
};

const LANGUAGE_SELECTOR_ID = 'language-selector-button';

export const LanguageSelectorHeader: React.FC<LanguageSelectorHeaderProps> = ({
  selectedLanguage,
  onLanguageSelect,
}) => {
  const { t } = useTranslation('languages');
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Tri alphabétique par label
  const sortedLanguages = [...studyLanguages]
    .map((lang) => ({
      ...lang,
      label: t(lang.code),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  // Trouve la langue sélectionnée
  const currentLang =
    sortedLanguages.find((lang) => lang.code === selectedLanguage) || sortedLanguages[0];

  // Fermer dropdown si clic en dehors
  useEffect(() => {
    const handleWindowClick = (event: MouseEvent) => {
      const target = (event.target as HTMLElement).closest('button');
      if (target && target.id === LANGUAGE_SELECTOR_ID) {
        return;
      }
      setIsOpen(false);
    };
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguageChange = (lang: typeof sortedLanguages[number]) => {
    onLanguageSelect(lang.code);
    setIsOpen(false);

    // Met à jour l’URL
    const currentPath = location.pathname;
    const segments = currentPath.split('/');

    if (segments[1] && segments[1].length === 2) {
      segments[1] = lang.code;
    } else {
      segments.splice(1, 0, lang.code);
    }

    const newPath = segments.join('/');
    navigate(newPath, { replace: true });
  };

  // Classes
  const buttonClass = `
    inline-flex items-center
    bg-transparent
    cursor-pointer
    whitespace-nowrap
    uppercase
    text-inherit
    font-semibold
    hover:text-gray-900
    focus:outline-none
    focus:ring-0
    transition-colors
  `;
  const dropdownClass = `
    absolute
    right-0
    mt-1
    w-96
    origin-top-right
    rounded-md
    bg-white
    shadow-lg
    ring-1 ring-black ring-opacity-5
    z-50
  `;

  // On ne montre pas le drapeau dans le bouton principal
  const showFlagInButton = false;

  return (
    <div className="relative inline-block text-left">
      <button
        type="button"
        id={LANGUAGE_SELECTOR_ID}
        onClick={toggleDropdown}
        className={buttonClass}
      >
        {showFlagInButton && (
          <span className="mr-2 text-[1.95rem]">
            {flagEmojis[currentLang.countryCode] || '❓'}
          </span>
        )}
        <span className="ml-1">{currentLang.label}</span>
        <svg
          className="ml-1 h-4 w-4 text-current"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0l3.292 3.292 3.293-3.292
               a1 1 0 111.414 1.414l-4 4
               a1 1 0 01-1.414 0l-4-4
               a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isOpen && (
        <div className={dropdownClass} role="menu">
          <div className="grid grid-cols-2 gap-x-4 gap-y-2 px-4 py-3">
            {sortedLanguages.map((lang) => (
              <button
                key={lang.code}
                onClick={() => handleLanguageChange(lang)}
                className={`
                  flex items-center
                  rounded-md
                  px-2 py-2
                  text-black
                  hover:bg-gray-100
                  hover:text-black
                  transition-colors
                  whitespace-nowrap
                  truncate
                  ${lang.code === currentLang.code ? 'bg-gray-100' : ''}
                `}
              >
                <span className="mr-2 text-[1.95rem]">
                  {flagEmojis[lang.countryCode] || '❓'}
                </span>
                <span className="text-xl uppercase font-medium">
                  {lang.label}
                </span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};