// src/components/StartupRouter.tsx
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserLang } from '../hooks/useUserLang';
import { getRoutingState } from '../utils/routingState';

export function StartupRouter() {
  const navigate = useNavigate();
  const userLang = useUserLang();   // ex. 'en', 'es', etc.

  useEffect(() => {
    const { onboardingStep } = getRoutingState();
    // Au lieu de forcer 'fr', on lit userLang (défaut 'en' si non défini)
    const prefix = `/${userLang}`;

    if (onboardingStep === 'landing') {
      navigate(prefix, { replace: true });
      return;
    }
    if (onboardingStep === 'scenario') {
      navigate(`${prefix}/scenario`, { replace: true });
      return;
    }
    // sinon => ex. 'heard-about', 'why-learn', etc.
    navigate(`${prefix}/${onboardingStep}`, { replace: true });
  }, [navigate, userLang]);

  return null;
}