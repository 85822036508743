// src/pages/useLandingPageLogic.ts

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getApiUrl } from '../config/environment';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
import { useUser } from '../context/UserContext';
import { useStudy } from '../context/StudyContext'; // pour mettre à jour la langue d’étude
import i18n from '../i18n';
import { buildStudyUrl } from '../utils/studyNavigation';

interface UseLandingPageLogicProps {
  onGetStarted?: () => void;
}

export interface LandingPageLogicReturn {
  loading: boolean;
  heroTitle: string;
  heroDescription: string;
  carouselItems: { name: string; code: string; flag: string }[];
  selectedLangFlag: string | null;
  methodPhrase: string | null;
  handleGetStartedClick: () => Promise<void>;
  handleLoginClick: () => void;
  handleStudyLanguageSelect: (studyLangCode: string) => void;
  isSpecificLanding: boolean;
  studyLang?: string;
  uiLang: string;
}

export function useLandingPageLogic({ onGetStarted }: UseLandingPageLogicProps): LandingPageLogicReturn {
  // (1) Lecture URL params
  const { studyLang, verbSlug } = useParams<{ studyLang?: string; verbSlug?: string }>();
  const isSpecificLanding = Boolean(studyLang && verbSlug);

  // (2) i18n
  const { t } = useTranslation('landingPage');

  // (3) Auth & User
  const { currentUser, loading } = useAuth();
  const { userData, refreshUserData } = useUser();

  // (4) Contexte “study”
  const { setStudyLanguage } = useStudy();

  // (5) Navigation
  const navigate = useNavigate();

  // (6) Langue d’interface
  const { interfaceLanguage, setInterfaceLanguage, detectedBrowserLanguage } = useLanguage();
  const uiLang = interfaceLanguage || detectedBrowserLanguage || 'en';

  useEffect(() => {
    if (!interfaceLanguage) {
      setInterfaceLanguage(detectedBrowserLanguage);
    }
  }, [interfaceLanguage, detectedBrowserLanguage, setInterfaceLanguage]);

  // (7) Landing spécifique => on met à jour la langue d’étude
  useEffect(() => {
    if (isSpecificLanding && studyLang) {
      setStudyLanguage(studyLang);
    }
  }, [isSpecificLanding, studyLang, setStudyLanguage]);

  // (8) Handler “Get Started” => On NE redirige plus vers /login
  async function handleGetStartedClick() {
    // Si userData a une interfaceLanguage différente de uiLang,
    // on met éventuellement à jour en BDD. Sinon, on s’en fiche.
    if (userData?.interfaceLanguage !== uiLang && currentUser?.uid) {
      await updateInterfaceLanguageInDB(currentUser.uid, uiLang);
      await refreshUserData();
    }

    // On va directement sur la page de sélection
    navigate('/studyLanguageSelection');

    // On appelle la callback si besoin
    onGetStarted?.();
  }

  // (9) Handler “Login” => S’il existe un bouton “I already have an account”
  function handleLoginClick() {
    // On peut laisser cette route exister,
    // ou on la supprime si on ne veut plus de login traditionnel
    navigate('/login');
  }

  // (10) Handler “Sélection langue d’étude” via carousel
  function handleStudyLanguageSelect(studyLangCode: string) {
    setStudyLanguage(studyLangCode);
    const finalUrl = buildStudyUrl(studyLangCode, uiLang);
    navigate(finalUrl);
  }

  // MàJ de la langue d’interface en BDD
  async function updateInterfaceLanguageInDB(uid: string, lang: string) {
    const apiUrl = getApiUrl();
    await fetch(`${apiUrl}/api/user/registerOrUpdateUser`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        uid,
        interfaceLanguage: lang,
        defaultBrowserLanguage: detectedBrowserLanguage,
      }),
    });
  }

  // (11) Construction du carousel
  const rawLangs = [
    { code: 'en', flag: '🇬🇧' },
    { code: 'es', flag: '🇪🇸' },
    { code: 'fr', flag: '🇫🇷' },
    { code: 'de', flag: '🇩🇪' },
    { code: 'it', flag: '🇮🇹' },
    { code: 'pt', flag: '🇵🇹' },
    { code: 'nl', flag: '🇳🇱' },
    { code: 'zh', flag: '🇨🇳' },
    { code: 'ar', flag: '🇸🇦' },
    { code: 'hi', flag: '🇮🇳' },
    { code: 'ru', flag: '🇷🇺' },
    { code: 'ko', flag: '🇰🇷' },
    { code: 'id', flag: '🇮🇩' },
    { code: 'tr', flag: '🇹🇷' },
    { code: 'pl', flag: '🇵🇱' },
    { code: 'ja', flag: '🇯🇵' },
    { code: 'ur', flag: '🇵🇰' },
  ];

  function buildCarouselItems() {
    const fixedT = i18n.getFixedT(uiLang, ['languages']);
    const fullList = rawLangs.map((item) => ({
      ...item,
      name: fixedT(item.code, { defaultValue: item.code.toUpperCase() }),
    }));
    if (isSpecificLanding && studyLang) {
      return fullList.filter((lang) => lang.code === studyLang);
    }
    return fullList;
  }

  const carouselItems = buildCarouselItems();

  // (12) Titre hero
  let heroTitle: string;
  if (!isSpecificLanding) {
    heroTitle = t('titleGeneric');
  } else {
    const fixedT = i18n.getFixedT(uiLang, ['languages']);
    const studyLangName = fixedT(`withArticle.${studyLang}`, { defaultValue: studyLang || '' });
    heroTitle = t('heroTitleWithStudyLang', { studyLangName });
  }
  const heroDescription = t('description', { defaultValue: '' });

  // (13) Bloc “flag + phrase” si landing spécifique
  let selectedLangFlag: string | null = null;
  let methodPhrase: string | null = null;
  if (isSpecificLanding && studyLang) {
    const found = rawLangs.find((l) => l.code === studyLang);
    selectedLangFlag = found?.flag ?? '❓';

    const fixedT = i18n.getFixedT(uiLang, ['languages']);
    const studyLangName = fixedT(`withArticle.${studyLang}`, { defaultValue: studyLang });
    methodPhrase = t('titleWithStudyLang', { studyLangName });
  }

  return {
    loading, // on l’expose si on veut un spinner, ou on l’ignore
    heroTitle,
    heroDescription,
    carouselItems,
    selectedLangFlag,
    methodPhrase,
    handleGetStartedClick,
    handleLoginClick,
    handleStudyLanguageSelect,
    isSpecificLanding,
    studyLang,
    uiLang,
  };
}