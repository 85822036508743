// src/pages/StartChoicePage.tsx

import React, { useState } from 'react';
import { OnboardingLayout } from '../components/OnboardingLayout';
import { useTranslation } from 'react-i18next';
import { VoxButton } from '../components/VoxButton';
import { VoxCard } from '../components/VoxCard';

interface StartChoicePageProps {
  onNext?: (choice: string) => void;
}

type ChoiceOption = {
  value: string;
  label: string;
  description: string;
  image: string;
};

const StartChoicePage: React.FC<StartChoicePageProps> = ({ onNext }) => {
  const { t } = useTranslation('start-choice');
  const [selectedChoice, setSelectedChoice] = useState<string>('');

  const choiceOptions: ChoiceOption[] = [
    {
      value: 'basics',
      label: t('options.basics.label', 'Commencer par les bases'),
      description: t(
        'options.basics.description',
        'Le mode “débutant” avec des conversations très simples.'
      ),
      image: '/start-choice/beginner-icon.png',
    },
    {
      value: 'levelTest',
      label: t('options.levelTest.label', 'Déterminer mon niveau'),
      description: t(
        'options.levelTest.description',
        'Laisse l’IA te poser quelques questions pour évaluer ton niveau.'
      ),
      image: '/start-choice/evaluation-icon.png',
    },
  ];

  // Mise à jour de la sélection
  const handleOptionClick = (value: string) => {
    setSelectedChoice(value);
  };

  // Validation : renvoie la valeur choisie
  const handleNext = () => {
    onNext?.(selectedChoice);
  };

  const isNextDisabled = !selectedChoice;

  // Footer : bouton "Continuer"
  const footer = (
    <div className="flex justify-center">
      <VoxButton
        variant="primary"
        width="auto"
        disabled={isNextDisabled}
        onClick={handleNext}
      >
        {t('next', 'Continuer')}
      </VoxButton>
    </div>
  );

  return (
    <OnboardingLayout
      currentStep="startChoice"
      title={t('title', 'Par où veux-tu commencer ?')}
      footer={footer}
    >
      <div className="w-full max-w-screen-md md:max-w-screen-xl mx-auto px-4 text-xl md:text-2xl mt-4 md:mt-8">
        {/* Grille à 1 colonne pour empiler les options verticalement */}
        <div className="grid grid-cols-1 gap-y-6 items-stretch w-full">
          {choiceOptions.map((opt) => {
            const isSelected = opt.value === selectedChoice;
            return (
              <VoxCard
                key={opt.value}
                hoverable
                selected={isSelected}
                onClick={() => handleOptionClick(opt.value)}
                // On retire le fond gris (gradient), mais on conserve le p-4 et les effets hover
                className="mx-auto flex p-4 hover:scale-105 hover:brightness-105 items-start"
              >
                <img
                  src={opt.image}
                  alt={opt.value}
                  className="h-16 w-16 md:h-20 md:w-20 object-contain mr-4 flex-shrink-0 self-start"
                />
                <div className="flex-1 flex flex-col text-left">
                  <span className="font-bold text-[#243238] leading-tight">
                    {opt.label}
                  </span>
                  <span className="text-gray-600 mt-1 leading-snug">
                    {opt.description}
                  </span>
                </div>
              </VoxCard>
            );
          })}
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default StartChoicePage;