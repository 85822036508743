// src/pages/EncouragementPage.tsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingLayout } from '../components/OnboardingLayout';
import { VoxButton } from '../components/VoxButton';

interface EncouragementPageProps {
  onNext?: () => void;
}

const EncouragementPage: React.FC<EncouragementPageProps> = ({ onNext }) => {
  const { t } = useTranslation('encouragement');

  // Liste des points d’encouragement
  const bulletPoints = [
    {
      image: '/encouragement/pronunciation-icon.png',
      text: t('bullet1'),
      subtext: t('bullet1Sub'),
    },
    {
      image: '/encouragement/speaking-with-confidence-icon.png',
      text: t('bullet2'),
      subtext: t('bullet2Sub'),
    },
    {
      image: '/encouragement/vocabulary-icon.png',
      text: t('bullet3'),
      subtext: t('bullet3Sub'),
    },
  ];

  // Footer => bouton "Next"
  const footer = (
    <div className="flex justify-center">
      <VoxButton variant="primary" width="auto" onClick={() => onNext?.()}>
        {t('next', 'Next')}
      </VoxButton>
    </div>
  );

  return (
    <OnboardingLayout currentStep="encouragement" title={t('title')} footer={footer}>
      {/*
        Sur mobile : text-xl
        Sur desktop : md:text-2xl
      */}
      <div
        className="
          max-w-screen-md
          mx-auto
          text-xl
          md:text-2xl
          mt-4
          md:mt-8
        "
      >
        {/* Carte englobante => même layout, juste la couleur du contour et des barres */}
        <div
          className="
            border-2
            border-blue-400  /* <-- Couleur bleue */
            rounded-lg
            bg-white
            p-6
            md:p-8
            text-left
            shadow-sm
          "
        >
          {bulletPoints.map((point, index) => (
            <React.Fragment key={index}>
              <div className="flex items-start py-6">
                <img
                  src={point.image}
                  alt="bullet icon"
                  className="
                    w-16 h-16
                    md:w-20 md:h-20
                    object-contain
                    mr-4
                    flex-shrink-0
                  "
                />
                <div className="flex flex-col">
                  <span className="font-bold text-[#243238] leading-snug">
                    {point.text}
                  </span>
                  <span className="mt-1 text-gray-600 leading-snug">
                    {point.subtext}
                  </span>
                </div>
              </div>

              {/* Séparateur (hr) entre bulletPoints => même couleur bleue */}
              {index < bulletPoints.length - 1 && (
                <hr className="my-4 mx-auto w-4/5 border-t-2 border-blue-400" />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default EncouragementPage;