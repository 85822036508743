// ---------------------------------------------------------------------
// src/pages/ConversationScenarioSelection/ScenarioGroups.tsx
// ---------------------------------------------------------------------
import React from 'react';
import { TFunction } from 'i18next';

function getScenarioIcon(scenarioId: string): string | null {
  switch (scenarioId) {
    case 'free_chat':
      return '/scenarioselection/free-chat-icon.png';
    case 'cafe':
      return '/scenarioselection/coffee-icon.png';
    case 'airport':
      return '/scenarioselection/airport-icon.png';
    case 'restaurant':
      return '/scenarioselection/restaurant-icon.png';
    case 'hotel':
      return '/scenarioselection/hotel-icon.png';
    default:
      return null; // Les autres scénarios n'ont pas d'icône => on affichera l'emoji
  }
}

type ScenarioGroupsProps = {
  scenarioGroups: any[][];
  completedScenarios: string[];
  unlockedGroupIndex: number;
  highlightedScenario: string | null;
  onScenarioClick: (scenarioId: string) => void;
  tScenarios: TFunction; // pour traductions
};

export function ScenarioGroups({
  scenarioGroups,
  completedScenarios,
  unlockedGroupIndex,
  highlightedScenario,
  onScenarioClick,
  tScenarios,
}: ScenarioGroupsProps) {
  return (
    <div className="scenarios-list">
      {scenarioGroups.map((group, groupIndex) => {
        const isGroupUnlocked = groupIndex <= unlockedGroupIndex;

        return (
          <div key={`group-${groupIndex}`} className="scenario-group">
            {/* (A) Niveau */}
            <div className="group-divider">
              <span className="group-number">
                {tScenarios('conversationScenarioSelection.levelLabel', {
                  count: groupIndex + 1,
                })}
              </span>
            </div>

            {/* (B) Verrou si pas débloqué */}
            {!isGroupUnlocked && (
              <div className="unlock-message">
                {tScenarios('conversationScenarioSelection.unlockMessage', {
                  level: groupIndex + 1,
                })}
              </div>
            )}

            {/* (C) Scénarios */}
            <div className="group-scenarios">
              {group.map((scenarioObj: any) => {
                const isCompleted = completedScenarios.includes(scenarioObj.id);
                const isLocked = !isGroupUnlocked;
                const isFreeChat = scenarioObj.id === 'free_chat';

                // Récupère le chemin d'icône si disponible
                const iconPath = getScenarioIcon(scenarioObj.id);

                return (
                  <div
                    key={scenarioObj.id}
                    className={`
                      scenario-card
                      ${highlightedScenario === scenarioObj.id ? 'selected' : ''}
                      ${isCompleted ? 'completed' : ''}
                      ${isLocked ? 'locked' : ''}
                      ${isFreeChat ? 'free-chat-special' : ''}
                    `}
                    onClick={() => !isLocked && onScenarioClick(scenarioObj.id)}
                  >
                    {isLocked && <span className="lock-icon">🔒</span>}

                    <h3
                      className="
                        m-0
                        text-[1.2rem]
                        sm:text-[1.25rem]
                        md:text-[1.375rem]
                        lg:text-[1.65rem]
                        leading-snug
                        text-gray-800
                        font-bold
                      "
                    >
                      {/* 
                        (C.1) L'icône ou l'emoji,
                        placé dans un conteneur .scenario-emoji
                        qui fait 5rem × 5rem, centré en flex.
                        Ici, on ajoute une marge verticale (marginBottom: '1.2rem')
                        pour augmenter légèrement l'espacement avec le titre.
                      */}
                      <span
                        className="scenario-emoji"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '5rem',
                          height: '5rem',
                          margin: '0 auto',
                          marginBottom: '1.2rem',
                        }}
                      >
                        {iconPath ? (
                          <img
                            src={iconPath}
                            alt={scenarioObj.id}
                            style={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain',
                            }}
                          />
                        ) : (
                          // Sinon, on affiche l'emoji
                          <span style={{ fontSize: '3rem' }}>{scenarioObj.emoji}</span>
                        )}
                      </span>

                      {/* (C.2) Titre i18n */}
                      {tScenarios(scenarioObj.titleKey)}

                      {/* (C.3) Icône '✅' si terminé */}
                      {isCompleted && (
                        <span className="completed-icon">✅</span>
                      )}
                    </h3>

                    {/* (C.4) Description i18n */}
                    <p
                      className="
                        mt-2
                        text-[1.2rem]
                        sm:text-[1.25rem]
                        md:text-[1.375rem]
                        lg:text-[1.65rem]
                        leading-snug
                        text-gray-800
                      "
                    >
                      {tScenarios(scenarioObj.descriptionKey)}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ScenarioGroups;