// src/pages/ConsolePage/ConversationList.tsx

import React, { useState, useCallback, useEffect } from 'react';
import FeedbackSidePanel from './FeedbackSidePanel';
import { ConversationItemType } from '../../types';

type ConversationListProps = {
  /** Array of conversation messages (either user or assistant). */
  items: ConversationItemType[];

  /** Function to request the translation of an assistant message. */
  onTranslate: (id: string) => void;

  /**
   * Utility to avoid line breaks before punctuation (e.g. turning " ..." into " ...").
   */
  preventLineBreaks: (txt: string | undefined) => string;
};

/**
 * ConversationList:
 *   - Renders the main list of user/assistant messages.
 *   - Shows a spinner if `isWaitingCorrection === true` (user messages only).
 *   - Displays a red/green badge for user messages after correction (based on `feedbackColor`).
 *   - Lets you open a side panel (FeedbackSidePanel) for more details about the correction.
 *   - Affiche un effet d’impulsion + un hint tooltip pour la croix rouge la première fois.
 */
export function ConversationList({
  items,
  onTranslate,
  preventLineBreaks,
}: ConversationListProps) {
  // Track which conversation item is selected for the right-side correction panel
  const [selectedItemForSidePanel, setSelectedItemForSidePanel] =
    useState<ConversationItemType | null>(null);

  /**
   * Toggles the FeedbackSidePanel open/close.
   * If clicking again on the same item, it closes the panel.
   */
  const handleOpenSidePanel = useCallback(
    (item: ConversationItemType) => {
      if (selectedItemForSidePanel?.id === item.id) {
        setSelectedItemForSidePanel(null);
      } else {
        setSelectedItemForSidePanel(item);
      }
    },
    [selectedItemForSidePanel]
  );

  // --------------------------------------------------------------------------
  // (A) Gérer un petit "hint" pour informer l’utilisateur qu’il peut cliquer
  //     sur la croix rouge (une seule fois).
  // --------------------------------------------------------------------------
  const [showRedCrossHint, setShowRedCrossHint] = useState(false);

  useEffect(() => {
    const hasClickedRedCross = localStorage.getItem('hasClickedRedCross');
    if (hasClickedRedCross === 'true') {
      setShowRedCrossHint(false);
      return;
    }
    const hasRedCross = items.some(
      (item) => item.correction?.feedbackColor === 'red'
    );
    if (hasRedCross) {
      setShowRedCrossHint(true);
    }
  }, [items]);

  // Au clic sur la croix rouge => on masquera le hint définitivement
  const handleRedCrossClick = useCallback(
    (item: ConversationItemType) => {
      // On ouvre le panel feedback
      handleOpenSidePanel(item);
      // On stocke le fait que l’utilisateur a déjà cliqué
      localStorage.setItem('hasClickedRedCross', 'true');
      setShowRedCrossHint(false);
    },
    [handleOpenSidePanel]
  );

  return (
    <div className="relative">
      {/* (1) Main list of messages */}
      <div className="content-block-body" data-conversation-content>
        {items.map((item) => {
          const isAssistant = item.role === 'assistant';
          // Assistant on the left, user on the right
          const wrapperClasses = isAssistant ? 'justify-start' : 'justify-end';
          // Visual style for the “bubble”
          const bubbleClasses = isAssistant
            ? 'bg-white border border-[#333] shadow-sm'
            : 'bg-blue-50 border border-blue-500 shadow-sm';

          // Nous ne montrons le spinner ou le badge que pour les messages user
          let pastilleContent: React.ReactNode = null;
          if (!isAssistant) {
            // 1) Si correction en cours => spinner
            if (item.isWaitingCorrection) {
              pastilleContent = (
                <div
                  className="w-10 h-10 mr-3 flex items-center justify-center cursor-default"
                  title="Correction en cours..."
                >
                  <div className="w-8 h-8 rounded-full border-4 border-blue-400 border-l-transparent animate-spin" />
                </div>
              );
            }
            // 2) Si correction terminée => badge vert ou rouge
            else if (item.correction) {
              const { feedbackColor } = item.correction;
              const isGreen = feedbackColor === 'green';
              const colorClass = isGreen ? 'bg-green-600' : 'bg-red-600';
              const iconInside = isGreen ? '✓' : '✕';
              // Ajout d'un style cliquable + animation si c'est la croix rouge
              const redCrossExtraClasses = !isGreen
                ? `cursor-pointer ${showRedCrossHint ? 'animate-pulse-slight' : ''}`
                : '';
              pastilleContent = (
                <div
                  className={`
                    ${colorClass}
                    w-10 h-10
                    mr-3
                    flex items-center justify-center
                    rounded-full
                    text-white text-xl font-bold
                    ${redCrossExtraClasses}
                  `}
                  title={isGreen
                    ? 'Correct ! Cliquez pour voir le détail.'
                    : 'Inexact. Cliquez pour voir la correction.'}
                  onClick={
                    isGreen
                      ? () => handleOpenSidePanel(item)
                      : () => handleRedCrossClick(item)
                  }
                >
                  {iconInside}
                </div>
              );
            }
          }

          return (
            <div
              key={item.id}
              className={`flex items-center ${wrapperClasses} mb-4 mx-4 lg:container lg:mx-auto lg:px-52 xl:px-96`}
            >
              {/* Pastille (spinner ou badge) pour l'utilisateur */}
              {pastilleContent}

              {/* La bulle du message */}
              <div
                className={`
                  relative p-4 rounded-2xl
                  max-w-[80%] md:max-w-[85%] lg:max-w-[90%]
                  ${bubbleClasses}
                `}
              >
                <div className="text-gray-800 leading-snug text-[1.2rem] sm:text-[1.25rem] md:text-[1.375rem] lg:text-[1.65rem] pr-4">
                  {isAssistant ? (
                    /* Message assistant (avec traduction optionnelle) */
                    <div>
                      {preventLineBreaks(item.text || '(assistant...)')}
                      {item.translation && (
                        <div className="mt-2 italic text-gray-600 text-[1.2rem] sm:text-[1.25rem] md:text-[1.375rem] lg:text-[1.65rem] leading-snug">
                          {item.translation}
                        </div>
                      )}
                    </div>
                  ) : (
                    /* Message utilisateur */
                    <div>{preventLineBreaks(item.text || '(user...)')}</div>
                  )}
                </div>

                {/* Bouton "Traduire" => uniquement pour les messages assistant */}
                {isAssistant && (
                  <button
                    onClick={() => onTranslate(item.id)}
                    className="absolute bottom-2 right-2 opacity-50 hover:opacity-100 transition-opacity"
                    aria-label="Traduire ce message"
                  >
                    <img
                      src="/translation-button.webp"
                      alt="Translate"
                      // ICI => icône agrandie
                      className="w-8 h-8" 
                    />
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {/* (2) Panneau latéral de correction => visible si un message user est sélectionné */}
      {selectedItemForSidePanel?.correction && (
        <FeedbackSidePanel
          item={selectedItemForSidePanel}
          onClose={() => setSelectedItemForSidePanel(null)}
        />
      )}
    </div>
  );
}

export default ConversationList;