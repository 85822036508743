// src/index.tsx

import React from 'react';
import ReactDOM from 'react-dom/client';

// Sentry
import * as Sentry from '@sentry/react';

// Import du CSS Tailwind et nos styles
import './index.css';        // <-- IMPORTANT : Charger Tailwind au tout début
import './i18n';             // i18n config

import App from './App';
import reportWebVitals from './reportWebVitals';

import { AuthProvider } from './context/AuthContext';
import { LanguageProvider } from './context/LanguageContext';
import { StudyProvider } from './context/StudyContext';
import { TotalMessageCountProvider } from './context/TotalMessageCountContext';
import { UserProvider } from './context/UserContext';

// CSS pour les drapeaux
import 'flag-icons/css/flag-icons.min.css';

// Initialisation Sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  environment: process.env.NODE_ENV,
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 0,
  replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 0,
  replaysOnErrorSampleRate: 1.0,
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/voxanet\.herokuapp\.com/,
    /^https:\/\/voxanet-staging\.herokuapp\.com/,
  ],
  beforeSend(event, hint) {
    if (process.env.NODE_ENV === 'development') {
      if (event.level === 'error') {
        console.error('[Sentry Error]:', {
          message: event.message,
          error: hint?.originalException,
          tags: event.tags,
        });
      }
      return null; // N'envoie pas à Sentry en dev
    }
    return {
      ...event,
      tags: {
        ...event.tags,
        locale: navigator.language,
        viewport: `${window.innerWidth}x${window.innerHeight}`,
      },
    };
  },
  maxBreadcrumbs: process.env.NODE_ENV === 'production' ? 50 : 10,
  debug: false,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Sentry.ErrorBoundary
    fallback={
      <div className="flex items-center justify-center h-full bg-transparent">
        <div className="text-center p-4">
          <h2 className="text-xl font-bold mb-2">Une erreur est survenue</h2>
          <p className="text-gray-600 mb-4">
            L'application rencontre un problème. Veuillez rafraîchir la page.
          </p>
          <button
            onClick={() => window.location.reload()}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
          >
            Rafraîchir la page
          </button>
        </div>
      </div>
    }
    onError={(error) => {
      if (process.env.NODE_ENV === 'development') {
        console.error('Error caught by Sentry ErrorBoundary:', error);
      }
    }}
  >
    <AuthProvider>
      <UserProvider>
        <LanguageProvider>
          <StudyProvider>
            <TotalMessageCountProvider>
              <App />
            </TotalMessageCountProvider>
          </StudyProvider>
        </LanguageProvider>
      </UserProvider>
    </AuthProvider>
  </Sentry.ErrorBoundary>
);

reportWebVitals(({ name, value, id }) => {
  Sentry.addBreadcrumb({
    category: 'performance',
    message: `${name}: ${value}`,
    level: 'info',
    data: { id },
  });
});