// src/pages/Marketing/TeamPage.tsx
import React from 'react';
import { Layout } from '../../components/Layout';
import { Header } from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';

/**
 * Page "Team" — presents the people behind your project.
 * Accessible via a route like "/team" or "/:lang/team" (depending on your routing).
 */
const TeamPage: React.FC = () => {
  const navigate = useNavigate();
  const { interfaceLanguage } = useLanguage();

  // Logo click => back to homepage in the current interface language
  const handleLogoClick = () => {
    navigate(`/${interfaceLanguage || 'en'}`);
  };

  return (
    <Layout noMaxWidth={false}>
      {/* HEADER commun */}
      <Header isAbsolute={false} onLogoClick={handleLogoClick} />

      {/* CONTENU PRINCIPAL */}
      <div
        className="py-10 text-gray-700 leading-relaxed
                   text-[1.2rem] sm:text-[1.25rem] md:text-[1.375rem] lg:text-[1.65rem]"
      >
        {/* SECTION HERO / INTRO */}
        <section className="px-4 mt-6 mb-16 text-center">
          <h1 className="text-3xl md:text-4xl font-bold mb-6">
            Meet Our Team
          </h1>
          <p className="max-w-4xl mx-auto mb-8">
            We’re a diverse group of innovators, educators, and multilingual enthusiasts, 
            all working together to bring you the best language-learning experience possible. 
            From product design to AI research, we unite our skills to help learners around the world.
          </p>
          <div
            role="img"
            aria-label="Hero image representing the team spirit"
            className="w-full h-64 sm:h-72 md:h-80 lg:h-96 bg-gray-200 border-dashed border-gray-400 border-2 flex items-center justify-center text-gray-600"
          >
            [Hero image: A creative illustration of the team]
          </div>
        </section>

        {/* SECTION : LES MEMBRES DE L'ÉQUIPE */}
        <section className="px-4 my-12">
          <h2 className="text-2xl md:text-3xl font-bold text-center mb-8">
            Our Core Team
          </h2>
          <div className="max-w-screen-xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Team card #1 */}
            <div className="flex flex-col items-center bg-white p-6 shadow-md rounded-lg">
              <div
                role="img"
                aria-label="Portrait of Team Member"
                className="w-32 h-32 rounded-full border-2 border-dashed border-gray-400 mb-4 flex items-center justify-center text-gray-500"
              >
                [Photo]
              </div>
              <h3 className="text-xl font-semibold mb-1">John Smith</h3>
              <p className="text-blue-600 font-medium mb-2">Founder &amp; CEO</p>
              <p className="text-center text-[1rem] text-gray-600">
                With a background in AI and education, John leads the vision 
                and strategy for our platform, ensuring we continually innovate 
                to empower language learners.
              </p>
            </div>

            {/* Team card #2 */}
            <div className="flex flex-col items-center bg-white p-6 shadow-md rounded-lg">
              <div
                role="img"
                aria-label="Portrait of Team Member"
                className="w-32 h-32 rounded-full border-2 border-dashed border-gray-400 mb-4 flex items-center justify-center text-gray-500"
              >
                [Photo]
              </div>
              <h3 className="text-xl font-semibold mb-1">Mary Johnson</h3>
              <p className="text-blue-600 font-medium mb-2">Chief Product Officer</p>
              <p className="text-center text-[1rem] text-gray-600">
                Mary focuses on creating intuitive, delightful user experiences 
                that help learners stay motivated. She brings years of product design 
                experience in edtech to the team.
              </p>
            </div>

            {/* Team card #3 */}
            <div className="flex flex-col items-center bg-white p-6 shadow-md rounded-lg">
              <div
                role="img"
                aria-label="Portrait of Team Member"
                className="w-32 h-32 rounded-full border-2 border-dashed border-gray-400 mb-4 flex items-center justify-center text-gray-500"
              >
                [Photo]
              </div>
              <h3 className="text-xl font-semibold mb-1">Alex Brown</h3>
              <p className="text-blue-600 font-medium mb-2">Head of AI Research</p>
              <p className="text-center text-[1rem] text-gray-600">
                Alex leads our machine learning and language processing initiatives, 
                ensuring our conversational AI remains cutting-edge and effective 
                for learners of all levels.
              </p>
            </div>

            {/* Team card #4 */}
            <div className="flex flex-col items-center bg-white p-6 shadow-md rounded-lg">
              <div
                role="img"
                aria-label="Portrait of Team Member"
                className="w-32 h-32 rounded-full border-2 border-dashed border-gray-400 mb-4 flex items-center justify-center text-gray-500"
              >
                [Photo]
              </div>
              <h3 className="text-xl font-semibold mb-1">Sophia Chen</h3>
              <p className="text-blue-600 font-medium mb-2">Linguistics Expert</p>
              <p className="text-center text-[1rem] text-gray-600">
                Sophia ensures our approach to language education is grounded 
                in real linguistic principles, bridging the gap between AI 
                capabilities and human language nuances.
              </p>
            </div>

            {/* Team card #5 */}
            <div className="flex flex-col items-center bg-white p-6 shadow-md rounded-lg">
              <div
                role="img"
                aria-label="Portrait of Team Member"
                className="w-32 h-32 rounded-full border-2 border-dashed border-gray-400 mb-4 flex items-center justify-center text-gray-500"
              >
                [Photo]
              </div>
              <h3 className="text-xl font-semibold mb-1">Carlos Ramirez</h3>
              <p className="text-blue-600 font-medium mb-2">Lead Frontend Engineer</p>
              <p className="text-center text-[1rem] text-gray-600">
                Carlos architects our user interface to ensure speed, 
                responsiveness, and accessibility across all devices, 
                helping millions of users connect daily.
              </p>
            </div>

            {/* Team card #6 */}
            <div className="flex flex-col items-center bg-white p-6 shadow-md rounded-lg">
              <div
                role="img"
                aria-label="Portrait of Team Member"
                className="w-32 h-32 rounded-full border-2 border-dashed border-gray-400 mb-4 flex items-center justify-center text-gray-500"
              >
                [Photo]
              </div>
              <h3 className="text-xl font-semibold mb-1">Diana Müller</h3>
              <p className="text-blue-600 font-medium mb-2">Marketing &amp; Outreach</p>
              <p className="text-center text-[1rem] text-gray-600">
                Diana connects with language lovers worldwide, 
                leading community initiatives and strategic partnerships 
                that expand our global reach.
              </p>
            </div>
          </div>
        </section>

        {/* CTA FINALE */}
        <section
          className="relative w-screen left-1/2 ml-[-50vw]
                     right-1/2 mr-[-50vw] border-t border-gray-300 py-24
                     bg-center bg-cover bg-no-repeat mt-8"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/background-for-all-website-optimized.webp)`,
          }}
        >
          <div className="max-w-screen-lg mx-auto px-4 text-center text-[1.65rem] leading-snug">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              Want to Join Our Team?
            </h2>
            <p className="mb-8 max-w-3xl mx-auto">
              We’re always looking for motivated people who share our passion 
              for language education, AI innovation, and creative problem-solving.
            </p>
            <button
              onClick={() => navigate(`/${interfaceLanguage || 'en'}/careers`)}
              className="w-full max-w-[24rem] whitespace-nowrap px-8 py-4 uppercase text-white font-semibold
                         text-[1.3rem] md:text-[1.4rem] rounded-lg shadow-lg transition-transform transform
                         hover:scale-105 focus:outline-none focus:ring-4 focus:ring-blue-300
                         bg-[#3f7afd] hover:bg-[#2f69df]"
            >
              Explore Careers
            </button>
          </div>
        </section>
      </div>
    </Layout>
  );
};

// IMPORTANT : Export par défaut pour que "import TeamPage from './TeamPage';" fonctionne
export default TeamPage;