// src/components/SeoAlternateLinks.tsx

import React from 'react';
import { Helmet } from 'react-helmet';

const ALL_LANGUAGES = [
  'en','fr','es','de','it','pt','nl','zh','ar','hi',
  'ru','ko','id','tr','pl','ja','ur'
];

interface SeoAlternateLinksProps {
  baseUrl?: string;
  currentLang?: string;
}

export const SeoAlternateLinks: React.FC<SeoAlternateLinksProps> = ({
  baseUrl = 'https://www.voxanet.com',
  currentLang = 'en',
}) => {
  return (
    <Helmet>
      {ALL_LANGUAGES.map((lang) => (
        <link
          key={lang}
          rel="alternate"
          hrefLang={lang}
          href={`${baseUrl}/${lang}`}
        />
      ))}
      <link
        rel="alternate"
        hrefLang="x-default"
        href={baseUrl}
      />
      {/* Canonical */}
      <link
        rel="canonical"
        href={`${baseUrl}/${currentLang}`}
      />
    </Helmet>
  );
};