// src/components/OnboardingProgressBar.tsx

import React from 'react';
import { ONBOARDING_STEPS } from '../constants/onboardingSteps';
import { WorkflowStep } from '../types/WorkflowStep';

interface OnboardingProgressBarProps {
  currentStep: WorkflowStep;
}

export function OnboardingProgressBar({ currentStep }: OnboardingProgressBarProps) {
  const currentIndex = ONBOARDING_STEPS.indexOf(currentStep);
  if (currentIndex === -1) return null;

  const totalSteps = ONBOARDING_STEPS.length;
  const progressPercentage = Math.round(((currentIndex + 1) / totalSteps) * 100);

  return (
    <div className="mx-auto max-w-3xl px-4 h-[1.3rem]">
      {/* Fond */}
      <div className="w-full h-full bg-gray-200 rounded-full overflow-hidden shadow-sm">
        {/* Barre de progression */}
        <div
          className="
            h-full
            bg-gradient-to-r
            from-blue-400
            to-blue-600
            shadow-md
            transition-all
            duration-500
            ease-out
          "
          style={{ width: `${progressPercentage}%` }}
        />
      </div>
    </div>
  );
}