// src/hooks/useWorkflowPreloading.ts

import { useEffect, useMemo } from 'react';
import { WORKFLOW_IMAGE_MAP } from './workflowImagesMap';
import { ORDERED_STEPS } from './workflowStepsOrder';
import { WorkflowStep } from '../types/WorkflowStep';
import { usePreloadImages } from './usePreloadImages';

/**
 * Détermine le step suivant dans la liste ORDERED_STEPS
 */
function getNextStep(currentStep: WorkflowStep): WorkflowStep | null {
  const idx = ORDERED_STEPS.indexOf(currentStep);
  if (idx === -1) return null;
  const nextIdx = idx + 1;
  if (nextIdx < ORDERED_STEPS.length) {
    return ORDERED_STEPS[nextIdx];
  }
  return null;
}

/**
 * Hook qui précharge les images de la "prochaine" étape
 * en se basant sur currentStep.
 */
export function useWorkflowPreloading(currentStep: WorkflowStep) {
  const nextStep = useMemo(() => getNextStep(currentStep), [currentStep]);
  const nextStepImages = nextStep ? WORKFLOW_IMAGE_MAP[nextStep] ?? [] : [];

  // On utilise notre hook generique pour charger la liste d'images du step suivant
  const preloadState = usePreloadImages(nextStepImages);

  useEffect(() => {
    if (preloadState.allLoaded && nextStep) {
      console.log(
        `[useWorkflowPreloading] Toutes les images de l'étape "${nextStep}" sont préchargées.`
      );
    }
  }, [preloadState.allLoaded, nextStep]);

  // On renvoie ce qu’on veut (ex. pour un éventuel debug)
  return preloadState;
}