// src/pages/LandingTestimonialsSection.tsx

import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Section "testimonials" avec 3 témoignages de users satisfaits.
 */
export const LandingTestimonialsSection: React.FC = () => {
  const { t } = useTranslation('landingPage');

  // Récupère le tableau "testimonials" (3 items minimum)
  const testimonials = t('testimonials', { returnObjects: true }) as Array<{
    name: string;
    age: string;
    city: string;
    country: string;
    quote: string;
    photo?: string;
  }>;

  if (!Array.isArray(testimonials) || testimonials.length < 3) {
    return null; // Fallback si moins de 3 témoignages
  }

  return (
    <section
      className={`
        relative
        w-screen left-1/2 ml-[-50vw]
        right-1/2 mr-[-50vw]
        border-t border-gray-300
        py-24
        bg-white
      `}
    >
      {/*
        Container avec text-3xl (~1.65rem) + leading-snug,
        pour rester cohérent avec le style global.
      */}
      <div className="max-w-screen-lg mx-auto px-4 text-3xl leading-snug">
        {/*
          Titre aligné sur LandingFeatureSection :
            text-4xl sm:text-5xl md:text-7xl
            font-bold
            text-[#243238]
            + mb-[4rem]
        */}
        <h2
          className="
            text-4xl
            sm:text-5xl
            md:text-7xl
            font-bold
            text-[#243238]
            text-center
            mb-[4rem]
            leading-tight
          "
        >
          {t('testimonialsSectionTitle')}
        </h2>

        {/* Grille de 3 items (témoignages) */}
        <div className="grid gap-8 md:grid-cols-3 items-stretch">
          {/* Témoignage 1 */}
          <div className="flex flex-col h-full bg-gray-50 border border-gray-300 rounded-lg shadow-md p-8">
            <div className="flex items-start mb-4">
              <img
                src="/users/Sarah-28-Paris-France.webp"
                alt={`Profil ${testimonials[0].name}`}
                className="w-44 h-44 mr-4 flex-shrink-0 object-cover"
              />
              <div>
                {/* Nom & âge => text-3xl, city/country => text-lg */}
                <div className="font-semibold text-3xl leading-tight">
                  {testimonials[0].name}
                </div>
                <div className="text-3xl leading-tight whitespace-nowrap">
                  {testimonials[0].age}
                </div>
                <div className="text-lg text-gray-600 leading-snug">
                  {testimonials[0].city}
                </div>
                <div className="text-lg text-gray-600 leading-snug">
                  {testimonials[0].country}
                </div>
              </div>
            </div>
            <p className="text-gray-800 italic">
              {testimonials[0].quote}
            </p>
          </div>

          {/* Témoignage 2 */}
          <div className="flex flex-col h-full bg-gray-50 border border-gray-300 rounded-lg shadow-md p-8">
            <div className="flex items-start mb-4">
              <img
                src="/users/Juan-36-Madrid-Spain.webp"
                alt={`Profil ${testimonials[1].name}`}
                className="w-44 h-44 mr-4 flex-shrink-0 object-cover"
              />
              <div>
                <div className="font-semibold text-3xl leading-tight">
                  {testimonials[1].name}
                </div>
                <div className="text-3xl leading-tight whitespace-nowrap">
                  {testimonials[1].age}
                </div>
                <div className="text-lg text-gray-600 leading-snug">
                  {testimonials[1].city}
                </div>
                <div className="text-lg text-gray-600 leading-snug">
                  {testimonials[1].country}
                </div>
              </div>
            </div>
            <p className="text-gray-800 italic">
              {testimonials[1].quote}
            </p>
          </div>

          {/* Témoignage 3 */}
          <div className="flex flex-col h-full bg-gray-50 border border-gray-300 rounded-lg shadow-md p-8">
            <div className="flex items-start mb-4">
              <img
                src="/users/Lucy-48-London-UK.webp"
                alt={`Profil ${testimonials[2].name}`}
                className="w-44 h-44 mr-4 flex-shrink-0 object-cover"
              />
              <div>
                <div className="font-semibold text-3xl leading-tight">
                  {testimonials[2].name}
                </div>
                <div className="text-3xl leading-tight whitespace-nowrap">
                  {testimonials[2].age}
                </div>
                <div className="text-lg text-gray-600 leading-snug">
                  {testimonials[2].city}
                </div>
                <div className="text-lg text-gray-600 leading-snug">
                  {testimonials[2].country}
                </div>
              </div>
            </div>
            <p className="text-gray-800 italic">
              {testimonials[2].quote}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};