// src/hooks/useRealtimeWebRTC/startSession.ts

import { handleDataChannelOpen } from "./handleDataChannelOpen";

interface StartSessionParams {
  setIsConnecting: (b: boolean) => void;
  onConnectingChange?: (b: boolean) => void;
  peerConnection: React.MutableRefObject<RTCPeerConnection | null>;
  audioElement: React.MutableRefObject<HTMLAudioElement | null>;
  localAudioContextRef: React.MutableRefObject<AudioContext | null>;
  localAnalyserRef: React.MutableRefObject<AnalyserNode | null>;
  remoteAudioContextRef: React.MutableRefObject<AudioContext | null>;
  remoteAnalyserRef: React.MutableRefObject<AnalyserNode | null>;
  setLocalAnalyser: (a: AnalyserNode | null) => void;
  setRemoteAnalyser: (a: AnalyserNode | null) => void;
  setDataChannel: (dc: RTCDataChannel | null) => void;
  setIsSessionActive: (b: boolean) => void;
  initialInstructions: string;
  onAnalyzersReady?: (local: AnalyserNode | null, remote: AnalyserNode | null) => void;
  showDebug: boolean;
  setEvents: React.Dispatch<React.SetStateAction<any[]>>;

  /**
   * Optionnel : si on veut injecter l'historique en plus du prompt,
   * on récupère un tableau "items" depuis le frontend (ConsolePage).
   */
  existingItems?: Array<{
    role: "user" | "assistant" | string;
    text: string;
  }>;
}

export async function startSession(params: StartSessionParams) {
  const {
    setIsConnecting,
    onConnectingChange,
    peerConnection,
    audioElement,
    localAudioContextRef,
    localAnalyserRef,
    remoteAudioContextRef,
    remoteAnalyserRef,
    setLocalAnalyser,
    setRemoteAnalyser,
    setDataChannel,
    setIsSessionActive,
    initialInstructions,
    onAnalyzersReady,
    showDebug,
    setEvents,
    existingItems,
  } = params;

  try {
    setIsConnecting(true);
    onConnectingChange?.(true);

    // ---------------------------------------------------------------------
    // (A) Construire les instructions, y compris l'historique
    // ---------------------------------------------------------------------
    let instructionsWithHistory = initialInstructions;

    if (existingItems && existingItems.length > 0) {
      // On formate l'historique en "User: ...\nAssistant: ..."
      const historyStr = existingItems
        .map((msg) => {
          const prefix = msg.role === "assistant" ? "Assistant" : "User";
          return `${prefix}: ${msg.text}`;
        })
        .join("\n");

      instructionsWithHistory += `\n\n[Conversation History]\n${historyStr}`;

      // Ajout d'une instruction spéciale pour dire "welcome back"
      instructionsWithHistory += `
[Returning User Greeting]
You are continuing a conversation with the user after a pause.
At the start of this resumed conversation, greet them with a short "Welcome back!" in English, then proceed normally.
Do not repeat this greeting again.
`;
    }

    // ---------------------------------------------------------------------
    // 1) Récupérer la clé ephemeral depuis /api/realtime/token
    // ---------------------------------------------------------------------
    const tokenResponse = await fetch("/api/realtime/token");
    if (!tokenResponse.ok) {
      throw new Error(`Token request failed: ${tokenResponse.status}`);
    }
    const data = await tokenResponse.json();
    const ephemeralKey = data?.client_secret?.value;
    if (!ephemeralKey) {
      throw new Error("No ephemeralKey returned from /api/realtime/token");
    }

    // ---------------------------------------------------------------------
    // 2) Créer RTCPeerConnection
    // ---------------------------------------------------------------------
    const pc = new RTCPeerConnection({
      iceServers: [{ urls: "stun:stun1.l.google.com:19302" }],
    });
    peerConnection.current = pc;

    // ---------------------------------------------------------------------
    // 3) Créer un <audio> pour le flux distant
    // ---------------------------------------------------------------------
    audioElement.current = document.createElement("audio");
    audioElement.current.autoplay = true;

    // ---------------------------------------------------------------------
    // 4) Sur pc.ontrack => assigne remoteStream au <audio>
    // ---------------------------------------------------------------------
    pc.ontrack = (evt) => {
      if (showDebug) {
        console.log("[startSession] => ontrack => got remote stream");
      }

      const remoteStream = evt.streams[0];
      if (!audioElement.current) return;

      audioElement.current.srcObject = remoteStream;

      // AudioContext côté remote
      remoteAudioContextRef.current = new AudioContext();
      remoteAnalyserRef.current = remoteAudioContextRef.current.createAnalyser();
      remoteAnalyserRef.current.fftSize = 256;

      const remoteSource = remoteAudioContextRef.current.createMediaStreamSource(remoteStream);
      remoteSource.connect(remoteAnalyserRef.current);

      setRemoteAnalyser(remoteAnalyserRef.current);
      onAnalyzersReady?.(localAnalyserRef.current, remoteAnalyserRef.current);
    };

    // ---------------------------------------------------------------------
    // 5) Capturer micro local => pc.addTrack
    // ---------------------------------------------------------------------
    const userMediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
    userMediaStream.getTracks().forEach((track) => pc.addTrack(track, userMediaStream));

    // AudioContext local + analyser
    localAudioContextRef.current = new AudioContext();
    localAnalyserRef.current = localAudioContextRef.current.createAnalyser();
    localAnalyserRef.current.fftSize = 256;

    const localSource = localAudioContextRef.current.createMediaStreamSource(userMediaStream);
    localSource.connect(localAnalyserRef.current);

    setLocalAnalyser(localAnalyserRef.current);
    onAnalyzersReady?.(localAnalyserRef.current, remoteAnalyserRef.current);

    // ---------------------------------------------------------------------
    // 6) Créer un DataChannel pour échanger les events (oai-events)
    // ---------------------------------------------------------------------
    const dc = pc.createDataChannel("oai-events");
    setDataChannel(dc);

    dc.onopen = () => {
      if (showDebug) {
        console.log("[startSession] => dataChannel open => session.update");
      }

      // Log un event
      setEvents((prev) => [...prev, { type: "SESSION_OPEN", timestamp: Date.now() }]);

      // Envoyer instructions => handleDataChannelOpen
      handleDataChannelOpen(dc, instructionsWithHistory);
    };

    dc.onclose = () => {
      if (showDebug) {
        console.log("[startSession] => dataChannel closed");
      }
    };

    // ---------------------------------------------------------------------
    // 7) createOffer => POST => OpenAI Realtime => récupère l'answer
    // ---------------------------------------------------------------------
    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);

    const baseUrl = "https://api.openai.com/v1/realtime";
    const model = "gpt-4o-realtime-preview-2024-12-17";

    const sdpResponse = await fetch(`${baseUrl}?model=${model}`, {
      method: "POST",
      body: offer.sdp,
      headers: {
        Authorization: `Bearer ${ephemeralKey}`,
        "Content-Type": "application/sdp",
      },
    });
    if (!sdpResponse.ok) {
      throw new Error(`OpenAI Realtime API returned ${sdpResponse.status}`);
    }

    const rawAnswer = await sdpResponse.text();
    const answer: RTCSessionDescriptionInit = {
      type: "answer",
      sdp: rawAnswer,
    };
    await pc.setRemoteDescription(answer);

    setIsSessionActive(true);
    setIsConnecting(false);
    onConnectingChange?.(false);

    if (showDebug) {
      console.log("[startSession] => session active!");
    }
  } catch (err) {
    console.error("[startSession] => error:", err);
    setIsConnecting(false);
    onConnectingChange?.(false);
  }
}