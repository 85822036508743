// src/hooks/workflowImagesMap.ts

export const WORKFLOW_IMAGE_MAP: Record<string, string[]> = {
    // Étape "language" => On sait que StudyLanguageSelection n’affiche que
    // quelques flags. S’il s’agit d’un sprite `flag-icons`, ce n’est pas
    // forcément à précharger. Sinon, on peut lister ici…
    language: [
      // (Exemple) "logo, background, etc." s'il y en a
    ],
  
    // Étape "heardAbout" => images : /heard-about/*.png, .webp
    heardAbout: [
      '/heard-about/google-search-icon.webp',
      '/heard-about/friends-icon.png',
      '/heard-about/social-networks-icon.png',
      '/heard-about/podcast-icon.png',
      '/heard-about/other-icon.png',
    ],
  
    // Étape "whyLearn" => /why-learn/*.png
    whyLearn: [
      '/why-learn/work-icon.png',
      '/why-learn/travel-icon.png',
      '/why-learn/friends-icon.png',
      '/why-learn/exam-icon.png',
      '/why-learn/having-fun-icon.png',
      '/why-learn/clever-time-icon.png',
      '/why-learn/other-icon.png',
    ],
  
    // Étape "level" => images du composant “SignalBars” ? (si c’est seulement des SVG inline, c’est inutile)
    level: [],
  
    // Étape "encouragement"
    encouragement: [
      '/encouragement/pronunciation-icon.png',
      '/encouragement/speaking-with-confidence-icon.png',
      '/encouragement/vocabulary-icon.png',
    ],
  
    // Étape "dailyGoal"
    dailyGoal: [
      '/daily-goal/clock-icon.png',
    ],
  
    // Étape "notifications-opt-in"
    'notifications-opt-in': [
      '/icons/notification-icon.webp',
    ],
  
    // Étape "startChoice"
    startChoice: [
      '/start-choice/beginner-icon.png',
      '/start-choice/evaluation-icon.png',
    ],
  
    // Étape "scenario" => le “scenario selection” utilise bcp d’icônes
    scenario: [
      '/scenarioselection/free-chat-icon.png',
      '/scenarioselection/coffee-icon.png',
      '/scenarioselection/airport-icon.png',
      '/scenarioselection/restaurant-icon.png',
      '/scenarioselection/hotel-icon.png',
      // …(ajoutez toutes les images possibles)
    ],
  
    // Étape "console"
    console: [
      // S’il y a des images “spéciales” (icônes micro, etc.)
      // S’il n’y en a pas, vous pouvez laisser vide
    ],
  };