// src/pages/LoginPage.tsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  AuthErrorCodes,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Layout } from '../components/Layout';
import { VoxButton } from '../components/VoxButton'; // <-- Import du bouton factorisé

/**
 * Composant LoginPage :
 *  - Permet de se connecter (e-mail / mot de passe)
 *  - Gère la logique de “mot de passe oublié”
 *  - Permet aussi de se connecter via Google
 *  - Redirige si l'utilisateur est déjà logué
 */
export const LoginPage: React.FC = () => {
  // On cible le namespace "login"
  const { t: tLogin } = useTranslation('login');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [resetSent, setResetSent] = useState(false);

  const { currentUser } = useAuth(); // Pour savoir si on est déjà logué
  const navigate = useNavigate();

  // [A] Si déjà logué => on redirige
  useEffect(() => {
    if (currentUser && !currentUser.isAnonymous) {
      console.log('[LoginPage] ✅ Utilisateur déjà connecté => redirection');
      navigate('/', { replace: true });
    }
  }, [currentUser, navigate]);

  // [B] Handler principal “Login” (Email + MDP)
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setResetSent(false);

    if (!email || !password) {
      setError(tLogin('missingEmailPassword', 'Email and password required.'));
      return;
    }
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('[LoginPage] => Connexion réussie :', {
        uid: userCredential.user.uid,
        email: userCredential.user.email,
      });

      // Redirection vers "/"
      navigate('/', { replace: true });
    } catch (err: any) {
      console.error('[LoginPage] => Erreur login =>', err);
      if (
        err.code === AuthErrorCodes.INVALID_PASSWORD ||
        err.code === AuthErrorCodes.USER_DELETED
      ) {
        setError(tLogin('invalidCredentials', 'E-mail or password invalid.'));
      } else {
        // Autre message d'erreur => fallback sur err.message
        setError(err.message);
      }
    }
  };

  // [C] Mot de passe oublié
  const handleResetPassword = async () => {
    setError(null);
    if (!email) {
      setError(tLogin('missingEmailForReset', 'Please enter your email to reset your password.'));
      return;
    }
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setResetSent(true);
      console.log('[LoginPage] => Email de réinitialisation envoyé');
    } catch (err: any) {
      console.error('[LoginPage] => Erreur reset password =>', err);
      if (err.code === AuthErrorCodes.USER_DELETED) {
        setError(tLogin('noAccountForEmail', 'No account associated with this email.'));
      } else {
        setError(err.message);
      }
    }
  };

  // [D] Connexion via Google
  const handleGoogleSignIn = async () => {
    setError(null);
    setResetSent(false);

    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      // provider.addScope('https://www.googleapis.com/auth/contacts.readonly'); // ex. scope supplémentaire

      // Ouvre la popup de connexion Google
      const result = await signInWithPopup(auth, provider);

      // user récupéré par la GoogleAuth
      const user = result.user;
      console.log('[LoginPage] => Connexion via Google réussie :', {
        uid: user.uid,
        email: user.email,
        providerId: user.providerId,
      });

      // Redirection vers "/"
      navigate('/', { replace: true });
    } catch (err: any) {
      console.error('[LoginPage] => Erreur Google sign-in =>', err);
      if (err.code === 'auth/popup-closed-by-user') {
        setError(tLogin('popupClosed', 'The Google window was closed before signing in.'));
      } else if (err.code === 'auth/account-exists-with-different-credential') {
        setError(
          tLogin('accountExistsDifferentCred', 'An account already exists for this email. Use another method.')
        );
      } else {
        setError(err.message);
      }
    }
  };

  // [E] Rendu
  return (
    <Layout noMaxWidth>
      <div className="min-h-screen flex flex-col items-center justify-center px-4">
        <div
          className="
            w-full max-w-lg
            bg-white
            border-2 border-gray-600
            rounded-md
            shadow
            p-10
            text-[1.2rem]
            sm:text-[1.25rem]
            md:text-[1.375rem]
            lg:text-[1.65rem]
            text-gray-800
          "
        >
          {/* Titre */}
          <h1 className="text-center font-extrabold mb-6 text-[1.4rem] sm:text-[1.5rem] md:text-[1.7rem] lg:text-[2rem]">
            {tLogin('title', 'Log in')}
          </h1>

          {/* Gestion des erreurs et feedback */}
          {error && (
            <div className="text-red-600 mb-3 text-center">
              {error}
            </div>
          )}
          {resetSent && (
            <div className="text-green-600 mb-3 text-center">
              {tLogin('resetEmailSent', 'A password reset link has been sent to your email address.')}
            </div>
          )}

          {/* Formulaire Email + Password */}
          <form onSubmit={handleLogin} className="space-y-6">
            {/* Champ Email */}
            <div>
              <input
                type="email"
                placeholder={tLogin('emailPlaceholder', 'Email address')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="
                  w-full
                  border-2 border-gray-600
                  rounded-lg
                  px-4 py-3
                  text-gray-900
                  placeholder-gray-600
                  focus:outline-none
                  focus:ring-2
                  focus:ring-blue-400
                "
              />
            </div>

            {/* Champ Mot de passe + bouton œil */}
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder={tLogin('passwordPlaceholder', 'Password')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="
                  w-full
                  border-2 border-gray-600
                  rounded-lg
                  px-4 py-3
                  text-gray-900
                  placeholder-gray-600
                  focus:outline-none
                  focus:ring-2
                  focus:ring-blue-400
                "
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="
                  absolute
                  inset-y-0
                  right-3
                  flex
                  items-center
                  text-gray-500
                  hover:text-gray-700
                "
                tabIndex={-1}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>

            {/* Bouton submit => <VoxButton> variant="primary" */}
            <div>
              <VoxButton
                variant="primary"
                width="auto"
                type="submit"
                style={{ width: '100%' }} // Pour occuper toute la largeur
              >
                {tLogin('loginButton', 'Log in')}
              </VoxButton>
            </div>
          </form>

          {/* Lien mot de passe oublié */}
          <div className="mt-4 text-center">
            <button
              type="button"
              onClick={handleResetPassword}
              className="text-blue-600 hover:text-blue-700"
            >
              {tLogin('forgotPassword', 'Forgot your password?')}
            </button>
          </div>

          {/* LIGNE DE SÉPARATION */}
          <div className="flex items-center my-6">
            <div className="flex-grow border-t border-gray-400" />
            <span className="mx-2 text-gray-600">
              {tLogin('separatorOr', 'OR')}
            </span>
            <div className="flex-grow border-t border-gray-400" />
          </div>

          {/* Bouton Sign in with Google => <VoxButton> variant="secondary" */}
          <VoxButton
            variant="secondary"
            width="auto"
            onClick={handleGoogleSignIn}
            style={{ width: '100%' }} // Pour occuper toute la largeur
          >
            <div className="flex items-center justify-center gap-2">
              <svg
                className="w-5 h-5"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#EA4335"
                  d="M24 9.5c3.94 0 7.14 1.62 9.39 3l6.92-6.92C36.06 2.33 30.48 0 24 0 14.66 0 6.36 4.74 1.74 11.64l7.37 5.73C11.29 11.67 17.18 9.5 24 9.5z"
                />
                <path
                  fill="#4285F4"
                  d="M46.145 24.5c0-1.64-.15-3.2-.43-4.73H24v9h12.46c-.53 2.4-1.98 4.43-4.2 5.82l6.68 5.19c3.9-3.59 6.14-8.89 6.14-15.28z"
                />
                <path
                  fill="#FBBC05"
                  d="M8.765 29.109a14.51 14.51 0 0 1-.76-4.61c0-1.6.28-3.14.76-4.61l-7.37-5.73C.45 17.04 0 20.46 0 24c0 3.54.45 6.96 1.395 10.14l7.37-5.031z"
                />
                <path
                  fill="#34A853"
                  d="M24 48c6.48 0 11.94-2.15 15.92-5.86l-6.68-5.19c-2 1.37-4.53 2.19-9.24 2.19-6.82 0-12.71-4.17-14.83-10.06l-7.37 5.03C6.36 43.26 14.66 48 24 48z"
                />
                <path fill="none" d="M0 0h48v48H0z" />
              </svg>
              <span>{tLogin('googleSignIn', 'SIGN IN WITH GOOGLE')}</span>
            </div>
          </VoxButton>
        </div>
      </div>
    </Layout>
  );
};

export default LoginPage;