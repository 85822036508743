// src/pages/LandingPage.tsx

import React from 'react';
import { Layout } from '../components/Layout';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { ResponsiveLanguageCarousel } from '../components/ResponsiveLanguageCarousel';
import { LandingFeatureSection } from './LandingFeatureSection';
import { LandingTestimonialsSection } from './LandingTestimonialsSection';
import { LandingFAQSection } from './LandingFAQSection';
import { useLandingPageLogic } from './useLandingPageLogic';
import { useLanguage } from '../context/LanguageContext';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { useNavigate } from 'react-router-dom';
import { VoxButton } from '../components/VoxButton';
// Import du composant SEO qui injecte <link rel="alternate"> etc.
import { SeoAlternateLinks } from '../components/SeoAlternateLinks';
// Import du composant Seo qui injecte <title> et <meta name="description">
import { Seo } from '../components/Seo';

interface LandingPageProps {
  onGetStarted?: () => void;
}

/**
 * Divise un texte en 3 lignes « équitables »
 */
function splitTextIntoThreeLines(text: string): string[] {
  const words = text.split(' ');
  const totalWords = words.length;
  const chunkSize = Math.ceil(totalWords / 3);
  const lines: string[] = [];
  for (let i = 0; i < totalWords; i += chunkSize) {
    lines.push(words.slice(i, i + chunkSize).join(' '));
  }
  return lines;
}

export const LandingPage: React.FC<LandingPageProps> = ({ onGetStarted }) => {
  console.log('[LandingPage] Montée du composant LandingPage');

  // Récupération des infos / handlers depuis la logique custom
  const {
    heroTitle,
    heroDescription,
    carouselItems,
    selectedLangFlag,
    methodPhrase,
    isSpecificLanding,
    studyLang,
    uiLang,
    handleGetStartedClick,
    handleLoginClick,
    handleStudyLanguageSelect,
  } = useLandingPageLogic({ onGetStarted });

  const { interfaceLanguage } = useLanguage();
  const { t } = useTranslation('landingPage');
  const navigate = useNavigate();

  console.log('[LandingPage] Langue d’interface (header) initiale :', interfaceLanguage);
  console.log('[LandingPage] Rendu normal => heroTitle:', heroTitle);

  /**
   * Clic sur le logo => retour sur la landing générique
   * si on était sur une landing spécifique
   */
  const handleLogoClick = () => {
    if (isSpecificLanding) {
      const targetUrl = `/${interfaceLanguage || 'en'}`;
      navigate(targetUrl);
    }
  };

  /**
   * Construire un <title> et une <meta name="description"> spécifiques
   * selon que l’on est sur une landing spécifique (langue d’étude) ou non
   */
  const seoTitle = isSpecificLanding
    ? `Voxanet – Learn ${studyLang} with AI`
    : 'Voxanet – Your AI Language Teacher';

  const seoDescription = isSpecificLanding
    ? `Improve your ${studyLang} skills with AI-driven lessons, real-time feedback, and interactive speaking practice.`
    : 'Voxanet is an AI-powered platform that helps you learn and practice any language through interactive speaking, grammar, and vocabulary lessons.';

  /**
   * Contenu du bas de la section Hero : soit un composant 
   * affichant la langue choisie (methodPhrase + flag), 
   * soit le carousel de langues.
   */
  let bottomContent: React.ReactNode;
  if (isSpecificLanding && methodPhrase && selectedLangFlag) {
    bottomContent = (
      <div className="flex items-center justify-center bg-white p-3 rounded-md shadow-md">
        <span className="text-6xl mr-4">{selectedLangFlag}</span>
        <span className="whitespace-normal break-words text-xl md:text-2xl font-bold leading-snug">
          {methodPhrase}
        </span>
      </div>
    );
  } else {
    bottomContent = (
      <ResponsiveLanguageCarousel
        items={carouselItems}
        onSelectLanguage={(langCode) => {
          console.log('[LandingPage] Carousel => sélection langue:', langCode);
          handleStudyLanguageSelect(langCode);
        }}
      />
    );
  }

  return (
    <Layout noMaxWidth>
      {/*
        1) Injection des balises SEO principales (title, meta description, etc.)
           via le composant <Seo />
      */}
      <Seo
        title={seoTitle}
        description={seoDescription}
        lang={interfaceLanguage || 'en'}
        canonicalUrl={`https://www.voxanet.com/${interfaceLanguage || 'en'}`}
        ogImage="https://www.voxanet.com/images/landing-hero-og.jpg"
      />

      {/*
        2) Injection des balises alternate/hreflang via <SeoAlternateLinks />
      */}
      <SeoAlternateLinks
        baseUrl="https://www.voxanet.com"
        currentLang={interfaceLanguage || 'en'}
      />

      {/* Header réutilisable : position absolue, clique sur logo => handleLogoClick */}
      <Header isAbsolute onLogoClick={handleLogoClick} />

      {/*
        SECTION HERO
        - Sur desktop, on conserve h-screen et le header absolu.
        - Sur mobile, on utilise pt-10 pour relever légèrement le contenu.
        - La marge verticale est gérée via mt-0 sur mobile et mt-4 sur desktop.
      */}
      <section className="relative h-screen w-full overflow-hidden flex flex-col bg-white pt-10 md:pt-0">
        {/* Bloc central : Vidéo + Titre + Boutons */}
        <div className="h-full flex flex-col md:flex-row items-center justify-center px-4 gap-2 md:gap-20 mt-0 md:mt-4 md:-translate-y-14">
          {/* Vidéo (gauche) */}
          <div className="flex justify-center mb-2 md:mb-0">
            <div className="border-2 border-gray-800 overflow-hidden rounded-xl w-64 h-64 sm:w-80 sm:h-80 md:w-[30rem] md:h-[30rem] lg:w-[30rem] lg:h-[30rem]">
              <video
                autoPlay
                loop
                muted
                playsInline
                preload="auto"
                className="w-full h-full object-cover"
              >
                <source src="/animations/animation-landingpage.mp4" type="video/mp4" />
              </video>
            </div>
          </div>

          {/* Titre + Boutons d'action (droite) */}
          <div className="flex flex-col items-center justify-center">
            <div className="max-w-[44rem] mx-auto text-center px-4">
              <h1 className="text-4xl sm:text-5xl md:text-7xl font-extrabold text-[#243238] mb-4 md:mb-6 leading-tight">
                {splitTextIntoThreeLines(heroTitle).map((line, idx) => (
                  <span key={idx}>
                    {line}
                    {idx < 2 && <br />}
                  </span>
                ))}
              </h1>

              <p className="hidden md:block text-lg sm:text-lg md:text-xl lg:text-3xl text-gray-800 mb-8 leading-snug">
                {heroDescription}
              </p>

              {/* Boutons d'action */}
              <div className="flex flex-col gap-2 md:gap-4 items-center">
                <VoxButton
                  variant="primary"
                  width="medium"
                  onClick={() => {
                    console.log('[LandingPage] "Get Started" cliqué');
                    handleGetStartedClick();
                  }}
                >
                  {t('getStartedButton')}
                </VoxButton>

                <VoxButton
                  variant="secondary"
                  width="medium"
                  onClick={() => {
                    console.log('[LandingPage] "I already have an account" cliqué.');
                    handleLoginClick();
                  }}
                >
                  {t('alreadyHaveAccount')}
                </VoxButton>
              </div>
            </div>
          </div>
        </div>

        {/* Bas de la section Hero : Carousel ou bloc spécialisé */}
        <div className="absolute bottom-2 md:bottom-4 left-1/2 transform -translate-x-1/2 w-full flex justify-center">
          <div className="max-w-screen-xl w-full px-2">
            {bottomContent}
          </div>
        </div>
      </section>

      {/* SECTION Features */}
      <LandingFeatureSection />

      {/* SECTION Testimonials */}
      <LandingTestimonialsSection />

      {/* SECTION FAQ */}
      <LandingFAQSection />

      {/* SECTION CTA FINALE */}
      <section className="relative w-screen left-1/2 ml-[-50vw] right-1/2 mr-[-50vw] py-24 bg-white">
        <div className="max-w-screen-lg mx-auto px-4 text-center text-3xl leading-snug">
          <div className="mb-16">
            <CTAFinalTitle
              isSpecificLanding={isSpecificLanding}
              studyLang={studyLang}
              uiLang={uiLang}
            />
          </div>
          <VoxButton
            variant="primary"
            width="medium"
            onClick={() => {
              console.log('[LandingPage] Bouton final CTA cliqué.');
              handleGetStartedClick();
            }}
          >
            {t('finalCtaButton')}
          </VoxButton>
        </div>
      </section>

      {/* FOOTER */}
      <div className="relative w-screen left-1/2 ml-[-50vw] right-1/2 mr-[-50vw] bg-white border-t-0 mt-0">
        <Footer />
      </div>
    </Layout>
  );
};

export default LandingPage;

/**
 * Composant CTA finale
 */
interface CTAFinalTitleProps {
  isSpecificLanding: boolean;
  studyLang?: string;
  uiLang?: string;
}

function CTAFinalTitle({ isSpecificLanding, studyLang, uiLang }: CTAFinalTitleProps) {
  const { t } = useTranslation('landingPage');
  console.log('[CTAFinalTitle] =>', { isSpecificLanding, studyLang, uiLang });

  if (!isSpecificLanding || !studyLang || !uiLang) {
    return (
      <h2 className="text-4xl sm:text-5xl md:text-7xl font-bold mb-6 text-[#243238] leading-tight">
        {t('finalCtaTitleGeneric')}
      </h2>
    );
  }

  const fixedT = i18n.getFixedT(uiLang, ['languages']);
  const studyLangName = fixedT(`withArticle.${studyLang}`, { defaultValue: studyLang });
  const ctaText = t('finalCtaTitleWithLang', { studyLangName });
  return (
    <h2 className="text-4xl sm:text-5xl md:text-7xl font-bold mb-6 text-[#243238] leading-tight">
      {ctaText}
    </h2>
  );
}