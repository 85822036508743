// src/config/limits.ts

let messagesRequiredToCompleteScenario: number; 
let anonymousUserMaxMessages: number;

// On définit l'environnement courant :
// - production
// - development (par défaut)
const environment = process.env.REACT_APP_ENVIRONMENT || 'development';

/**
 * Pour l'environnement de production :
 *  - Le scénario se termine après 10 messages "user" (messagesRequiredToCompleteScenario).
 *  - Les utilisateurs anonymes ont droit à 20 messages maximum avant de devoir s'inscrire (anonymousUserMaxMessages).
 *  - Les utilisateurs free ont droit à 50 messages (freeUserLimit).
 *
 * Pour l'environnement de développement :
 *  - Les valeurs sont volontairement très élevées (200).
 */
if (environment === 'production') {
  messagesRequiredToCompleteScenario = 10;
  anonymousUserMaxMessages = 8;
} else {  
  messagesRequiredToCompleteScenario = 10;    // ou 3
  anonymousUserMaxMessages = 20;             // ou 3
}

/**
 * MESSAGE_LIMITS représente les différentes limites
 * que l'application applique :
 *  - MESSAGES_REQUIRED_TO_COMPLETE_SCENARIO : 
 *       Combien de messages "user" sont nécessaires pour clôturer un scénario.
 *  - ANONYMOUS_USER_PROMPT_LIMIT :
 *       Combien de messages un user anonyme peut envoyer avant qu’on ne lui propose de s’inscrire.
 *  - FREE_USER_LIMIT :
 *       Combien de messages un user gratuit (non-abonné) peut envoyer avant qu’on ne lui propose la souscription.
 */
export const MESSAGE_LIMITS = {
  MESSAGES_REQUIRED_TO_COMPLETE_SCENARIO: messagesRequiredToCompleteScenario,
  ANONYMOUS_USER_PROMPT_LIMIT: anonymousUserMaxMessages,
};

/**
 * SPECIAL_USERS contient la liste des users (UID) qui ont accès
 * à un mode illimité ou spécial. Par exemple, pour le fondateur, QA, etc.
 */
export const SPECIAL_USERS = {
  UNLIMITED_ACCESS: ['605Ql2KWbSMfMn4SCGxPd6MJ6E73'],
};