// src/pages/StudyLanguageSelection.tsx

import React, { useState, useEffect } from 'react';
import { Layout } from '../components/Layout';
import { Header } from '../components/Header';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/AuthContext';
import { useStudy } from '../context/StudyContext';
import FlagIcon from '../components/FlagIcon';

interface StudyLanguageSelectionProps {
  onLanguageSelect: () => void;
}

interface LanguageCardData {
  code: string;
  countryCode: string;
  nameKey: string;
  learnersCountLabel: string;  // ex. "690M learners"
}

/**
 * Fonction utilitaire pour extraire la valeur numérique du champ learnersCountLabel.
 * Exemple : "48.8M learners" → 48800000
 */
function parseLearnersCountLabel(label: string): number {
  const [countString] = label.split(' ');
  let multiplier = 1;
  let numericPart = countString.toLowerCase();

  if (numericPart.endsWith('m')) {
    multiplier = 1_000_000;
    numericPart = numericPart.replace('m', '');
  }

  return parseFloat(numericPart) * multiplier;
}

/**
 * LANGUAGES_DATA : liste des langues + leur nombre d'apprenants.
 */
const LANGUAGES_DATA: LanguageCardData[] = [
  { code: 'en', countryCode: 'us', nameKey: 'languages.en', learnersCountLabel: '690M learners' },
  { code: 'ar', countryCode: 'sa', nameKey: 'languages.ar', learnersCountLabel: '8.31M learners' },
  { code: 'de', countryCode: 'de', nameKey: 'languages.de', learnersCountLabel: '18.6M learners' },
  { code: 'es', countryCode: 'es', nameKey: 'languages.es', learnersCountLabel: '48.8M learners' },
  { code: 'fr', countryCode: 'fr', nameKey: 'languages.fr', learnersCountLabel: '27.3M learners' },
  { code: 'hi', countryCode: 'in', nameKey: 'languages.hi', learnersCountLabel: '11M learners' },
  { code: 'it', countryCode: 'it', nameKey: 'languages.it', learnersCountLabel: '13.1M learners' },
  { code: 'ja', countryCode: 'jp', nameKey: 'languages.ja', learnersCountLabel: '23.3M learners' },
  { code: 'ko', countryCode: 'kr', nameKey: 'languages.ko', learnersCountLabel: '17.8M learners' },
  { code: 'nl', countryCode: 'nl', nameKey: 'languages.nl', learnersCountLabel: '3.22M learners' },
  { code: 'pl', countryCode: 'pl', nameKey: 'languages.pl', learnersCountLabel: '2.51M learners' },
  { code: 'pt', countryCode: 'pt', nameKey: 'languages.pt', learnersCountLabel: '5.86M learners' },
  { code: 'ru', countryCode: 'ru', nameKey: 'languages.ru', learnersCountLabel: '9.03M learners' },
  { code: 'tr', countryCode: 'tr', nameKey: 'languages.tr', learnersCountLabel: '4.71M learners' },
  { code: 'ur', countryCode: 'pk', nameKey: 'languages.ur', learnersCountLabel: '7.6M learners' },
  { code: 'zh', countryCode: 'cn', nameKey: 'languages.zh', learnersCountLabel: '10.7M learners' },
];

export const StudyLanguageSelection: React.FC<StudyLanguageSelectionProps> = ({ onLanguageSelect }) => {
  const { currentUser } = useAuth();
  const { t: tCommon } = useTranslation('common');
  const { setStudyLanguage } = useStudy();

  // État local pour la langue sélectionnée
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');

  // Tri dynamique par nombre d'apprenants (desc)
  const sortedLanguagesData = [...LANGUAGES_DATA].sort((a, b) => {
    return parseLearnersCountLabel(b.learnersCountLabel) - parseLearnersCountLabel(a.learnersCountLabel);
  });

  useEffect(() => {
    console.log('[StudyLanguageSelection] Monté. currentUser:', currentUser?.uid ?? 'aucun');
  }, [currentUser]);

  const handleCardClick = async (langCode: string) => {
    console.log('[StudyLanguageSelection] →', langCode);
    setSelectedLanguage(langCode);

    if (!currentUser) {
      console.error('[StudyLanguageSelection] Aucun utilisateur connecté');
      return;
    }

    setStudyLanguage(langCode);

    try {
      const res = await fetch('/api/user/registerOrUpdateUser', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uid: currentUser.uid,
          studyLanguage: langCode,
        }),
      });
      console.log('[StudyLanguageSelection] API => status:', res.status);
      const data = await res.json();
      console.log('[StudyLanguageSelection] data =>', data);
    } catch (err) {
      console.error('[StudyLanguageSelection] Erreur =>', err);
    }

    onLanguageSelect();
  };

  console.log('[StudyLanguageSelection] selectedLanguage =', selectedLanguage);

  return (
    <Layout noMaxWidth>
      {/* Header identique à la page d’accueil */}
      <Header />

      {/*
        Section -> on veut un grand titre
        => text-4xl sm:text-5xl md:text-7xl
        On diminue la marge-top seulement sur mobile (pt-6),
        puis on repasse sur pt-10 dès sm: et pt-20 dès md:.
      */}
      <section
        className="
          min-h-screen
          flex
          flex-col
          items-center
          justify-start
          px-4
          pt-4 sm:pt-10 md:pt-20
          pb-24
        "
      >
        <h1
          className="
            text-4xl
            sm:text-5xl
            md:text-7xl
            font-extrabold
            text-[#243238]
            mb-10
            md:mb-20
            leading-tight
            text-center
          "
        >
          {tCommon('studyLanguageSelection.title')}
        </h1>

        {/*
          Cartes => text-xl / md:text-2xl
        */}
        <div
          className="
            w-full
            max-w-screen-xl
            mx-auto
            grid
            grid-cols-1
            sm:grid-cols-2
            md:grid-cols-3
            lg:grid-cols-4
            gap-x-8
            gap-y-10
            mb-6
            justify-items-center
            text-xl
            md:text-2xl
          "
        >
          {sortedLanguagesData.map((lang) => {
            const isSelected = lang.code === selectedLanguage;

            return (
              <button
                key={lang.code}
                onClick={() => handleCardClick(lang.code)}
                className={`
                  w-[276px]
                  h-[276px]
                  flex flex-col
                  items-center
                  justify-center
                  border-2
                  ${isSelected ? 'border-blue-400' : 'border-gray-400'}
                  bg-white
                  rounded-xl
                  shadow-md
                  hover:shadow-lg
                  hover:border-blue-400
                  transition-all
                  cursor-pointer
                `}
              >
                {/* 
                  Conteneur drapeau 
                */}
                <div
                  className="
                    w-[138px]
                    h-[102px]
                    border
                    border-gray-300
                    rounded-xl
                    overflow-hidden
                    flex
                    items-center
                    justify-center
                  "
                >
                  <FlagIcon countryCode={lang.countryCode} size={138} />
                </div>

                {/*
                  Nom => text-xl / md:text-2xl + font-bold uppercase
                */}
                <div
                  className="
                    mt-4
                    font-bold
                    uppercase
                    text-gray-800
                    tracking-wide
                  "
                >
                  {tCommon(lang.nameKey)}
                </div>

                {/*
                  Sous-texte => mêmes classes => text-xl / md:text-2xl
                */}
                <div
                  className="
                    font-normal
                    text-gray-700
                    mt-1
                  "
                >
                  {lang.learnersCountLabel.replace(
                    'learners',
                    tCommon('studyLanguageSelection.learnersLabel')
                  )}
                </div>
              </button>
            );
          })}
        </div>
      </section>
    </Layout>
  );
};

export default StudyLanguageSelection;