// src/pages/InnerApp.tsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Routes, Route } from 'react-router-dom';
import i18n from '../i18n';

// Pages marketing
import AboutPage from './Marketing/AboutPage';
import MissionVisionPage from './Marketing/MissionVisionPage';
import HowItWorksPage from './Marketing/HowItWorksPage';
import ContactPage from './Marketing/ContactPage';
import PricingPage from './Marketing/PricingPage';
import TeamPage from './Marketing/TeamPage'; // ← Import de la nouvelle page "TeamPage"

// Pages d’onboarding et autres
import { LandingPage } from './LandingPage';
import { StudyLanguageSelection } from './StudyLanguageSelection';
import { StudyLevelSelection } from './StudyLevelSelection';
import { ConversationScenarioSelection } from './ConversationScenarioSelection/ConversationScenarioSelection';
import { ConsolePage } from './ConsolePage/ConsolePage';
import { SettingsPage } from './SettingsPage';
import SuccessPage from './SuccessPage';
import HeardAboutPage from './HeardAboutPage';
import WhyLearnPage from './WhyLearnPage';
import EncouragementPage from './EncouragementPage';
import DailyGoalPage from './DailyGoalPage';
import StartChoicePage from './StartChoicePage';
import NotificationsOptInPage from './NotificationsOptInPage';

// Hooks & context
import { useAppInitialization } from '../hooks/useAppInitialization';
import { useStudy } from '../context/StudyContext';
import { useLanguage } from '../context/LanguageContext';
import { useAuth } from '../context/AuthContext';
import { allowedInterfaceLanguages, defaultInterfaceLanguage } from '../config/languages';

// Types
import { WorkflowStep } from '../types/WorkflowStep';

// Pour stocker ou lire l’étape dans localStorage (optionnel)
import { setRoutingState } from '../utils/routingState';

// Hook perso pour avertir des changements non sauvegardés
import { useWarnOnUnsavedChanges } from '../hooks/useWarnOnUnsavedChanges';

// *** Importation du hook de preloading ***
import { useWorkflowPreloading } from '../hooks/useWorkflowPreloading';

/**
 * Fonction simulant un fetch vers /api/user/registerOrUpdateUser
 */
async function updateUserPreferredLanguage(uid: string, lang: string) {
  console.log('[updateUserPreferredLanguage] => uid=', uid, '| lang=', lang);
  // ... ex. fetch(...)
}

export function InnerApp() {
  // On définit currentStep comme un WorkflowStep
  const [currentStep, setCurrentStep] = useState<WorkflowStep>('landing');

  // Récupère :lang
  const { lang } = useParams();
  const navigate = useNavigate();

  // Contexte
  const { interfaceLanguage, setInterfaceLanguage } = useLanguage();
  const { currentUser } = useAuth();
  const { studyLanguage, setStudyLanguage } = useStudy();

  // Avertissements de sortie
  useWarnOnUnsavedChanges();

  /**
   * 1) Initialisation
   */
  useAppInitialization({
    currentStep,
    setCurrentStep,
    locationPathname: window.location.pathname,
  });

  // *** Appel du hook de préchargement en fonction de l'étape courante ***
  useWorkflowPreloading(currentStep);

  /**
   * 2) Gestion de la langue
   */
  useEffect(() => {
    if (!lang) return;
    if (!allowedInterfaceLanguages.includes(lang)) {
      navigate(`/${defaultInterfaceLanguage}`, { replace: true });
      return;
    }
    if (lang !== interfaceLanguage) {
      setInterfaceLanguage(lang);
      i18n.changeLanguage(lang);
      document.documentElement.lang = lang;
      if (currentUser) {
        updateUserPreferredLanguage(currentUser.uid, lang);
      }
    } else {
      // Force tout de même i18n
      i18n.changeLanguage(lang);
      document.documentElement.lang = lang;
    }
  }, [lang, interfaceLanguage, currentUser, setInterfaceLanguage, navigate]);

  /**
   * 3) Récupération de studyLanguage dans localStorage
   */
  useEffect(() => {
    if (!studyLanguage) {
      const stored = localStorage.getItem('studyLanguage');
      if (stored) {
        setStudyLanguage(stored);
      }
    }
  }, [studyLanguage, setStudyLanguage]);

  /**
   * 4) Handlers d’onboarding
   */
  const handleGetStarted = () => {
    setRoutingState({ onboardingStep: 'language' });
    navigate(`/${lang || defaultInterfaceLanguage}/language`);
  };

  const handleStudyLanguageSelect = () => {
    setRoutingState({ onboardingStep: 'heard-about' });
    navigate(`/${lang || defaultInterfaceLanguage}/heard-about`);
  };

  const handleHeardAboutNext = () => {
    setRoutingState({ onboardingStep: 'why-learn' });
    navigate(`/${lang || defaultInterfaceLanguage}/why-learn`);
  };

  const handleWhyLearnNext = () => {
    setRoutingState({ onboardingStep: 'level' });
    navigate(`/${lang || defaultInterfaceLanguage}/level`);
  };

  const handleStudyLevelSelect = () => {
    setRoutingState({ onboardingStep: 'encouragement' });
    navigate(`/${lang || defaultInterfaceLanguage}/encouragement`);
  };

  const handleEncouragementNext = () => {
    setRoutingState({ onboardingStep: 'daily-goal' });
    navigate(`/${lang || defaultInterfaceLanguage}/daily-goal`);
  };

  const handleDailyGoalNext = () => {
    setRoutingState({ onboardingStep: 'notifications-opt-in' });
    navigate(`/${lang || defaultInterfaceLanguage}/notifications-opt-in`);
  };

  const handleNotificationsOptInNext = () => {
    setRoutingState({ onboardingStep: 'start-choice' });
    navigate(`/${lang || defaultInterfaceLanguage}/start-choice`);
  };

  const handleStartChoiceNext = () => {
    setRoutingState({ onboardingStep: 'scenario' });
    navigate(`/${lang || defaultInterfaceLanguage}/scenario`);
  };

  const handleScenarioSelect = () => {
    // Ouvre la console
    navigate(`/${lang || defaultInterfaceLanguage}/console`);
  };

  const handleExitConsole = () => {
    // Retour scenario
    navigate(`/${lang || defaultInterfaceLanguage}/scenario`);
  };

  /**
   * 5) Rendu des routes
   */
  return (
    <Routes>
      {/* (A) Pages marketing */}
      <Route path="about" element={<AboutPage />} />
      <Route path="mission-vision" element={<MissionVisionPage />} />
      <Route path="how-it-works" element={<HowItWorksPage />} />
      <Route path="contact" element={<ContactPage />} />
      <Route path="pricing" element={<PricingPage />} />
      {/* NOUVEAU : la page Team */}
      <Route path="team" element={<TeamPage />} />

      {/* (B) Workflow d’onboarding */}
      <Route
        index
        element={<LandingPage onGetStarted={handleGetStarted} />}
      />
      <Route
        path="language"
        element={
          <StudyLanguageSelection onLanguageSelect={handleStudyLanguageSelect} />
        }
      />
      <Route
        path="heard-about"
        element={<HeardAboutPage onNext={handleHeardAboutNext} />}
      />
      <Route
        path="why-learn"
        element={<WhyLearnPage onNext={handleWhyLearnNext} />}
      />
      <Route
        path="level"
        element={<StudyLevelSelection onLevelSelect={handleStudyLevelSelect} />}
      />
      <Route
        path="encouragement"
        element={<EncouragementPage onNext={handleEncouragementNext} />}
      />
      <Route
        path="daily-goal"
        element={<DailyGoalPage onNext={handleDailyGoalNext} />}
      />
      <Route
        path="notifications-opt-in"
        element={<NotificationsOptInPage onNext={handleNotificationsOptInNext} />}
      />
      <Route
        path="start-choice"
        element={<StartChoicePage onNext={handleStartChoiceNext} />}
      />

      {/* (C) Scenario + console */}
      <Route
        path="scenario"
        element={
          <ConversationScenarioSelection onScenarioSelect={handleScenarioSelect} />
        }
      />
      <Route path="console" element={<ConsolePage onExit={handleExitConsole} />} />

      {/* (D) Autres pages */}
      <Route path="settings" element={<SettingsPage />} />
      <Route path="success" element={<SuccessPage />} />

      {/* (E) Landing spécifique => ex. /:lang/en/learn-english */}
      <Route
        path=":studyLang/:verbSlug"
        element={<LandingPage onGetStarted={handleGetStarted} />}
      />

      {/* (F) 404 */}
      <Route
        path="*"
        element={
          <div className="p-4 text-center">
            <h1 className="text-2xl font-bold">404 - Not Found (InnerApp)</h1>
            <p>Oops, this page doesn't exist.</p>
          </div>
        }
      />
    </Routes>
  );
}

export default InnerApp;