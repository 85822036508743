// src/hooks/useUserLang.ts
import { useLanguage } from '../context/LanguageContext';

/**
 * Renvoie 'fr', 'en', etc. 
 * (Remplace l’ancien usage de displayLanguage + interfaceLanguage.)
 */
export function useUserLang() {
  const { interfaceLanguage } = useLanguage();
  return interfaceLanguage || 'en';
}