// src/pages/NotificationsOptInPage.tsx

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getToken } from 'firebase/messaging';
import { OnboardingLayout } from '../components/OnboardingLayout';
import { getApiUrl } from '../config/environment';
import { auth, messaging } from '../firebaseConfig';
import { VoxButton } from '../components/VoxButton';
import { VoxCard } from '../components/VoxCard';

interface NotificationsOptInPageProps {
  onNext: () => void;
}

export const NotificationsOptInPage: React.FC<NotificationsOptInPageProps> = ({ onNext }) => {
  const { t } = useTranslation('notificationsPage');

  // Indique si on est en train de gérer la demande de permission + enregistrement
  const [isLoading, setIsLoading] = useState(false);

  // Pour animer les points dans le texte du bouton
  const [dotCount, setDotCount] = useState(0);

  /**
   * Gère l’intervalle qui fait varier dotCount
   *  => 1 point, 2 points, 3 points, puis revient à 1 point, etc.
   */
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isLoading) {
      interval = setInterval(() => {
        setDotCount((prev) => (prev + 1) % 3); // cycle : 0,1,2 => 0,1,2...
      }, 500);
    } else {
      setDotCount(0); // On réinitialise si l’opération est terminée
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isLoading]);

  /**
   * Au lieu d'ajouter les points directement dans la chaîne de caractères,
   * on sépare le texte ("Autoriser") et la zone d'affichage des points.
   * Comme on réserve une largeur fixe, le texte ne bouge plus.
   */
  const allowButtonContent = isLoading ? (
    <div className="flex items-center">
      <span className="mr-1">{t('allow', 'Autoriser')}</span>
      {/* Réservation d’une largeur fixe pour les points (ex. w-6) */}
      <span className="inline-block w-6">
        {'.'.repeat(dotCount + 1)}
      </span>
    </div>
  ) : (
    t('allow', 'Autoriser')
  );

  /**
   * Permettre les notifications puis passer à la page suivante.
   */
  async function handleAllowNotifications() {
    if (!('Notification' in window)) {
      console.log('[NotificationsOptIn] API Notification non supportée');
      onNext();
      return;
    }

    try {
      setIsLoading(true);

      // 1) Demander la permission
      const permission = await Notification.requestPermission();
      console.log('[NotificationsOptIn] Permission demandée:', permission);

      if (permission !== 'granted') {
        console.warn('[NotificationsOptIn] Permission refusée');
        onNext();
        return;
      }

      // 2) Obtenir le token FCM
      const vapidKey = process.env.REACT_APP_VAPID_KEY || 'REMPLACEZ_CLE_VAPID_ICI';
      console.log('[NotificationsOptIn] VAPID KEY =', vapidKey);

      let fcmToken: string | undefined;
      try {
        fcmToken = await getToken(messaging, { vapidKey });
      } catch (tokenError) {
        console.error('[NotificationsOptIn] Erreur getToken:', tokenError);
        throw tokenError;
      }

      if (!fcmToken) {
        console.error('[NotificationsOptIn] Aucun token FCM retourné');
        onNext();
        return;
      }
      console.log('[NotificationsOptIn] FCM token =>', fcmToken);

      // 3) UID utilisateur
      const uid = auth.currentUser?.uid || 'mdQzUFkO7SSmluw5dY5O33pC2dJ3';
      console.log('[NotificationsOptIn] UID =>', uid);

      // 4) Envoi du token à l’API
      const apiUrl = getApiUrl();
      const registerUrl = `${apiUrl}/api/notifications/registerFcmToken`;
      console.log('[NotificationsOptIn] URL API =>', registerUrl);

      const response = await fetch(registerUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid, fcmToken }),
      });
      console.log('[NotificationsOptIn] Réponse HTTP =>', response.status, response.statusText);

      if (!response.ok) {
        console.error('[NotificationsOptIn] Erreur enregistrement FCM:', response.statusText);
      } else {
        const data = await response.json();
        console.log('[NotificationsOptIn] Données backend =>', data);
      }

      // 5) On poursuit vers la page suivante
      onNext();

    } catch (error) {
      console.error('[NotificationsOptIn] Erreur notifications ou FCM:', error);
      onNext();
    } finally {
      setIsLoading(false);
    }
  }

  /**
   * Bloquer les notifications et passer directement à la page suivante.
   */
  function handleBlockNotifications() {
    console.log('[NotificationsOptIn] Notifications bloquées par l’utilisateur');
    onNext();
  }

  // On laisse OnboardingLayout sans footer car nous gérons les boutons dans le contenu
  return (
    <OnboardingLayout
      currentStep="notifications-opt-in"
      title={t('title', "N'oublie pas de t'entraîner grâce à nos rappels quotidiens")}
      footer={undefined}
    >
      <div
        className="
          w-full
          max-w-screen-md
          mx-auto
          text-xl
          md:text-2xl
          mt-4
          md:mt-8
          px-4
          flex
          flex-col
          items-center
        "
      >
        <VoxCard
          hoverable={false}
          selected={false}
          className="
            w-full
            p-6
            md:p-8
            shadow-md
            flex flex-col
            items-center
            border-gray-300
          "
        >
          <img
            src="/icons/notification-icon.webp"
            alt="Notification Icon"
            className="w-20 h-20 md:w-24 md:h-24 object-contain mb-4"
          />

          <p className="text-gray-600 font-semibold text-center leading-snug mb-6">
            {t('description', 'www.voxanet.com souhaite afficher les notifications')}
          </p>

          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <VoxButton
              variant="secondary"
              width="auto"
              onClick={handleBlockNotifications}
              disabled={isLoading}
            >
              {t('block', 'Bloquer')}
            </VoxButton>

            <VoxButton
              variant="primary"
              width="auto"
              onClick={handleAllowNotifications}
              disabled={isLoading}
            >
              {allowButtonContent}
            </VoxButton>
          </div>
        </VoxCard>
      </div>
    </OnboardingLayout>
  );
};

export default NotificationsOptInPage;