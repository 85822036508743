// /src/components/VoxButton.tsx

import React from 'react';

// Les variantes de couleurs
type ButtonVariant = 'primary' | 'secondary';

// Les largeurs possibles : on ne garde plus que «auto» et «medium»
type ButtonWidth = 'auto' | 'medium';

interface VoxButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  width?: ButtonWidth;
}

/**
 * Composant VoxButton
 *
 * - variant="primary"     => bouton bleu
 * - variant="secondary"   => bouton blanc / bordure bleue
 *
 * - width="medium"        => w-[28rem] sur desktop, w-full sur mobile
 * - width="auto"          => auto + min-width + padding
 *
 * - disabled              => style grisé
 */
export const VoxButton: React.FC<VoxButtonProps> = ({
  variant = 'primary',
  width = 'auto',
  disabled,
  children,
  ...props
}) => {
  // Classes communes de base
  let classes = `
    whitespace-nowrap
    px-8 py-4
    uppercase
    font-semibold
    text-xl md:text-2xl
    rounded-lg
    shadow-lg
    transition-transform
    hover:scale-105
    focus:outline-none
    focus:ring-4
    inline-flex
    justify-center
    items-center
  `;

  // Gestion de la largeur
  if (width === 'medium') {
    // => Plein écran sur mobile + 28rem (~448px) à partir de md
    classes += ' w-full md:w-[28rem]';
  } else {
    // => 'auto' => on laisse la largeur s'adapter au contenu
    //    + min-width pour empêcher un bouton trop étroit
    classes += ' min-w-[14rem]';
  }

  // Couleurs selon variant
  if (variant === 'primary') {
    classes += `
      bg-[#3f7afd]
      text-white
      hover:bg-[#2f69df]
      focus:ring-blue-300
    `;
  } else {
    // secondary
    classes += `
      bg-white
      border
      border-[#3f7afd]
      text-[#3f7afd]
      hover:bg-gray-50
      focus:ring-blue-200
    `;
  }

  // disabled => grisé
  if (disabled) {
    classes += ' bg-gray-300 cursor-not-allowed hover:scale-100 hover:bg-gray-300';
  }

  return (
    <button className={classes} disabled={disabled} {...props}>
      {children}
    </button>
  );
};