// src/hooks/useRealtimeWebRTC/handleDataChannelOpen.ts

export function handleDataChannelOpen(
  dc: RTCDataChannel,
  instructionsWithHistory: string
) {
  // 1) session.update => push-to-talk
  dc.send(
    JSON.stringify({
      type: "session.update",
      session: {
        instructions: instructionsWithHistory,
        turn_detection: null,
        modalities: ["audio", "text"],
        input_audio_transcription: { model: "whisper-1" },
      },
    })
  );

  // 2) conversation.item.create => "start"
  dc.send(
    JSON.stringify({
      type: "conversation.item.create",
      item: {
        type: "message",
        role: "user",
        content: [{ type: "input_text", text: "start" }],
      },
    })
  );

  // 3) response.create => audio + text
  dc.send(
    JSON.stringify({
      type: "response.create",
      response: { modalities: ["audio", "text"] },
    })
  );
}