// src/pages/HeardAboutPage.tsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/AuthContext';
import { OnboardingLayout } from '../components/OnboardingLayout';
import { VoxButton } from '../components/VoxButton';
import { VoxCard } from '../components/VoxCard';

interface HeardAboutPageProps {
  onNext: () => void;
}

type OptionItem = {
  value: string;
  label: string;
  imagePath: string;
};

const HeardAboutPage: React.FC<HeardAboutPageProps> = ({ onNext }) => {
  const { t } = useTranslation('heardAboutPage');
  const { currentUser } = useAuth();

  const [selectedOption, setSelectedOption] = useState('');
  const [otherText, setOtherText] = useState('');

  const pageTitle = t('title', 'How did you hear about us?').replace(' ?', '\u00A0?');

  const options: OptionItem[] = [
    {
      value: 'google',
      label: t('options.google', 'Google Search'),
      imagePath: '/heard-about/google-search-icon.webp',
    },
    {
      value: 'friend',
      label: t('options.friend', 'Friends'),
      imagePath: '/heard-about/friends-icon.png',
    },
    {
      value: 'social',
      label: t('options.social', 'Social networks'),
      imagePath: '/heard-about/social-networks-icon.png',
    },
    {
      value: 'podcast',
      label: t('options.podcast', 'Podcast'),
      imagePath: '/heard-about/podcast-icon.png',
    },
    {
      value: 'other',
      label: t('options.other', 'Other'),
      imagePath: '/heard-about/other-icon.png',
    },
  ];

  const handleOptionClick = (value: string) => {
    setSelectedOption(value);
    if (value !== 'other') {
      setOtherText('');
    }
  };

  const handleSubmit = async () => {
    if (!currentUser) return;

    const payload = {
      uid: currentUser.uid,
      heardAbout: selectedOption,
      heardAboutOther: selectedOption === 'other' ? otherText : '',
    };

    try {
      await fetch('http://localhost:8082/api/user/updateOnboardingAnswers', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
    } catch (err) {
      console.error('Error updating onboarding answers:', err);
    }
    onNext();
  };

  const isNextDisabled =
    !selectedOption || (selectedOption === 'other' && !otherText.trim());

  // Footer avec le bouton "Next"
  const footer = (
    <div className="flex justify-center">
      <VoxButton
        variant="primary"
        width="auto"
        disabled={isNextDisabled}
        onClick={handleSubmit}
      >
        {t('next', 'Next')}
      </VoxButton>
    </div>
  );

  return (
    <OnboardingLayout currentStep="heardAbout" title={pageTitle} footer={footer}>
      <div className="grid grid-cols-1 gap-4 text-xl md:text-2xl">
        {options.map((opt) => {
          const isSelected = opt.value === selectedOption;
          return (
            <VoxCard
              key={opt.value}
              hoverable
              selected={isSelected}
              onClick={() => handleOptionClick(opt.value)}
            >
              <img
                src={opt.imagePath}
                alt={opt.value}
                className="w-16 h-16 object-contain mr-4"
              />
              <span className="font-bold text-[#243238] leading-snug">
                {opt.label}
              </span>
            </VoxCard>
          );
        })}

        {selectedOption === 'other' && (
          <div className="mt-2">
            <input
              type="text"
              placeholder={t('otherPlaceholder', 'Please specify')}
              value={otherText}
              onChange={(e) => setOtherText(e.target.value)}
              className="
                w-full
                text-xl md:text-2xl
                px-3 py-2
                border border-gray-300
                rounded-md
                focus:outline-none
                focus:ring-2
                focus:ring-blue-300
              "
            />
          </div>
        )}
      </div>
    </OnboardingLayout>
  );
};

export default HeardAboutPage;