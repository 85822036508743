// src/pages/StudyLevelSelection.tsx

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

// Layout et contexte
import { OnboardingLayout } from '../components/OnboardingLayout';
import { useAuth } from '../context/AuthContext';
import { useUser } from '../context/UserContext';
import { useStudy } from '../context/StudyContext';
import { updateUserSettings } from '../services/userService';

// Constantes
import { LEVELS } from '../constants/levels';

// Autres composants
import SignalBars from '../components/SignalBars';
import { VoxButton } from '../components/VoxButton';
import { VoxCard } from '../components/VoxCard';

interface StudyLevelSelectionProps {
  onLevelSelect: () => void;
}

export const StudyLevelSelection: React.FC<StudyLevelSelectionProps> = ({ onLevelSelect }) => {
  const { t: tCommon } = useTranslation('common');
  const { currentUser } = useAuth();
  const { userData } = useUser();
  const { studyLanguage, setStudyLevel } = useStudy();

  const [selectedLevel, setSelectedLevel] = useState<number | null>(null);

  useEffect(() => {
    console.log('[StudyLevelSelection] Monté. currentUser:', currentUser?.uid ?? 'aucun');
    console.log('[StudyLevelSelection] userData:', userData);
    console.log('[StudyLevelSelection] studyLanguage:', studyLanguage);
  }, [currentUser, userData, studyLanguage]);

  // Traduction
  const uiLang = i18n.language || 'en';
  const fixedT = i18n.getFixedT(uiLang, ['languages']);
  const studyLangName = fixedT(`withArticle.${studyLanguage}`, { defaultValue: studyLanguage });
  const dynamicTitle = tCommon('studyLevelSelection.titleWithLang', { studyLangName });

  // Clic sur une carte
  const handleLevelClick = (value: number) => {
    setSelectedLevel(value);
  };

  // Au clic sur Next => enregistrement
  const handleNext = async () => {
    if (selectedLevel == null) return;
    if (!currentUser) {
      console.error('[StudyLevelSelection] Aucun utilisateur connecté.');
      return;
    }
    setStudyLevel(selectedLevel);
    try {
      console.log('[StudyLevelSelection] updateUserSettings =>', studyLanguage, selectedLevel);
      await updateUserSettings(currentUser.uid, {
        studyLanguage,
        studyLevel: selectedLevel,
      });
      console.log('[StudyLevelSelection] Mise à jour réussie.');
    } catch (error) {
      console.error('[StudyLevelSelection] Erreur de mise à jour :', error);
    }
    onLevelSelect();
  };

  // Désactivation du bouton
  const isNextDisabled = selectedLevel == null;

  // Footer
  const footer = (
    <div className="flex justify-center">
      <VoxButton
        variant="primary"
        width="auto"
        disabled={isNextDisabled}
        onClick={handleNext}
      >
        {tCommon('studyLevelSelection.next')}
      </VoxButton>
    </div>
  );

  return (
    <OnboardingLayout currentStep="level" title={dynamicTitle} footer={footer}>
      <div className="flex flex-col items-center space-y-4 text-xl md:text-2xl">
        {LEVELS.map((value) => {
          const isSelected = value === selectedLevel;
          return (
            <VoxCard
              key={value}
              hoverable
              selected={isSelected}
              onClick={() => handleLevelClick(value)}
              className="w-full max-w-2xl gap-6"
            >
              {/* SignalBars */}
              <SignalBars level={value} />

              {/* Texte niveau => aligné à gauche */}
              <p className="m-0 text-gray-800 leading-snug font-bold flex-1 text-left">
                {tCommon(`studyLevelSelection.levelDescriptions.${value}`, {
                  studyLanguageName: studyLangName,
                  interfaceLanguageName:
                    tCommon(`languages.${userData?.interfaceLanguage || 'en'}`) ||
                    'Your native language',
                })}
              </p>
            </VoxCard>
          );
        })}
      </div>
    </OnboardingLayout>
  );
};

export default StudyLevelSelection;