// src/hooks/workflowStepsOrder.ts

import { WorkflowStep } from '../types/WorkflowStep';

export const ORDERED_STEPS: WorkflowStep[] = [
  'landing',
  'language',
  'heardAbout',
  'whyLearn',
  'level',
  'encouragement',
  'dailyGoal',
  'notifications-opt-in',
  'startChoice',
  'scenario',
  'console',
];