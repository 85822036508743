// src/pages/ConsolePage/StreakPage.tsx

import React from 'react';
import Lottie from 'lottie-react';
import { VoxButton } from '../../components/VoxButton';

// Animation de la flamme
import streakFlameAnimationData from '../../assets/animations/streakflame-better.json';

interface StreakPageProps {
  currentStreak?: number;   // Par défaut 1 si non fourni
  longestStreak?: number;   // Record à afficher (optionnel)
  onClose: () => void;
}

const motivationMessages = [
  "Chaque jour compte : un seul jour manqué et ta série repart à zéro !",
  "Continue ta lancée, ou tu repars à zéro. Ne lâche rien !",
  "Garde le rythme, sinon c'est retour à la case départ !",
  "Un petit effort quotidien, sinon tu risques de tout perdre !",
  "Ta série t'attend, ne la brise pas : un jour oublié, tout repart à zéro !",
];

export const StreakPage: React.FC<StreakPageProps> = ({
  currentStreak = 1,
  longestStreak = 5,
  onClose,
}) => {
  // Sélectionne un message de motivation aléatoire
  const randomMessage = motivationMessages[Math.floor(Math.random() * motivationMessages.length)];

  // Limite l'affichage du calendrier à 7 jours
  const highlightCount = Math.min(currentStreak, 7);
  const days = Array.from({ length: 7 }, (_, i) => i + 1);

  // Prépare le libellé du streak
  // Un seul point d'exclamation
  const dayLabel = currentStreak === 1 ? '1 Jour' : `${currentStreak} Jours`;
  const exclamationMark = '!';

  // On adapte la taille du titre selon le streak :
  let titleSize = 'text-6xl'; // par défaut pour 1 jour
  if (currentStreak === 2) {
    titleSize = 'text-7xl';
  } else if (currentStreak >= 3) {
    titleSize = 'text-8xl';
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-4 py-6 bg-white overflow-hidden relative">
      {/* Conteneur principal avec un contour gris plus visible */}
      <div className="relative w-full max-w-md bg-white border-2 border-gray-300 shadow-md rounded-vox p-6 text-center">
        
        {/* Animation de la flamme */}
        <div className="mx-auto mb-6" style={{ width: 350, height: 350 }}>
          <Lottie animationData={streakFlameAnimationData} loop />
        </div>

        {/* Titre très grand, avec exclamation unique */}
        <h2 className={`${titleSize} font-bold text-[#3f7afd] mb-4 animate-bounce`}>
          {dayLabel}{exclamationMark}
        </h2>

        {/* Mini-calendrier de 7 jours (avec coche) */}
        <div className="flex justify-center mb-8 space-x-3">
          {days.map((day, index) => {
            const isHighlighted = index < highlightCount;
            const squareSize = "w-12 h-12"; 
            const tickSize = "w-6 h-6";

            return (
              <div
                key={day}
                className={`flex items-center justify-center ${squareSize} border-2 border-[#3f7afd] rounded-md transition-colors ${
                  isHighlighted ? 'bg-[#3f7afd] animate-pulse-slight' : 'bg-white'
                }`}
              >
                {/* Affiche la coche (tick) seulement si le jour est validé */}
                {isHighlighted && (
                  <svg
                    className={`${tickSize} text-white`}
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="3"
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                )}
              </div>
            );
          })}
        </div>

        {/* Message de motivation */}
        <p className="text-2xl text-vox-brand leading-snug mb-6">
          {randomMessage}
        </p>

        {/* Petit rappel sur la règle des 10 messages */}
        <div className="mb-6">
          <p className="text-md text-gray-700 font-medium">
            N’oublie pas : pour valider ta journée et faire grimper ta streak, 
            tu dois envoyer au moins <strong>10 messages</strong> !
          </p>
        </div>

        {/* Bouton "OK" */}
        <div className="flex justify-center items-center">
          <VoxButton variant="primary" width="auto" onClick={onClose}>
            OK&nbsp;!
          </VoxButton>
        </div>
      </div>
    </div>
  );
};

export default StreakPage;