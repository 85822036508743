// src/firebaseConfig.ts
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCGwnFcn5o_9GXjjb75D8rszhxfv_j01qM",
  authDomain: "nouveau-voxanet-2a5e7.firebaseapp.com",
  projectId: "nouveau-voxanet-2a5e7",
  storageBucket: "nouveau-voxanet-2a5e7.appspot.com",
  messagingSenderId: "722747874132",
  appId: "1:722747874132:web:aaf9d82a49a409bdebc6c1",
  measurementId: "G-Y7SE2YDVRH"
};

// Initialiser Firebase
const app = initializeApp(firebaseConfig);

// Initialiser Firebase Authentication
export const auth = getAuth(app);

// Initialiser Firebase Cloud Messaging
export const messaging = getMessaging(app);

// Exporter l'application (si nécessaire pour d'autres services Firebase)
export default app;