// src/i18n.ts

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Liste des langues supportées
const languages = [
  'ar', 'de', 'en', 'es', 'fr', 'hi', 'it', 'ja', 'ko',
  'nl', 'pl', 'pt', 'ru', 'tr', 'zh', 'id', 'ur'
];

// Liste des namespaces utilisés dans le projet
const namespaces = [
  'common',
  'landingPage',
  'scenarios',
  'registerEmailForm',
  'login',
  'paywall',
  'successPage',
  'cancelPage',
  'consolePage',
  'realtime',
  'languages',
  'contactPage',
  'aboutPage',
  'pricingPage',
  'howItWorksPage',
  'icebreaker',
  'settingsPage',
  'heardAboutPage',
  'why-learn',
  'encouragement',
  'daily-goal',
  'start-choice',
  'missionVisionPage',
  'notificationsPage',
  'footer' // Ajout du namespace "footer"
];

// Construction dynamique de l'objet "resources"
const resources: { [lang: string]: { [ns: string]: any } } = {};

languages.forEach(lang => {
  resources[lang] = {};
  namespaces.forEach(ns => {
    try {
      // Chargement du fichier JSON pour la langue "lang" et le namespace "ns"
      resources[lang][ns] = require(`./locales/${lang}/${ns}.json`);
    } catch (error) {
      console.error(`Erreur lors du chargement de "./locales/${lang}/${ns}.json" :`, error);
    }
  });
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    ns: namespaces,
    defaultNS: 'common',
    fallbackNS: 'common',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'navigator', 'htmlTag', 'cookie', 'path', 'subdomain'],
      caches: ['localStorage'],
    },
    saveMissing: true,
    react: {
      useSuspense: false,
    },
    returnEmptyString: false,
  });

export default i18n;